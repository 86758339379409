import { SET_GLOBAL_FILTER } from '@va/dashboard/actions/ui';
import { T3_URL_PARAMS } from '@va/standalone/shared/constants';
import { appHistory } from '@va/standalone/shared/router';
import { TimePeriod } from '@va/types/time';
import { updateUrlParameter } from '@va/util/helpers';
import { Moment } from 'moment';

export function* updateGlobalFilterUrlParam(action: {
  type: typeof SET_GLOBAL_FILTER;
  filter: { from: Moment; until: Moment; unit: TimePeriod; search?: string };
}) {
  let updatedURL = updateUrlParameter(T3_URL_PARAMS.startDate, action.filter.from.format('YYYY-MM-DD'));
  appHistory.replace(updatedURL);
  updatedURL = updateUrlParameter(T3_URL_PARAMS.endDate, action.filter.until.format('YYYY-MM-DD'));
  appHistory.replace(updatedURL);
  yield;
}
