import { AaasFeaturePermission, GroupedPackages, StpCounterResponse } from '@va/aaas/shared/types';
import { apiStatus } from '@va/constants';
import { Website } from '@visitor-analytics/3as-sdk';
import { Map } from 'immutable';
import { ThemeType } from './theme.types';

export interface RootState extends Map<string, any> {
  core: any;
  api: any;
  ui: any;
  customization: CustomizationState;
  app: WixMiniAppState;
}

export interface WixMiniAppState {
  instanceId: string;
  initialized: boolean;
  activeWebsite: string;
  theme: ThemeType | undefined;
  featurePermissions: {
    data: AaasFeaturePermission[];
    status: apiStatus;
  };
  allowUpgrade: boolean;
  intpWebsite?: Website & { intpName: string };
  stpRates: StpCounterResponse;
  packages: GroupedPackages;
  predefinedFilters: PredefinedFilters;
}

export interface CustomizationState {
  providerName: string;
  supportCenterDomain: string;
  chatwootToken: string;
}

export enum PREDEFINED_PERIOD {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
}

export enum DEVICE_TYPES {
  ALL = 'ALL',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  NOT_SELECTED_DEVICE_TYPE = 'NOT_SELECTED_DEVICE_TYPE',
}

type WixFunnelEvents =
  | 'SESSIONS_CONVERTED'
  | 'REACHED_CHECKOUT'
  | 'ADDED_TO_CART'
  | 'VIEWED_PRODUCT'
  | 'SITE_SESSIONS'
  | 'NOT_SELECTED_FUNNEL_STEP';

export type WixConversionFunnel = {
  include: WixFunnelEvents;
  exclude: WixFunnelEvents;
};

export interface PredefinedFilters {
  timezone: string;
  predefinedTimePeriod?: string;
  customTimePeriod?: {
    startDate: string;
    endDate: string;
  };
  deviceType?: string;
  navigationFlow?: { pageInteractions: Array<string> };
  conversionFunnel?: WixConversionFunnel;
}
