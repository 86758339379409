import { Filter } from '@va/types/filters';
import classNames from 'classnames';

type ExternalAppliedFilterProps = {
  filter: Filter;
  size?: 'small' | 'medium' | 'large';
  viewOnly?: boolean;
  disableEditing?: boolean;
};

export const ExternalAppliedFilter = (props: ExternalAppliedFilterProps) => {
  const AppliedFilterComponent = props.filter.appliedInput;
  return (
    <div className={classNames('relative flex items-center cursor-not-allowed', { 'opacity-60': props.viewOnly })}>
      <div className={'contents pointer-events-none'}>
        <AppliedFilterComponent {...props} {...props.filter.inputProps} />
      </div>
    </div>
  );
};
