import { useTranslate } from '@va/localization';
import { BlinkingCircle } from '@va/ui/design-system';
import classNames from 'classnames';

type NewMarkerProps = {
  className?: string;
  isNew?: boolean;
  hidden?: boolean;
  showLabel?: boolean;
  isBeta?: boolean;
};

const NewMarker = (props: NewMarkerProps) => {
  const translate = useTranslate();
  const { isNew, hidden, showLabel, isBeta, className } = props;

  if (!isNew && !isBeta) return null;
  if (hidden) return null;

  if (showLabel) {
    return (
      <div
        className={classNames(
          `flex flex-row flex-nowrap items-center gap-1  bg-[#FEEADE] p-1 rounded-md mr-2`,
          className,
        )}
      >
        <BlinkingCircle color='#ff9933' />
        <span className='text-10.5 leading-tight text-orange-pure font-semibold'>
          {isNew && translate('leftNav.new')}
          {isBeta && translate('leftNav.beta')}
        </span>
      </div>
    );
  }

  return (
    <div className={classNames('pr-2', className)}>
      <BlinkingCircle color='#ff9933' />
    </div>
  );
};

export default NewMarker;
