import { Placement } from '@floating-ui/react';
import { CheckmarkIconFilled, EmptyCheckmarkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { DefaultFilterOptionType } from '@va/types/filters';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import { Button, Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

type SelectedOptions = Record<string, any>;

export const FilterSelectDropdown: React.FC<
  PropsWithChildren<{
    selectedOptions: SelectedOptions;
    onChange: (option: DefaultFilterOptionType) => void;
    options: DefaultFilterOptionType[];
    showSearchInput?: boolean;
    triggerClassName?: string;
    closeOnClick?: boolean;
    showArrowIcon?: boolean;
    placement?: Placement;
    tooltipClassName?: string;
    allOption?: DefaultFilterOptionType;
    disabled?: boolean;
  }>
> = ({
  children,
  selectedOptions,
  onChange,
  options,
  showSearchInput = true,
  triggerClassName,
  closeOnClick = true,
  showArrowIcon = true,
  tooltipClassName,
  allOption,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TooltipWrapper
      interactive
      useDefaultStyle={false}
      maxWidth={'none'}
      open={isOpen}
      tooltipClassNames={classNames('shadow-overlay rounded-18', tooltipClassName)}
      arrow={false}
      onOpenChange={(open) => setIsOpen(open)}
      trigger='click'
      disabled={disabled}
      content={
        // force dropdown to unmount so that search field is cleared
        isOpen ? (
          <DropdownContent
            allOption={allOption}
            closeOnClick={closeOnClick}
            onChange={onChange}
            selectedOptions={selectedOptions}
            close={() => {
              setIsOpen(false);
            }}
            options={options}
            showSearchInput={showSearchInput}
          />
        ) : null
      }
    >
      <div
        className={classNames(triggerClassName, 'flex items-center gap-3', {
          'cursor-pointer': !disabled,
        })}
      >
        {children}
        {showArrowIcon && <DropdownArrow iconClassName='w-2' open={isOpen} color='#969696' />}
      </div>
    </TooltipWrapper>
  );
};

export const DropdownContent: React.FC<{
  options: DefaultFilterOptionType[];
  allOption?: DefaultFilterOptionType;
  showSearchInput?: boolean;
  close?: () => void;
  selectedOptions: SelectedOptions;
  onChange: (option: DefaultFilterOptionType) => void;
  closeOnClick?: boolean;
}> = ({ options, close, selectedOptions, onChange, showSearchInput, closeOnClick, allOption }) => {
  const translate = useTranslate();
  const [filterOptions, setFilterOptions] = useState(options);

  useEffect(() => {
    setFilterOptions(options);
  }, [options]);

  const handleSearch = (searchText: string) => {
    setFilterOptions(options?.filter((item) => item?.label?.toLowerCase().includes(searchText?.toLowerCase())));
  };

  return (
    <div className='bg-white p-6px rounded-18 text-gray-charcoal md:min-w-[312px] w-auto'>
      {allOption && (
        <SelectDropdownItem
          selected={!isNil(selectedOptions[allOption.value])}
          icon={allOption.icon}
          label={allOption.label}
          onClick={() => {
            onChange(allOption);
          }}
        />
      )}
      {showSearchInput && <SearchFilterV2 onChange={handleSearch} />}
      {filterOptions.length === 0 && <Paragraph className='p-5'>{translate('select.noOptions')}</Paragraph>}
      <ul className='my-1 max-h-40 overflow-auto scrollbar scrollbar-thumb'>
        {filterOptions
          .sort((a, b) => (a.value > b.value ? 1 : -1))
          .sort((a, b) => {
            // keep selected options at the top
            const isSelectedA = !isNil(selectedOptions[a.value]);
            const isSelectedB = !isNil(selectedOptions[b.value]);

            if (isSelectedA && isSelectedB) return 0;

            if (isSelectedA) return -1;
            if (isSelectedB) return 1;

            return 0;
          })
          .map((option) => {
            const { value, disabled, label, icon } = option;
            const isSelected = !isNil(selectedOptions[value]);

            return (
              <SelectDropdownItem
                key={value}
                onClick={() => {
                  if (disabled) return;
                  onChange(option);
                  if (closeOnClick && close) {
                    close();
                  }
                }}
                icon={icon}
                label={label}
                selected={isSelected}
              />
            );
          })}
      </ul>
      {close && (
        <Button text={translate('button.close')} color='tertiary' onClick={close} className='w-full !text-sm !p-2' />
      )}
    </div>
  );
};

type SelectDropdownItemProps = {
  onClick: () => void;
  icon?: ReactNode;
  label: string;
  selected?: boolean;
  disabled?: boolean;
};

export const SelectDropdownItem = ({ onClick, disabled, icon, label, selected }: SelectDropdownItemProps) => {
  return (
    <li
      onClick={onClick}
      className={classNames('rounded-md h-8 px-3 flex items-center  gap-2', {
        'bg-gray-concrete cursor-not-allowed text-gray-silver': disabled,
        'cursor-pointer bg-white hover:bg-gray-concrete': !disabled,
      })}
    >
      {icon && <div className='shrink-0'>{icon}</div>}
      <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
        {label}
      </ParagraphWithTooltip>
      <div className='ml-auto'>
        {selected ? <CheckmarkIconFilled className='w-4 h-4' /> : <EmptyCheckmarkIcon className='w-4 h-4' />}
      </div>
    </li>
  );
};
