import { TEST_IDS } from '@va/constants';
import { ExportIcon, RightArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import { Button, GrayGradientDivider, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { memo } from 'react';

export const DownloadBtn = memo(
  ({
    downloadCSV,
    downloadPDF,
    downloadPNG,
    downloadXLSX,
    infoNote,
    title,
  }: {
    downloadCSV?: () => void;
    downloadPDF?: () => void;
    downloadPNG?: () => void;
    downloadXLSX?: () => void;
    infoNote?: string;
    title: string;
  }) => {
    const translate = useTranslate();

    return (
      <TooltipWrapper
        interactive
        useDefaultStyle={false}
        arrow={false}
        trigger='click'
        tooltipClassNames='rounded-18 shadow-overlay'
        content={
          <div data-testid={TEST_IDS.generic.export.dropdownContainer} className='rounded-18 bg-white max-w-[320px]'>
            <div className='p-3'>
              <Paragraph colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
                {title}
              </Paragraph>
            </div>
            <GrayGradientDivider />
            <ul className='p-6px'>
              {downloadCSV && (
                <ListItem
                  data-testid={TEST_IDS.generic.export.getExportOptionId('csv')}
                  label={translate('all.labels.exportCSV')}
                  onClick={downloadCSV}
                />
              )}
              {downloadPDF && (
                <ListItem
                  data-testid={TEST_IDS.generic.export.getExportOptionId('pdf')}
                  label={translate('all.labels.exportPDF')}
                  onClick={downloadPDF}
                />
              )}
              {downloadPNG && (
                <ListItem
                  data-testid={TEST_IDS.generic.export.getExportOptionId('png')}
                  label={translate('all.labels.exportPNG')}
                  onClick={downloadPNG}
                />
              )}
              {downloadXLSX && (
                <ListItem
                  data-testid={TEST_IDS.generic.export.getExportOptionId('xlsx')}
                  label={translate('all.labels.exportXLSX')}
                  onClick={downloadXLSX}
                />
              )}
            </ul>
            {infoNote && (
              <Paragraph
                className='py-1 px-2'
                size={paragraphSizes.tiny}
                colorClassName='text-gray-devil'
                weight={fontWeights.medium}
              >
                {infoNote}
              </Paragraph>
            )}
            <div className='p-6px'>
              <CloseBtn />
            </div>
          </div>
        }
      >
        <div>
          <Btn />
        </div>
      </TooltipWrapper>
    );
  },
);

const Btn = () => {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  return (
    <Button
      data-testid={TEST_IDS.generic.export.toggleBtn}
      onClick={() => {}}
      className='mr-2 h-12 shrink-0'
      color='tertiary'
      text={''}
      icon={() => <ExportIcon color='#696969' />}
      rightSideIcon={<DropdownArrow color='#969696' open={context.open} />}
      tooltip={translate('tooltip.label.exportData')}
    />
  );
};

const CloseBtn = () => {
  const translate = useTranslate();
  const { context } = useTooltipContext();
  return (
    <Button
      data-testid={TEST_IDS.generic.buttons.close}
      onClick={() => {
        context.onOpenChange(false);
      }}
      color='tertiary'
      className='w-full'
      text={translate('button.close')}
    />
  );
};

const ListItem = ({ onClick, label, ...rest }: { onClick: () => void; label: string } & TestAttributes) => {
  const { context } = useTooltipContext();

  return (
    <li
      data-testid={rest['data-testid']}
      onClick={() => {
        onClick();
        context.onOpenChange(false);
      }}
      className='flex gap-15px min-w-300 cursor-pointer h-12 items-center hover:bg-white-snow active:bg-gray-concrete px-3 rounded-12'
    >
      <ExportIcon color='#696969' />
      <Paragraph weight={fontWeights.medium}>{label}</Paragraph>
      <div className='ml-auto'>
        <RightArrowIcon color='#969696' />
      </div>
    </li>
  );
};
