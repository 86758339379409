import { TestAttributes } from '@va/types/component';
import { ElementType, ReactElement, ReactNode } from 'react';

/**Sidebar navigation */

// TODO Merge with the types from the sidebar
export type BaseMenuNavigationTab = {
  index?: number;
  name: string;
  displayInLeftNav: boolean;
  isNew?: boolean;
  isBeta?: boolean;
};

export type WithLink = {
  linkTo: ((a: { className: string; translate: (k: string) => string }) => ReactElement) | string;
  openInNewTab?: boolean;
  children?: Array<WithLink>;
} & BaseMenuNavigationTab;

export type WithSubMenu = {
  hasSubGroups?: boolean;
  submenu: Array<WithLink>;
} & BaseMenuNavigationTab;

/**Nested page navigation */

export enum NestedNavigationType {
  Tab = 'tab',
  Route = 'route',
}

export type NestedRoute = {
  link: string;
  exact?: boolean;
  component: (() => JSX.Element) | any;
  shouldRenderRoute?: boolean;
  onHover?: () => void;
  onClick?: () => void;
  type: NestedNavigationType.Route;
} & BaseElement;

export type NestedTab = {
  onClick?: () => void;
  content: JSX.Element;
  type: NestedNavigationType.Tab;
} & BaseElement;

type BaseElement = {
  label: string;
  tooltip?: string;
  icon?: ElementType;
  className?: string;
  shouldDisplay?: boolean;
  contextMenu?: ContextMenuProps;
  contextMenuElement?: React.ReactNode;
  endAdornment?: ReactNode;
} & TestAttributes;

export type ContextMenuProps = {
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  render: (onClose?: () => void) => JSX.Element;
};
