import { HeaderContext } from '@tanstack/react-table';
import { TEST_IDS } from '@va/constants';
import { ArrowsIcon, FilterIcon, IconProps, SortAndFilterIcon } from '@va/icons';
import { GroupingOption, HeaderCellFiltersDropdown } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';

type HeaderCellV8Props<TData, TValue> = {
  context: HeaderContext<TData, TValue>;
  className?: string;
  text?: string;
  icon?: ReactNode;
  tooltip?: string;
  iconTooltip?: ReactNode;
  groupingOptions?: GroupingOption[];
  size?: 'small' | 'medium';
  additionalInfo?: ReactNode;
};

export const HeaderCellV8 = <TData, TValue>({
  context,
  icon,
  className,
  text,
  tooltip,
  iconTooltip,
  groupingOptions,
  size = 'medium',
  additionalInfo,
}: HeaderCellV8Props<TData, TValue>) => {
  const canSort = context.column.getCanSort();
  const isSorted = context.column.getIsSorted();
  const isGrouped = context.column.getIsGrouped();
  const canGroup = context.column.getCanGroup();
  const group = context.table.getState().grouping;

  const shouldHighlightHeader = useMemo(() => {
    if (!canGroup) return false;
    if (isSorted) return true;
    if (isGrouped) return true;
    return !!groupingOptions?.find((item) => group.includes(item.value));
  }, [canGroup, group, groupingOptions, isGrouped, isSorted]);

  const arrowIcon = useMemo(() => {
    if (!isSorted) return <ArrowsIcon />;
    if (isSorted === 'desc') return <ChevronIcon />;
    return <ChevronIcon className='rotate-180' />;
  }, [isSorted]);

  return (
    <div
      className={classNames(
        'header-cell-content px-2 w-full h-full font-medium py-4 leading-normal text-xs text-gray-faded flex items-center justify-center divide-x-4 divide-transparent flex-nowrap',
        {
          'cursor-pointer': canSort || canGroup,
          'bg-gray-mercury': shouldHighlightHeader,
          'bg-white-snow ': !shouldHighlightHeader,
          'px-2 py-4': size === 'medium',
          'px-3 py-9px': size === 'small',
        },
        className,
      )}
    >
      {icon && (
        <TooltipWrapper content={iconTooltip} disabled={!iconTooltip} interactive useDefaultStyle={false}>
          <div className='flex items-center justify-center'>{icon}</div>
        </TooltipWrapper>
      )}
      {typeof text === 'string' ? (
        <TooltipWrapper trigger={'click'} disabled={!tooltip} content={tooltip} interactive>
          <div
            className={classNames('text-center text-gray-devilSolid cursor-default truncate', {
              'underline-dashed': tooltip,
            })}
          >
            {text}
          </div>
        </TooltipWrapper>
      ) : (
        <div className='text-center text-gray-devilSolid'>{text}</div>
      )}
      {additionalInfo}

      <TooltipWrapper
        appendTo={'parent'}
        trigger='click'
        arrow={false}
        disabled={!canGroup}
        placement='bottom'
        useDefaultStyle={false}
        tooltipClassNames='border-2 border-gray-concrete rounded-24 shadow-overlay'
        content={<TooltipContent ctx={context} groupingOptions={groupingOptions} />}
      >
        <div
          onClick={() => {
            if (!canSort || canGroup) return;
            context.column.toggleSorting();
          }}
        >
          {canSort && !canGroup && (
            <div className='flex items-center justify-center' data-testid={TEST_IDS.generic.table.sortHeaderBtn}>
              {arrowIcon}
            </div>
          )}
          {!canSort && canGroup && (
            <div className='flex items-center justify-center ml-2' data-testid={TEST_IDS.generic.table.groupHeaderBtn}>
              <FilterIcon color='#969696' className='h-18px w-18px' />
            </div>
          )}
          {canSort && canGroup && (
            <div
              className='flex items-center justify-center ml-2'
              data-testid={TEST_IDS.generic.table.sortAndGroupHeaderBtn}
            >
              <SortAndFilterIcon color='#969696' className='h-18px w-18px' />
            </div>
          )}
        </div>
      </TooltipWrapper>
    </div>
  );
};

const TooltipContent = ({
  ctx,
  groupingOptions,
}: {
  ctx: HeaderContext<any, any>;
  groupingOptions?: GroupingOption[];
}) => {
  const { context } = useTooltipContext();

  const isSorted = ctx.column.getIsSorted();
  const isSortedDesc = isSorted ? isSorted === 'desc' : undefined;
  const canGroupBy = ctx.column.getCanGroup();
  const isGrouped = ctx.column.getIsGrouped();
  const canSort = ctx.column.getCanSort();
  const groupBy = ctx.table.getState().grouping;

  return (
    <HeaderCellFiltersDropdown
      closeTooltip={() => {
        context.onOpenChange(false);
      }}
      canGroupBy={canGroupBy}
      isGroupedByColumn={isGrouped}
      toggleGroupByColumn={ctx.column.toggleGrouping}
      setGroupBy={ctx.table.setGrouping}
      groupBy={groupBy}
      clearSortBy={ctx.column.clearSorting}
      isSortedDesc={isSortedDesc}
      toggleSortBy={ctx.column.toggleSorting}
      groupingOptions={groupingOptions}
      canSort={canSort}
    />
  );
};

const ChevronIcon = ({ className }: IconProps) => (
  <svg
    className={classNames('w-3 h-3', className)}
    width='14'
    height='9'
    viewBox='0 0 14 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1 1L7 7L13 1' stroke={'#969696'} strokeWidth='2' />
  </svg>
);
