import { TEST_IDS } from '@va/constants';
import { LinkIcon } from '@va/icons';
import { URL_VIEW } from '@va/types/website';
import { Button, ButtonShapes, ListCardItemWithIcon, ParagraphWithTooltip, paragraphSizes } from '@va/ui/design-system';
import { getUrlWithoutProtocolAndTrailingSlash, getWellFormedUrl } from '@va/util/helpers';
import { isNil } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';

export type PageListCardItemProps = {
  url: string;
  title?: string;
  icon?: ReactNode;
  urlView?: URL_VIEW;
  color?: string;
};

export const PageListCardItem: FC<PageListCardItemProps> = ({ title, url, color, urlView = URL_VIEW.url }) => {
  const niceUrl = getUrlWithoutProtocolAndTrailingSlash(url) || '/';
  const shouldHideTitle = useMemo(() => {
    return urlView === 'url' || isNil(title);
  }, [title, urlView]);

  return (
    <ListCardItemWithIcon
      icon={
        <div className='relative'>
          <Button
            data-testid={TEST_IDS.generic.link}
            tooltip={niceUrl}
            color={'secondary'}
            shape={ButtonShapes.circle}
            icon={(_className, color) => <LinkIcon color={color} />}
            onClick={() => window.open(getWellFormedUrl(url), '_blank')?.focus()}
          />
          {color && (
            <div
              style={{ backgroundColor: color }}
              className='absolute rounded-full w-3 h-3 border border-white right-0 bottom-0'
            />
          )}
        </div>
      }
      topText={shouldHideTitle ? niceUrl : title}
      bottomNode={
        !shouldHideTitle ? (
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            colorClassName='text-gray-charcoal'
            className='text-left hover:underline'
          >
            {niceUrl}
          </ParagraphWithTooltip>
        ) : null
      }
    />
  );
};
