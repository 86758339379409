import { TEST_IDS } from '@va/constants';
import { getWebsiteInstallDate } from '@va/dashboard/selectors/core';
import { getPreviousGlobalFilter, getPreviousYearGlobalFilter } from '@va/dashboard/selectors/ui';
import { CheckmarkIconFilled, EmptyCheckmarkIcon } from '@va/icons';
import { useLocalizationContext, useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import {
  Button,
  Paragraph,
  ParagraphWithTooltip,
  ToggleSwitch,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper, TooltipWrapperProps, useTooltipContext } from '@va/ui/tooltips';
import { getLocalizedDate, getTimestampFromIso } from '@va/util/helpers';
import classNames from 'classnames';
import { isNil } from 'lodash';

import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export enum DateComparisonPeriod {
  PREVIOUS = 'previous',
  YOY = 'yoy',
}

type ToggleProps = {
  selected: boolean;
  onToggle: () => void;
  className?: string;
  text?: string;
  activeComparisonOption?: DateComparisonPeriod;
  setActiveComparisonOption?: (value: DateComparisonPeriod) => void;
};

const useIsPrevPeriodAvailable = () => {
  const { until: previousUntil } = useSelector(getPreviousGlobalFilter);
  const installDate = useSelector(getWebsiteInstallDate);
  const isPreviousPeriodBeforeInstallDate = useMemo(() => {
    const duration = moment.duration(installDate.diff(previousUntil)).asSeconds();
    return duration > 0;
  }, [installDate, previousUntil]);

  return !isPreviousPeriodBeforeInstallDate;
};

export const PreviousToggle = ({
  onToggle,
  selected,
  activeComparisonOption = DateComparisonPeriod.PREVIOUS,
  setActiveComparisonOption,
}: ToggleProps) => {
  const { translate, locale } = useLocalizationContext();
  const [isOpen, setIsOpen] = useState(false);

  const isPrevPeriodAvailable = useIsPrevPeriodAvailable();

  const isYoyComparisonEnabled = useMemo(() => !isNil(setActiveComparisonOption), [setActiveComparisonOption]);

  const isYoyOptionActive = useMemo(
    () => activeComparisonOption === DateComparisonPeriod.YOY,
    [activeComparisonOption],
  );
  const { from: previousFrom, until: previousUntil } = useSelector(
    isYoyOptionActive ? getPreviousYearGlobalFilter : getPreviousGlobalFilter,
  );

  const previousFilter = useMemo(() => {
    const from = getLocalizedDate(getTimestampFromIso(previousFrom), locale);
    const until = getLocalizedDate(getTimestampFromIso(previousUntil), locale);
    return `${from} - ${until}`;
  }, [locale, previousFrom, previousUntil]);

  const handleDropdownVisibility = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onComparisonOptionChange = useCallback(
    (value: DateComparisonPeriod) => {
      if (isNil(setActiveComparisonOption)) return;
      setActiveComparisonOption(value);
    },
    [setActiveComparisonOption],
  );

  useEffect(() => {
    if (!isPrevPeriodAvailable && selected) {
      onToggle();
    }
  }, [isPrevPeriodAvailable, onToggle, selected]);

  const tooltipProps = useMemo(() => {
    if (!isPrevPeriodAvailable) {
      return {
        trigger: 'hover',
        content: translate('tooltip.previousToggle.prevPeriodNotAvailable'),
      };
    }
    return {
      open: isOpen,
      onOpenChange: (val) => isOpen && setIsOpen(val),
      trigger: 'click',
      useDefaultStyle: false,
      tooltipClassNames: 'shadow-overlay rounded-18',
      content: (
        <ComparisonOptions
          activeComparisonOption={activeComparisonOption}
          setActiveComparisonOption={onComparisonOptionChange}
          isYoyComparisonEnabled={isYoyComparisonEnabled}
        />
      ),
    };
  }, [
    activeComparisonOption,
    isOpen,
    isPrevPeriodAvailable,
    isYoyComparisonEnabled,
    onComparisonOptionChange,
    translate,
  ]) as TooltipWrapperProps;

  return (
    <TooltipWrapper {...tooltipProps} arrow={false} disabled={!isYoyComparisonEnabled}>
      <div
        className={classNames(
          'flex flex-nowrap justify-between bg-white-snow rounded-xl h-12 gap-3 items-center px-3 w-[230px]',
          { 'opacity-70 cursor-not-allowed': !isPrevPeriodAvailable },
        )}
      >
        <div className='flex flex-col'>
          <Paragraph
            data-testid={TEST_IDS.generic.previousPeriodComparison.toggleOptionsDropdownBtn}
            size={paragraphSizes.tiny}
            weight={fontWeights.medium}
            colorClassName='text-primary'
            onClick={handleDropdownVisibility}
            className={classNames({
              'hover:underline cursor-pointer': isYoyComparisonEnabled && isPrevPeriodAvailable,
            })}
          >
            {translate(`label.compareTo.${activeComparisonOption ?? DateComparisonPeriod.PREVIOUS}`)}
          </Paragraph>
          <Paragraph
            size={paragraphSizes.tiny}
            weight={fontWeights.medium}
            colorClassName='text-gray-devil whitespace-nowrap'
            // className='cursor-pointer'
            // onClick={handleDropdownVisibility}
          >
            {previousFilter}
          </Paragraph>
        </div>
        <div
          className='flex items-center justify-center'
          data-testid={TEST_IDS.generic.previousPeriodComparison.toggleComparisonBtn}
        >
          <ToggleSwitch onChange={onToggle} checked={selected} disabled={!isPrevPeriodAvailable} />
        </div>
      </div>
    </TooltipWrapper>
  );
};

const ComparisonOptions = ({
  activeComparisonOption = DateComparisonPeriod.PREVIOUS,
  setActiveComparisonOption,
  isYoyComparisonEnabled,
}: {
  activeComparisonOption: DateComparisonPeriod;
  setActiveComparisonOption: (value: DateComparisonPeriod) => void;
  isYoyComparisonEnabled?: boolean;
}) => {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  const closeDropdown = useCallback(() => context.onOpenChange(false), [context]);
  return (
    <div
      data-testid={TEST_IDS.generic.previousPeriodComparison.dropdownOptionsContainer}
      className='flex flex-col bg-white p-6px rounded-18 text-gray-charcoal min-w-auto md:min-w-[312px]'
    >
      <SelectDropdownItem
        data-testid={TEST_IDS.helpers.createListItemId(DateComparisonPeriod.PREVIOUS)}
        selected={activeComparisonOption === DateComparisonPeriod.PREVIOUS}
        onClick={() => {
          setActiveComparisonOption?.(DateComparisonPeriod.PREVIOUS);
          closeDropdown();
        }}
        label={translate('label.compareToPreviousPeriod')}
      />
      {isYoyComparisonEnabled && (
        <SelectDropdownItem
          data-testid={TEST_IDS.helpers.createListItemId(DateComparisonPeriod.YOY)}
          selected={activeComparisonOption === DateComparisonPeriod.YOY}
          onClick={() => {
            setActiveComparisonOption?.(DateComparisonPeriod.YOY);
            closeDropdown();
          }}
          label={translate('label.compareToYearOverYearPeriod')}
        />
      )}
      <Button
        data-testid={TEST_IDS.generic.buttons.close}
        text={translate('button.close')}
        className='mt-3'
        color='tertiary'
        onClick={closeDropdown}
      />
    </div>
  );
};

const SelectDropdownItem = ({
  onClick,
  disabled,
  label,
  selected,
  ...rest
}: {
  onClick: () => void;
  disabled?: boolean;
  label: string;
  selected?: boolean;
} & TestAttributes) => {
  return (
    <li
      data-testid={rest['data-testid']}
      onClick={onClick}
      className={classNames('rounded-12 p-3 flex items-center gap-2', {
        'bg-gray-concrete cursor-not-allowed text-gray-silver': disabled,
        'cursor-pointer bg-white hover:bg-gray-concrete': !disabled,
      })}
    >
      <ParagraphWithTooltip weight={fontWeights.medium}>{label}</ParagraphWithTooltip>
      <div className='ml-auto'>{selected ? <CheckmarkIconFilled /> : <EmptyCheckmarkIcon />}</div>
    </li>
  );
};
