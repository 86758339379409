import { apiRequestResponseMsg, apiStatus, subFeaturesModuleSettings, tabNames, userActions } from '@va/constants';
import { GET_SSR_SETTINGS_SUCCEEDED } from '@va/dashboard/actions/api';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { getSsrSettingsSelector } from '@va/dashboard/selectors/core';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { DynamicPageType, PageTypeEnum, StaticPageType } from '@va/types/website';
import { SelectDropdownInputOption, getUrlPatternDropdownOptions } from '@va/ui/design-system';
import { isWixMiniApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import { isEqual } from 'lodash';
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateSsrSettings, useUpdateSsrSettings } from './apiClient';
import { FormValuesType, SsrSettingsType, UrlPatternType, UrlPatternTypeEnum } from './types';

const defaultFormValues: FormValuesType = {
  anyPage: true,
  clickAndScroll: false,
  minDuration: 0,
};

const defaultValues = {
  dynamicPages: [],
  staticPages: [],
  urlPatterns: [],
  isMinimumDurationActive: false,
  isTextObfuscationActive: false,
};

type RecordingsSettingsContextType = {
  formValues: FormValuesType;
  hasPermissionToEdit: boolean;
  allPages: Array<DynamicPageType | StaticPageType>;
  dynamicPages: DynamicPageType[] | undefined;
  staticPages: StaticPageType[] | undefined;
  urlPatterns: UrlPatternType[] | undefined;
  updateFormValues: (data: Object) => void;
  isMinimumDurationActive: boolean;
  isTextObfuscationActive: boolean;
  toggleIsMinimumDurationActive: () => void;
  toggleIsTextObfuscationActive: () => void;
  onPageSelect: (option: SelectDropdownInputOption) => void;
  onUrlPatternSelect: (option: SelectDropdownInputOption) => void;
  clearSelectedPage: (option: DynamicPageType | StaticPageType) => void;
  clearSelectedUrlPattern: (option: UrlPatternType) => void;
  onSaveChanges: () => void;
  isLoading: boolean;
  isLoadingSsrSettings: boolean;
  urlPatternOptions: SelectDropdownInputOption[];
  hasMadeChanges: boolean;
  onUrlPatternChange: (urlValue: string, urlPatternId: string) => void;
  cancelChanges: () => void;
  resetAllSettings: () => void;
  areSettingsDefault: boolean;
};

const RecordingsSettingsContext = createContext<RecordingsSettingsContextType>({} as RecordingsSettingsContextType);

export const RecordingsSettingsContextProvider: React.FC<PropsWithChildren<{ onSuccess?: () => void }>> = ({
  children,
  onSuccess,
}) => {
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [formValues, setFormValues] = useState<FormValuesType>(defaultFormValues);
  const [dynamicPages, setDynamicPages] = useState<DynamicPageType[] | undefined>(undefined);
  const [staticPages, setStaticPages] = useState<StaticPageType[] | undefined>(undefined);
  const [urlPatterns, setUrlPatterns] = useState<UrlPatternType[] | undefined>(undefined);
  const allPages = useMemo(() => [...(dynamicPages ?? []), ...(staticPages ?? [])], [dynamicPages, staticPages]);

  const [isMinimumDurationActive, setIsMinimumDurationActive] = useState(false);
  const [isTextObfuscationActive, setIsTextObfuscationActive] = useState(false);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const hasPermissionToEdit = useSelector((state) =>
    hasPermission(state, userActions.Settings, subFeaturesModuleSettings.sessionRecordings, tabNames.modulesSettings),
  );
  const ssrSettings = useSelector(getSsrSettingsSelector) as SsrSettingsType | undefined;
  const ssrSettingsApiStatus = useSelector((state) => getRequestStatus(state, 'getSsrSettings'));

  const isLoadingSsrSettings = useMemo(
    () => ssrSettingsApiStatus !== apiStatus.SUCCEEDED && ssrSettingsApiStatus !== apiStatus.FAILED,
    [ssrSettingsApiStatus],
  );
  const isInitialState = useMemo(
    () => dynamicPages === undefined && urlPatterns === undefined && isEqual(formValues, defaultFormValues),
    [dynamicPages, formValues, urlPatterns],
  );

  const areSettingsDefault = useMemo(
    () =>
      isEqual(formValues, defaultFormValues) &&
      isEqual(dynamicPages, defaultValues.dynamicPages) &&
      isEqual(staticPages, defaultValues.staticPages) &&
      isEqual(urlPatterns, defaultValues.urlPatterns) &&
      !isMinimumDurationActive &&
      !isTextObfuscationActive,
    [dynamicPages, formValues, isMinimumDurationActive, staticPages, urlPatterns, isTextObfuscationActive],
  );

  const ssrSettingsNotFound = useMemo(
    () => ssrSettings?.error?.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS,
    [ssrSettings?.error?.message],
  );

  const updateSsrSettings = useUpdateSsrSettings();
  const createSsrSettings = useCreateSsrSettings();

  const asyncAction = useMemo(
    () => (ssrSettingsNotFound ? createSsrSettings : updateSsrSettings),
    [createSsrSettings, ssrSettingsNotFound, updateSsrSettings],
  );

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const urlPatternOptions = useMemo(() => getUrlPatternDropdownOptions(translate), [translate]);

  const onUserAction = useCallback(() => {
    setHasMadeChanges(true);
  }, []);

  const resetHasMadeChanges = useCallback(() => {
    setHasMadeChanges(false);
  }, []);

  const setInitialValues = useCallback(() => {
    setFormValues({
      anyPage: ssrSettingsNotFound ? true : ssrSettings?.anyPage,
      clickAndScroll: ssrSettingsNotFound ? false : ssrSettings?.clickAndScroll,
      minDuration: ssrSettings?.minDuration && ssrSettings?.minDuration <= 0 ? 0 : ssrSettings?.minDuration,
    });
    setDynamicPages(ssrSettings?.dynamicPages?.map((page) => ({ ...page, type: PageTypeEnum.dynamic })));
    setStaticPages(ssrSettings?.pages?.map((page) => ({ ...page, type: PageTypeEnum.static })));
    setUrlPatterns(ssrSettings?.urlPatterns?.map((urlPattern) => ({ ...urlPattern, id: String(Math.random()) })));
    setIsMinimumDurationActive(!ssrSettings?.minDuration || ssrSettings.minDuration < 0 ? false : true);
    setIsTextObfuscationActive(ssrSettings?.textObfuscation ?? false);
  }, [ssrSettings, ssrSettingsNotFound]);

  // Populate initial state
  useEffect(() => {
    if (!isInitialState) return;
    if (isLoadingSsrSettings) return;
    setInitialValues();
  }, [isInitialState, isLoadingSsrSettings, setInitialValues, ssrSettings, ssrSettingsNotFound]);

  const updateFormValues = useCallback(
    (data: Object) => {
      onUserAction();

      setFormValues((prev) => ({ ...prev, ...data }));
    },
    [onUserAction],
  );

  useEffect(() => {
    if (!asyncAction.isSucceeded) return;
    onSuccess?.();
    asyncAction.resetSuccessState();
    updateFormValues({ anyPage: asyncAction.data?.anyPage });
    dispatch({ type: GET_SSR_SETTINGS_SUCCEEDED, data: asyncAction.data });
    showSuccessNotification();
    resetHasMadeChanges();
  }, [asyncAction, dispatch, onSuccess, resetHasMadeChanges, showSuccessNotification, updateFormValues]);

  useEffect(() => {
    if (!asyncAction.error) return;
    asyncAction.clearError();
    showErrorNotification();
  }, [asyncAction, showErrorNotification]);

  const toggleIsMinimumDurationActive = useCallback(() => {
    onUserAction();

    setIsMinimumDurationActive((prev) => {
      if (prev) {
        updateFormValues({ minDuration: '0' });
      }

      if (isWixMiniApp()) {
        DashboardAppWorker.trackEvent({
          category: 'General Settings',
          action: 'onclick',
          label: `Record Only Sessions With a Minimum Duration ${!prev ? 'Enabled' : 'Disabled'}`,
          value: 'Button',
        });
      }

      return !prev;
    });
  }, [onUserAction, updateFormValues]);

  const toggleIsTextObfuscationActive = useCallback(() => {
    onUserAction();

    setIsTextObfuscationActive((prev) => {
      if (isWixMiniApp()) {
        DashboardAppWorker.trackEvent({
          category: 'General Settings',
          action: 'onclick',
          label: `Obscure Recording Text ${!prev ? 'Enabled' : 'Disabled'}`,
          value: 'Button',
        });
      }

      return !prev;
    });
  }, [onUserAction]);

  const onPageSelect = useCallback(
    (option: SelectDropdownInputOption) => {
      onUserAction();

      if (option['type'] === PageTypeEnum.dynamic) {
        setDynamicPages((prev) => {
          const isAlready = prev?.find((existentPage) => existentPage.key === option.id);
          if (isAlready) return prev;
          const newPage: DynamicPageType = {
            key: String(option.id),
            name: option.label,
            pattern: option.value,
            type: PageTypeEnum.dynamic,
          };
          return [...(prev ?? []), newPage];
        });
      }

      if (option['type'] === PageTypeEnum.static) {
        setStaticPages((prev) => {
          const isAlready = prev?.find((existentPage) => existentPage.key === option.id);
          if (isAlready) return prev;
          const newPage: StaticPageType = {
            key: String(option.id),
            title: option.label,
            url: option.value,
            type: PageTypeEnum.static,
          };
          return [...(prev ?? []), newPage];
        });
      }
    },
    [onUserAction],
  );

  const onUrlPatternSelect = useCallback(
    (option: SelectDropdownInputOption) => {
      onUserAction();

      const newUrlPattern: UrlPatternType = {
        url: '',
        type: option.value as UrlPatternTypeEnum,
        id: String(Math.random()),
      };
      setUrlPatterns((prev) => [...(prev ?? []), newUrlPattern]);
    },
    [onUserAction],
  );

  const onUrlPatternChange = useCallback(
    (urlValue: string, urlPatternId: string) => {
      onUserAction();

      setUrlPatterns((prev) =>
        prev?.map((urlPattern) => {
          if (urlPattern.id !== urlPatternId) return urlPattern;
          return {
            ...urlPattern,
            url: urlValue,
          };
        }),
      );
    },
    [onUserAction],
  );

  const clearSelectedPage = useCallback(
    (option: DynamicPageType | StaticPageType) => {
      onUserAction();

      if (option?.type === PageTypeEnum.dynamic) {
        setDynamicPages((prev) => prev?.filter((page) => page.key !== option.key));
      }
      if (option.type === PageTypeEnum.static) {
        setStaticPages((prev) => prev?.filter((page) => page.key !== option.key));
      }
    },
    [onUserAction],
  );

  const clearSelectedUrlPattern = useCallback(
    (option: UrlPatternType) => {
      onUserAction();

      setUrlPatterns((prev) =>
        prev?.filter((urlPattern) => urlPattern.id !== option.id || urlPattern.url !== option.url),
      );
    },
    [onUserAction],
  );

  const onSaveChanges = useCallback(() => {
    const dynamicPageKeys = dynamicPages?.map((page) => page.key) || [];
    const staticPageKeys = staticPages?.map((page) => page.key) || [];
    const data = {
      anyPage: dynamicPages?.length === 0 || urlPatterns?.length === 0 ? true : !!formValues.anyPage,
      clickAndScroll: !!formValues.clickAndScroll,
      dynamicPageKeys: formValues.anyPage ? [] : dynamicPageKeys,
      pageKeys: formValues.anyPage ? [] : staticPageKeys,
      minDuration: isMinimumDurationActive ? Number(formValues.minDuration ?? 0) : 0,
      urlPatterns: formValues.anyPage ? [] : removeIdsFromUrlPatterns(urlPatterns ?? []),
      textObfuscation: isTextObfuscationActive,
    };

    resetHasMadeChanges();
    asyncAction.execute(data);
  }, [
    asyncAction,
    dynamicPages,
    formValues.anyPage,
    formValues.clickAndScroll,
    formValues.minDuration,
    isMinimumDurationActive,
    isTextObfuscationActive,
    resetHasMadeChanges,
    staticPages,
    urlPatterns,
  ]);

  const cancelChanges = useCallback(() => {
    setInitialValues();
    resetHasMadeChanges();
  }, [resetHasMadeChanges, setInitialValues]);

  const resetAllSettings = useCallback(() => {
    setFormValues(defaultFormValues);
    setDynamicPages(defaultValues.dynamicPages);
    setStaticPages(defaultValues.staticPages);
    setUrlPatterns(defaultValues.urlPatterns);
    setIsMinimumDurationActive(defaultValues.isMinimumDurationActive);
    setIsTextObfuscationActive(defaultValues.isTextObfuscationActive);

    onSaveChanges();
  }, [onSaveChanges]);

  return (
    <RecordingsSettingsContext.Provider
      value={{
        allPages,
        hasPermissionToEdit,
        dynamicPages,
        staticPages,
        urlPatterns,
        formValues,
        updateFormValues,
        isMinimumDurationActive,
        isTextObfuscationActive,
        toggleIsMinimumDurationActive,
        toggleIsTextObfuscationActive,
        urlPatternOptions,
        onPageSelect,
        clearSelectedPage,
        clearSelectedUrlPattern,
        onSaveChanges,
        isLoading: asyncAction.isLoading,
        isLoadingSsrSettings,
        onUrlPatternSelect,
        onUrlPatternChange,
        hasMadeChanges,
        cancelChanges,
        resetAllSettings,
        areSettingsDefault,
      }}
    >
      {children}
    </RecordingsSettingsContext.Provider>
  );
};

const useRecordingsSettingsContext = () => useContext(RecordingsSettingsContext);
export default useRecordingsSettingsContext;

function removeIdsFromUrlPatterns(urlPatterns: UrlPatternType[]) {
  return urlPatterns?.map(({ id, ...rest }) => rest);
}
