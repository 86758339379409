import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';

type ReportBlockDisabledOverlayProps = {
  message?: string;
};

export const ReportBlockDisabledOverlay = ({ message }: ReportBlockDisabledOverlayProps) => {
  return (
    <div className='absolute top-0 left-0 w-full h-full backdrop-blur-2px flex justify-center items-center'>
      {message && (
        <Paragraph size={paragraphSizes.large} weight={fontWeights.semibold} colorClassName='text-black-lighter-60'>
          {message}
        </Paragraph>
      )}
    </div>
  );
};
