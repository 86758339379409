import { TEST_IDS } from '@va/constants';
import { FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Paragraph, fontWeights } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { DropdownArrowContainer } from './DropdownArrowContainer';
import { MetricSelectorDropdown } from './MetricSelectorDropdown';

type MetricSelectorProps = {
  translationsMap: {
    [x: string]: string;
  };
  isIconVisible?: boolean;
  selectedMetric: string;
  isDisabled: boolean;
  selectMetric: (metric: string) => void;
  metricsList: string[];
};

export default function MetricSelector({
  isIconVisible = true,
  translationsMap,
  selectedMetric,
  isDisabled,
  selectMetric,
  metricsList,
}: MetricSelectorProps) {
  const translate = useTranslate();

  return (
    <TooltipWrapper
      trigger='click'
      interactive={true}
      arrow={false}
      useDefaultStyle={false}
      tooltipClassNames='rounded-12 shadow-md'
      content={
        <MetricSelectorDropdown
          selectedMetric={selectedMetric}
          selectMetric={selectMetric}
          metricsList={metricsList}
          translationsMap={translationsMap}
        />
      }
      disabled={isDisabled}
      useParentWidth
    >
      <button
        className={classNames(
          'p-15px bg-white-snow rounded-15 w-full flex items-center justify-between whitespace-nowrap ',
          { 'cursor-not-allowed': isDisabled },
        )}
        disabled={isDisabled}
        data-testid={TEST_IDS.generic.filters.dropdownContainer}
      >
        <div className='flex items-center mx-1 gap-3'>
          {isIconVisible && <FilterIcon color='#696969' />}
          <Paragraph weight={fontWeights.medium}>{translate(translationsMap[selectedMetric])}</Paragraph>
        </div>
        <DropdownArrowContainer />
      </button>
    </TooltipWrapper>
  );
}
