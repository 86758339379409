import { CommonFilterParams, Filter, FilterOperators } from '@va/types/filters';
import { HTMLInputTypeAttribute } from 'react';
import { TextFilterInput } from './TextFilterInput';
import { TextFilterInputApplied } from './TextFilterInputApplied';

export type TextFilterValue = number | string;
type PlaceholderFunc = (operator: FilterOperators) => string;

type TextFilterInputProps = {
  placeholder?: string | PlaceholderFunc;
  label?: string;
  type?: HTMLInputTypeAttribute;
  className?: string;
};

type TextFilterParams = { allowMultiSelect?: boolean } & CommonFilterParams<TextFilterValue, TextFilterInputProps>;

export class TextFilter extends Filter<TextFilterValue, TextFilterInputProps> {
  constructor(params: TextFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.contains,
      input: TextFilterInput,
      appliedInput: TextFilterInputApplied,
    });
  }
}
