import { timezones } from '@va/assets';
import jstz from 'jstz';
import { SWRConfiguration } from 'swr';

export const TOTAL_KEY = 'totalValues';

type PlatformWithCode = {
  id: number;
  // TODO update
  code: string;
  name: string;
};

export const platform = {
  WIX: 'WIX',
  CUSTOM: 'CUSTOM',
  KYVIO: 'KYVIO',
  WEEBLY: 'WEEBLY',
  WORDPRESS: 'WORDPRESS',
  DRUPAL: 'DRUPAL',
  OCTOBER: 'OCTOBER',
  TYPO: 'TYPO',
  JOOMLA: 'JOOMLA',
  MAGENTO: 'MAGENTO',
  OPENCART: 'OPENC',
  AAAS: 'AAAS',
  PIXPA: 'PIXPA',
  // todo make sure these are used or remove them
  DRUPAL7: 'DRUPAL7',
  DRUPAL8: 'DRUPAL8',
};

export const platforms: Array<PlatformWithCode> = [
  { id: 0, code: platform.WIX, name: 'WiX' },
  { id: 1, code: platform.CUSTOM, name: 'Custom' },
  { id: 2, code: platform.WORDPRESS, name: 'Wordpress' },
  { id: 3, code: platform.KYVIO, name: 'Kyvio' },
  { id: 4, code: platform.DRUPAL, name: 'Drupal' },
  { id: 5, code: platform.DRUPAL7, name: 'Drupal7' },
  { id: 6, code: platform.DRUPAL8, name: 'Drupal8' },
  { id: 7, code: platform.TYPO, name: 'Typo3' },
  { id: 8, code: platform.OCTOBER, name: 'October CMS' },
  { id: 9, code: platform.MAGENTO, name: 'Magento' },
  { id: 10, code: platform.OPENCART, name: 'OpenCart' },
  { id: 11, code: platform.WEEBLY, name: 'Weebly' },
];

export const NO_PLATFORM = { id: 999, code: undefined, name: '' };

export const platformsById = platforms.reduce((accumulator: Record<string, PlatformWithCode>, platform) => {
  accumulator[platform.id.toString()] = platform;
  return accumulator;
}, {});
export const platformsByCode = platforms.reduce((accumulator: Record<string, PlatformWithCode>, platform) => {
  accumulator[platform.code] = platform;
  return accumulator;
}, {});

export const allowedPlatformsForWebsiteCreation = {
  WIX: 'WIX',
  CUSTOM: 'CUSTOM',
  WEEBLY: 'WEEBLY',
  WORDPRESS: 'WORDPRESS',
  DRUPAL: 'DRUPAL',
  OCTOBER: 'OCTOBER',
  TYPO: 'TYPO',
  JOOMLA: 'JOOMLA',
  MAGENTO: 'MAGENTO',
  OPENCART: 'OPENC',
};

export const deviceSizesMap = {
  mobile: 0,
  tablet: 1,
  desktop: 2,
  current: 3,
};

export const manualScreenshotPopupResolutions = {
  [deviceSizesMap.mobile]: { width: 375, height: 667 },
  [deviceSizesMap.tablet]: { width: 768, height: 1024 },
  [deviceSizesMap.desktop]: { width: 1366, height: 768 },
  [deviceSizesMap.current]: { width: window.innerWidth, height: window.innerHeight },
};

export const events = [
  {
    label: 'On Click',
    value: 'onClick',
  },
  {
    label: 'On Abort',
    value: 'onAbort',
  },
  {
    label: 'On Blur',
    value: 'onBlur',
  },
  {
    label: 'On Change',
    value: 'onChange',
  },
  {
    label: 'On Double Click',
    value: 'onDoubleClick',
  },
  {
    label: 'On Error',
    value: 'onError',
  },
];

export const flagKeys = {
  LAST_ANNOUNCEMENT_VIEWED_AT: 'lastAnModalViewedAt',
  LAST_ACCOUNT_BASED_ANNOUNCEMENT_VIEWED_AT: 'lastACmodalViewedAt',
  LAST_WEBSITE_BASED_ANNOUNCEMENT_VIEWED_AT: 'lastWBmodalViewedAt',
  ONBOARDING_FINISHED: 'onboardingFinished',
  LAST_COMPLETED_ONBOARDING_STEP: 'onboardingStep',
};

export const createEventTagModalView = {
  CREATE_EVENT_TAG_VIEW: 'createEventTag',
  COPY_CREATED_EVENT_TAG_SNIPPET: 'copyCreatedEventTagSnippet',
};

export const FLAG_ICONS_VERSION = '2.8.0';

export const FILTERS_NAME = {
  ID: 'id',
  URL_CONTAIN: 'containsPage',
  URL_NOT_CONTAIN: 'notContainsPage',
  EXACT_URL: 'exactUrl',
  COUNTRY: 'country',
  DEVICE: 'devices',
  VISITOR_TYPE: 'status',
  OPERATING_SYSTEM: 'os',
  BROWSER: 'browser',
  WATCHED: 'watched',
  HAS_NOTE: 'note',
  STARRED: 'starred',
  EXIT_PAGE: 'exitPage',
  ENTRY_PAGE: 'entryPage',
  DURATION: 'duration',
  PAGE_COUNT: 'pageCount',
  FUNNEL_DROPOUTS: 'funnelDropouts',
  RECORDING_TAGS: 'tags',
  ONLY_SSR_SESSIONS: 'onlySsrSessions',
  VISITOR_IP: 'ip',
  COMPANY_NAME: 'companyName',
  ALARMING_EVENT_CONTAIN: 'alarmingEvents',
  TRIGGER: 'trigger',
  REFERRER_URL: 'referrerUrls',
  UTM_PARAMETERS: 'utmParameters',
  ECOM: 'ecom',
  TRAFFIC_CHANNEL: 'trafficChannel',
  TRAFFIC_CHANNEL_DIRECT: 'traffic-channel-direct',
  TRAFFIC_CHANNEL_SOCIAL: 'traffic-channel-social',
  TRAFFIC_CHANNEL_SEARCH: 'traffic-channel-search',
  TRAFFIC_CHANNEL_PAID: 'traffic-channel-paid',
  TRAFFIC_CHANNEL_REFERRALS: 'traffic-channel-referrals',
  TRAFFIC_CHANNEL_EMAIL: 'traffic-channel-email',
  AD_CAMPAIGN_LABEL: 'adCampaignLabels',
  RECORDING_ID: 'recordingId',
} as const;

export const WIX_MINI_FILTER_EVENT_NAME: Record<string, string> = {
  [FILTERS_NAME.ALARMING_EVENT_CONTAIN]: 'ABE',
  [FILTERS_NAME.REFERRER_URL]: 'Referrer Url',
  [FILTERS_NAME.TRAFFIC_CHANNEL]: 'Traffic Channel',
  [FILTERS_NAME.TRAFFIC_CHANNEL_DIRECT]: 'Traffic Channel Direct',
  [FILTERS_NAME.TRAFFIC_CHANNEL_SOCIAL]: 'Traffic Channel Social',
  [FILTERS_NAME.TRAFFIC_CHANNEL_SEARCH]: 'Traffic Channel Search',
  [FILTERS_NAME.TRAFFIC_CHANNEL_PAID]: 'Traffic Channel Paid',
  [FILTERS_NAME.TRAFFIC_CHANNEL_REFERRALS]: 'Traffic Channel Referrals',
  [FILTERS_NAME.TRAFFIC_CHANNEL_EMAIL]: 'Traffic Channel Email',
  [FILTERS_NAME.BROWSER]: 'Browser',
  [FILTERS_NAME.OPERATING_SYSTEM]: 'OS',
  [FILTERS_NAME.URL_CONTAIN]: 'URL Contains',
  [FILTERS_NAME.AD_CAMPAIGN_LABEL]: 'UTM Campaign Name',
  [FILTERS_NAME.COUNTRY]: 'Country',
  [FILTERS_NAME.DEVICE]: 'Device',
  [FILTERS_NAME.VISITOR_TYPE]: 'Visitor Type',
  [FILTERS_NAME.WATCHED]: 'Watched',
  [FILTERS_NAME.STARRED]: 'Starred',
  [FILTERS_NAME.ENTRY_PAGE]: 'Entry Page',
  [FILTERS_NAME.EXIT_PAGE]: 'Exit Page',
  [FILTERS_NAME.URL_NOT_CONTAIN]: 'URL Not Contains',
  [FILTERS_NAME.DURATION]: 'Duration',
  [FILTERS_NAME.PAGE_COUNT]: 'Page Count',
  [FILTERS_NAME.RECORDING_ID]: 'Recording ID',
  [FILTERS_NAME.HAS_NOTE]: 'Has Note',
};

export const buttonStatus = {
  INITIAL: 'INITIAL',
  ACTIVE: 'ACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const logoSizeLimit = 1 * 1048576; //1mb

// The same ReportOptions items order is used when generate exported pdf. Keep it this way.
export const ReportOptions = [
  'visitors',
  'uniqueVisitors',
  'os',
  'topCampaigns',
  'bounceRate',
  'conversions',
  'trafficCharts',
  'mediumSources',
  'visitorsByCountry',
  'campaignVisitors',
  'campaignCharts',
];

/**
 * @deprecated
 * @see src/website-viewer/components/tabs/behaviour/heatmaps-v2/context/HeatmapProvider.tsx - HeatmapStatus
 */
export const heatmapStatus = { PAUSED: 'PAUSED', ACTIVE: 'COLLECTING_DATA' };

export const mobileOS = {
  android: 'android',
  ios: 'ios',
  windows: 'windows',
  unknown: 'unknown',
};

export const DEVICE_TYPE = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  PHONE: 'mobile',
  ALL: 'all',
};

export const COMPETITION_TYPES = ['website', 'competition'];

export const OTHERS_KEY = 'o';

export const UNKNOWN_KEY = 'UNKNOWN';

export const BEHAVIOUR_FEATURE = { RECORDINGS: 'recordings', HEATMAPS: 'heatmaps', FUNNELS: 'funnels' };

export const HEATMAP_EVENT_TYPE = {
  CLICK: 'click',
  MOVE: 'move',
  SCROLL: 'scroll',
};

export const reportType = {
  DAILY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  NEVER: 3,
};

export const pageType = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
};

export const status = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
};

export const websiteStatus = {
  ...status,
};

export const GRAPH_LEVEL = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const EXPORT_FORMAT = {
  CSV: 'csv',
  XLSX: 'xlsx',
};

export const TRAFFIC_FILTER = {
  PAST_WEEK: 'PAST_WEEK',
  PAST_YEAR: 'PAST_YEAR',
  SPECIFIC_YEAR: 'SPECIFIC_YEAR',
};

export const defaultTrafficFilter = {
  period: TRAFFIC_FILTER.PAST_WEEK,
  specificYear: null,
};

export const STEP_FILTER = {
  PAST_DAY: 'day',
  PAST_WEEK: 'week',
  PAST_MONTH: 'month',
  PAST_YEAR: 'year',
};

export const defaultStepFilter = {
  period: STEP_FILTER.PAST_DAY,
};

export const cookieName = {
  CONSENT_GRANT: 'consent_grant',
};

export const SSR_INSTALL_VIDEO_URL = 'https://www.youtube.com/embed/yizZ03REOLI';
export const SSR_INSTALL_DIRECT_VIDEO_URL = 'https://www.youtube.com/watch?v=yizZ03REOLI';
export const ADD_TRACKING_CODE_VIDEO_URL = 'https://www.youtube.com/embed/cVrW5MBkOpE';
export const ADD_TRACKING_CODE_DIRECT_VIDEO_URL = 'https://www.youtube.com/watch?v=cVrW5MBkOpE';

export const SSR_FEATURE_VIDEO_URL = 'https://www.youtube.com/embed/HN4-axxAr9s';
export const SSR_FEATURE_DIRECT_VIDEO_URL = 'https://www.youtube.com/watch?v=HN4-axxAr9s';
export const HEATMAP_FEATURE_VIDEO_URL = 'https://www.youtube.com/embed/tpn8eXDA4dY';
export const HEATMAP_FEATURE_DIRECT_VIDEO_URL = 'https://www.youtube.com/watch?v=tpn8eXDA4dY';
export const FUNNEL_FEATURE_VIDEO_URL = 'https://www.youtube.com/embed/eVO6RKdgvNI';
export const FUNNEL_FEATURE_DIRECT_VIDEO_URL = 'https://www.youtube.com/watch?v=eVO6RKdgvNI';

export const defaultWebsiteId = '00000000-0000-0000-0000-000000000000';
const determinedTimezone = jstz.determine();
const supportedTimezone = timezones.find((o) => o.value === determinedTimezone.name());
export const defaultTimezone = supportedTimezone ? window.timezone ?? determinedTimezone.name() : 'Europe/Berlin';

export const documentTitle = {
  appsumo: 'TWIPLA - Appsumo',
  default: 'Dashboard',
};

export const cardView = {
  INITIAL: 'initial',
  CONFIRMATION: 'confirmation',
};

export const recordingsSettingsView = {
  WELCOME: 'welcomeRecordings',
  RECORDINGSPAGE: 'recordingsPage',
  RECORDINGSRULES: 'recordingsRules',
};

export const inputMode = {
  ADD: 'add',
  EDIT: 'edit',
};

export const GDPR_RELEASE_DATE_UNIX_TS = 1526037720;
export const LOCATIONS_RELEASE_DATE_UNIX_TS = 1507248000; // 6 oct 2017 00:00
export const DEVICE_TYPE_RELEASE_DATE_UNIX_TS = 1558004400;
export const ADS_RELEASE_DATE_UNIX_TS = 1533254400; // 3Aug2018
export const SSR_BETA_RELEASE_DATE_UNIX_TS = 1559088000; // 29May2019
export const STATS_OVERVIEW_RELEASE_DATE_UNIX_TS = 1592265600; // 16Jun2020 - dev release date; 02Jul2020 - live release date
export const MAX_RECORDINGS_PANEL_MOBILE_WIDTH = 1180;
export const MAX_WIDTH_TO_ADJUST_HISTORY_COLUMN = 1250;
export const SSR_V0_RELEASE_DATE_UNIX_TS = 1571140800; // 15Oct2019
export const MAX_PANEL_MOBILE_WIDTH = 992;
export const MAX_MOBILE_VIEW_WIDTH = 768;
export const MAX_MOBILE_XS_WIDTH = 450;
export const MAX_ADS_TRAFFIC_CHART_ITEMS = 13;
export const MAX_HORIZONTAL_BARS_DATA = 20;
export const WHITELISTED_DOMAINS_MAX_COUNT = 100;
export const BLACKLISTED_DOMAINS_MAX_COUNT = 100;
export const REQUIRED_COLOR = '#ff5252';
export const MAX_LATEST_VISITORS_LIMIT = 50000;

export const POLLS_SURVEYS_PUBLISHED_ON_TRACKING_START = 1688648400000;

export const storageSpecialEventItems = {
  halloween: 'halloween-theme-', //we also add the year
};

export const INVALID_DATE_RANGE_TOOLTIP_ID = 'invalid-date-range-button';
export const INVALID_DATE_PERIOD_TOOLTIP_ID = 'invalid-date-period-dropdown';

export const TOOLTIPS_IDS = {
  contributorRoleTooltipId: 'contributor-role-not-allowed-tooltip',
  heatmapCardTooltipId: 'heatmap-card-tooltip',
  urlMatchingTypeId: 'icon-tooltip-id-matching-type',
  videoOverlayTooltipId: 'video-overlay-tooltip',
  activateConsentlessTrackingInfoTooltipId: 'activate-consentless-tracking_info-tooltip-id',
  liveVisitorsTooltip: 'live-visitors-tooltip',
};

export const commentStatus = {
  SHOW_ADD_COMMENT_TEXT: 'SHOW_ADD_COMMENT_TEXT',
  DISPLAY_HEADER: 'DISPLAY_HEADER',
  UPSERT_HEADER: 'UPSERT_HEADER',
};

export const MATCHING_TYPES = [
  {
    value: 'SIMPLE',
    labelKey: 'modal.recordingsPage.matchingType.simple',
    match: 0,
  },
  {
    value: 'EXACT',
    labelKey: 'modal.recordingsPage.matchingType.exact',
    match: 1,
  },
  {
    value: 'STARTS',
    labelKey: 'modal.recordingsPage.matchingType.startsWith',
    match: 2,
  },
  {
    value: 'ENDS',
    labelKey: 'modal.recordingsPage.matchingType.endsWith',
    match: 4,
  },
  {
    value: 'CONTAINS',
    labelKey: 'modal.recordingsPage.matchingType.contains',
    match: 3,
  },
];

export const URL_PATTERN_MATCH_TYPES = {
  SIMPLE: 0,
  EXACT: 1,
  STARTS: 2,
  CONTAINS: 3,
  ENDS: 4,
};

export const devicePageBarChartIds = {
  deviceId: 'deviceId',
  osId: 'osId',
  browserId: 'browserId',
  displayId: 'displayId',
};

export const urlViews = {
  URL: 'url',
  TITLE: 'title',
};

export const defaultSsrSettings = {
  paused: true,
  anyPage: true,
  pageKeys: [],
  dynamicPageKeys: [],
  urlPatterns: [],
  clickAndScroll: false,
  minDuration: null,
};

export const businessSector = [
  { transKey: 'businessSector.administratorAndGovernment', value: 1 },
  { transKey: 'businessSector.architectureAndEngineering', value: 2 },
  { transKey: 'businessSector.artsMediaAndEntertainment', value: 3 },
  { transKey: 'businessSector.businessAndFinance', value: 4 },
  { transKey: 'businessSector.cleaningAndMaintenanceService', value: 5 },
  { transKey: 'businessSector.communityAndSocialService', value: 6 },
  { transKey: 'businessSector.construction', value: 7 },
  { transKey: 'businessSector.eCommerce', value: 8 },
  { transKey: 'businessSector.education', value: 9 },
  { transKey: 'businessSector.farmingFishingAndForestry', value: 10 },
  { transKey: 'businessSector.foodAndRestaurants', value: 11 },
  { transKey: 'businessSector.healthcare', value: 12 },
  { transKey: 'businessSector.it', value: 13 },
  { transKey: 'businessSector.legalServices', value: 14 },
  { transKey: 'businessSector.management', value: 15 },
  { transKey: 'businessSector.production', value: 16 },
  { transKey: 'businessSector.sales', value: 17 },
  { transKey: 'businessSector.transportation', value: 18 },
  { transKey: 'businessSector.other', value: 0 },
];

export const businessType = [
  // Values 2, 3, 4 moved into a separate dropdown - businessSize; see VISA-10700
  { transKey: 'businessType.solopreneur', value: 1 },
  { transKey: 'businessType.eCommerce', value: 5 },
  { transKey: 'businessType.agency', value: 6 },
  { transKey: 'businessType.nonProfitOrganization', value: 7 },
  { transKey: 'businessType.publicSector', value: 8 },
  { transKey: 'businessType.software', value: 9 },
];

export const businessSize = [
  { transKey: 'businessSize.1to10', value: 1 },
  { transKey: 'businessSize.10to50', value: 2 },
  { transKey: 'businessSize.50to250', value: 3 },
  { transKey: 'businessSize.250to1000', value: 4 },
  { transKey: 'businessSize.1000+', value: 5 },
];

export const howDidYouFindUs = [
  { transKey: 'accountSettings.personalData.howDidYouFindUs.googleOrBing', value: 0 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.socialMedia', value: 1 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.webinarOrEvent', value: 2 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.community', value: 3 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.reviewSites', value: 4 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.newsletter', value: 5 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.wordOfMouth', value: 6 },
  { transKey: 'accountSettings.personalData.howDidYouFindUs.other', value: 7 },
];

export const FUNNEL_STATUS = {
  DRAFT: 0,
  ACTIVE: 1,
};

export const FUNNEL_LEVEL_TYPE = {
  SIMPLE_PAGE: 0,
  DYNAMIC_PAGE: 1,
  URL_PATTERN: 2,
};

export const FUNNEL_LEVEL_RANGE = {
  HIGH: '80-100',
  MEDIUM: '40-80',
  LOW: '0-40',
};

export const EMBEDDED_VIDEO_TRANS_KEY_PREFIX = 'video.youtube.embed';
export const FEATURE_VIDEO_TRANS_KEY_PREFIX = `${EMBEDDED_VIDEO_TRANS_KEY_PREFIX}.tab`;

export const YOUTUBE_CHANNEL_ID = 'UCfcNSn97px_PClXo2dJuTcg';

export const CONSENT_FLAG = {
  YOUTUBE: 'youTubeConsent',
};

export const allowedWebsiteIdsStatsOverviewWithNoStartDateLimitation = ['326865a9-6d58-11e8-834c-0242ac120005'];

export const competitionGraphSortingOptions = [
  {
    labelTransKey: 'competition.sort.myFirst',
    value: 1,
  },
  {
    labelTransKey: 'competition.sort.myCompetitionFirst',
    value: 2,
  },
];

export const COMPETITION_SORTING_CATEGORY = {
  BROWSER_SORTING: 'browserSortingSelectedOption',
  OPERATING_SYSTEM_SORTING: 'operatingSystemSortingSelectedOption',
  SCREEN_RESOLUTION_SORTING: 'screenresolutionSortingSelectedOption',
  DEVICES_SORTING: 'devicesSortingSelectedOption',
};
export const supportEmailAddress = 'support@twipla.com';
// eslint-disable-next-line no-useless-escape

export const userStatus = {
  ...status,
};

export const notificationTransKeys = {
  visitsLimitReachedUpgrade: 'notifications.visitsLimit.subtitle.upgradePlan',
};

export const buttonGroupFilterOptions = {
  all: 'all',
  enabled: 'enabled',
  disabled: 'disabled',
};

export const PENDING_CONTRIBUTOR_REQUEST_USER_ROLES = {
  REQUEST_EDITOR: 'request-editor',
  REQUEST_WATCHER: 'request-watcher',
};

export const userActions = {
  Create: 'Create',
  Edit: 'Edit',
  Delete: 'Delete',
  ChangeStatus: 'ChangeStatus',
  Reset: 'Reset',
  Import: 'Import',
  Settings: 'Settings',
  Enable: 'Enable',
};

export const filterButtonOptions = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inActive',
};

export const sortingOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const swrConfigOptionsDashboard: SWRConfiguration = {
  revalidateOnFocus: false,
};

export const UNIDENTIFIED_COUNTRY_CODE = 'UNIDENTIFIED_COUNTRY_CODE';

/**
 * IDs for the default report blocks we show throught the app, outside of custom dashboards
 * Used for saving report block specific features:
 * 1. filter templates
 * * !!! Any changes to the existent values after it is already in production will introduce issues
 */
export const DEFAULT_REPORT_BLOCK_IDS = {
  pageDashboard: 'pageDashboard',
  heatmaps: 'heatmaps',
  latestVisitorsTable: 'latestVitorsTable',
  recordingsTable: 'recordingsTable',
  eventTrackingTable: 'eventTrackingTable',
  autoEventTrackingTable: 'autoEventTrackingTable',
  alarmingBehaviorTrackingTable: 'alarmingBehaviorTrackingTable',
  agencyCommisionTable: 'agencyCommisionTable',
  manageWhiteLabelWebsitesTable: 'manageWhiteLabelWebsitesTable',
  visitsSessionsVisitors: 'mainDashboardRB',
  allFunnnels: 'allFunnels',
  referringSites: 'referringSites',
  overallTraffic: 'overallTraffic',

  // main dashboard cards
  bounceRateCard: 'bounceRateCard',
  topPageVisits: 'topPageVisits',
  usabilityScore: 'usabilityScore',
  disabledUsabilityScore: 'disabledUsabilityScore',
  websiteErrors: 'websiteErrors',
  disabledWebsiteErrors: 'disabledWebsiteErrors',

  // traffic-structure
  trafficShareOverview: 'trafficShareOverview',
  trafficShareInDepth: 'trafficShareInDepth',
  sessionDurationOverview: 'sessionsDurationOverview',
  sessionDurationInDepth: 'sessionDurationInDepth',
  pagesPerVisitsOverview: 'pagesPerVisitsOverview',
  pagsPerVisitsInDepth: 'pagsPerVisitsInDepth',
  bounceRateOverview: 'bounceRateOverview',
  bounceRateInDepth: 'bounceRateInDepth',

  ecom: {
    mainKpiReport: 'ecommerceKPIChart',
    productKpis: 'viewedProducts',
    productLists: 'productsList',
    salesKpis: 'ecomSalesKpis',
    revenueByProduct: 'ecomRevenueByProduct',
    unitsSoldByProduct: 'ecomUnitsSoldByProduct',
    trafficStructureInDepth: 'ecomTrafficStructureInDepth',
  },

  //UTM campaigns
  myUtmCampaigns: 'myUtmCampaigns',
  myUtmCampaignDetails: 'myUtmCampaignDetails',
};

/**
 * Used for identifying report blocks for custom dashboards
 * Value is saved in DB in order to know what component to render
 * !!! Any changes to the existent values after it is already in production will introduce issues
 */
export const DEFAULT_REPORT_BLOCK_TYPE = {
  masterDashboard: {
    visitsSessionsVisitors: 'dashboard-visitsSessionsVisitors',
    topPagesByVisits: 'dashboard-topPagesByVisits',
    bounceRate: 'dashboard-bounceRate',
    topCountries: 'dashboard-topCountries',
    liveVisitorsMap: 'dashboard-liveVisitorsMap',
    latestVisitorsOverview: 'dashboard-latestVisitorsOverview',
    topLandingPages: 'dashboard-topLandingPages',
    topReferrers: 'dashboard-topReferrers',
    topVisitedPages: 'dashboard-topVisitedPages',
    topOperatingSystems: 'dashboard-topOperatingSystems',
    topBrowsers: 'dashboard-topBrowsers',
    topDisplayResolutions: 'dashboard-topDisplayResolutions',
    topDevices: 'dashboard-topDevices',
    recordingsFeatureCard: 'dashboard-recordingsFeatureCard',
    eventsFeatureCard: 'dashboard-eventsFeatureCard',
    heatmapsFeatureCard: 'dashboard-heatmapsFeatureCard',
    funnelsFeatureCard: 'dashboard-funnelsFeatureCard',
    pollsFeatureCard: 'dashboard-pollsFeatureCard',
    surveysFeatureCard: 'dashboard-surveysFeatureCard',
    usabilityScoreCard: 'dashboard-usabilityScoreCard',
    disabledUsabilityScoreCard: 'dashboard-disabledUsabilityScoreCard',
    websiteErrorsCard: 'dashboard-websiteErrorsCard',
    disabledWebsiteErrorsCard: 'dashboard-disabledWebsiteErrorsCard',
  },

  traffic: {
    sessionsByChannelOverview: 'traffic-sessionsByChannelOverview',
    sessionsByChannelInDepth: 'traffic-sessionsByChannelInDepth',
    averagePagesPerSessionOverview: 'traffic-averagePagesPerSessionOverview',
    averagePagesPerSessionInDepth: 'traffic-averagePagesPerSessionInDepth',
    sessionDurationOverview: 'traffic-sessionDurationOverview',
    sessionDurationInDepth: 'traffic-sessionDurationInDepth',
    bounceRateInDepth: 'traffic-bounceRateInDepth',
    bounceRateOverview: 'traffic-bounceRateOverview',
  },

  outgoingTraffic: {
    overallTraffic: 'outgoingTraffic-overallTraffic',
  },

  pages: {
    landing: 'pages-landing',
    conversion: 'pages-conversion',
    referrers: 'pages-referrers',
  },

  devices: {
    useOfDevicesOverview: 'devices-useOfDevicesOverview',
    osUsage: 'devices-osUsage',
    browserUsage: 'devices-browserUsage',
    screenSizeUsage: 'devices-screenSizeUsage',
  },

  utm: {
    visitorsGraph: 'utm-visitorsGraph',
    topPerforming: 'utm-topPerforming',
    topTerm: 'utm-topTerm',
    topSource: 'utm-topSource',
    topMedium: 'utm-topMedium',
    topContent: 'utm-topContent',
    latestVisitors: 'utm-latestVisitors',
    visitorsMap: 'utm-visitorsMap',
    visitorsTable: 'utm-visitorsTable',
    myCampaigns: 'utm-myCampaigns',
  },

  visitors: {
    latestVisitorsTable: 'visitors-latestVisitorsTable',
    latestVisitorsMap: 'visitors-latestVisitorsMap',
    byCity: 'visitors-byCity',
    byCountry: 'visitors-byCountry',
    companyVisits: 'visitors-companyVisits',
  },

  behaviour: {
    sessionRecordings: 'behaviour-sessionRecordings',
    heatmaps: 'behaviour-heatmaps',
    funnels: 'behaviour-funnels',
  },

  other: {
    pageDashboard: 'pageDashboard',
  },
};
