import { WIX_MINI_FILTER_EVENT_NAME } from '@va/constants';
import { useTranslate } from '@va/localization';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import { InputMessage, Paragraph } from '@va/ui/design-system';
import { isWixMiniApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SelectDropdownItem } from '../../FilterSelectDropdown';
import { filterValidationKeys } from '../../constants';
import { FilterInputComponentRefType } from '../../filter-types';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { PickOperatorBtn } from '../PickOperatorBtn';
import { useOperator } from '../useOperator';
import { MultiSelectFilter, MultiSelectFilterProps } from './MultiSelectFilter';

export type MultiSelectFilterInputValueType = number | string;

export type MultiSelectFilterInputProps = { filter: MultiSelectFilter } & MultiSelectFilterProps;

export const MultiSelectFilterInput = forwardRef<FilterInputComponentRefType, MultiSelectFilterInputProps>(
  ({ filter, showSearchInput, searchPlaceholder, allOption, useOptions }, ref) => {
    const [filterValue, setFilterValue] = useState('');
    const [selectedValues, setSelectedValues] = useState<MultiSelectFilterInputValueType[]>(filter.values);

    const { data: options = [], isLoading, error: asyncErr } = useOptions();

    const { operator, setOperator } = useOperator(filter.operator);

    const translate = useTranslate();

    const { applySingleNewFilter, isFilterApplied, updateAppliedFilter } = useFiltersContext();

    const onOptionClick = useCallback(
      (value: MultiSelectFilterInputValueType) => {
        if (isWixMiniApp()) {
          const filterEventName = WIX_MINI_FILTER_EVENT_NAME[filter.name];

          if (filterEventName) {
            DashboardAppWorker.trackEvent({
              category: 'Recording Main View',
              action: 'onclick',
              label: `Add/Edit Filter ${filterEventName} ${value}`,
              value: 'Button',
            });
          }
        }

        setSelectedValues((prev) => {
          let previousValues = prev;
          if (allOption) {
            // Unselecting the ALL option
            previousValues = prev.filter((val) => val !== allOption.value);
          }

          if (selectedValues.includes(value)) {
            return previousValues.filter((prevValue) => prevValue !== value);
          } else {
            return [...previousValues, value];
          }
        });
      },
      [allOption, filter.name, selectedValues],
    );

    const onSubmit = useCallback(() => {
      if (isFilterApplied(filter.id)) {
        updateAppliedFilter(filter.id, { values: selectedValues, operator });
        return;
      }

      applySingleNewFilter(new MultiSelectFilter({ ...filter, values: selectedValues, operator }));
    }, [applySingleNewFilter, filter, isFilterApplied, operator, selectedValues, updateAppliedFilter]);

    const { handleSubmit, error } = useFilterValidation({
      value: selectedValues,
      onSubmit,
      validationFunc: (val) => {
        if (val.length === 0) {
          return filterValidationKeys.selectAtLeastOneOption;
        }
      },
    });
    const filteredOptions = useMemo(() => {
      return options.filter((option) => option.label?.toLowerCase().includes(filterValue.toLowerCase()));
    }, [filterValue, options]);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }));

    if (isLoading) {
      return (
        <div className='space-y-2'>
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
        </div>
      );
    }

    return (
      <div className='space-y-2'>
        <PickOperatorBtn
          onOperatorChange={setOperator}
          operatorOptions={filter.operatorOptions}
          selectedOperator={operator}
        />
        {error && <InputMessage error={translate(error)} />}
        {asyncErr && <InputMessage error={translate('notifications.generalError')} />}
        {allOption && (
          <SelectDropdownItem
            selected={selectedValues.includes(allOption.value)}
            icon={allOption.icon}
            label={allOption.label}
            onClick={() => {
              if (isWixMiniApp()) {
                const filterEventName = WIX_MINI_FILTER_EVENT_NAME[filter.name];

                if (filterEventName) {
                  DashboardAppWorker.trackEvent({
                    category: 'Recording Main View',
                    action: 'onclick',
                    label: `Add/Edit Filter ${filterEventName} All`,
                    value: 'Button',
                  });
                }
              }

              setSelectedValues([allOption.value]);
            }}
          />
        )}
        {showSearchInput && options.length !== 0 && (
          <SearchFilterV2
            onChange={(value) => {
              setFilterValue(value);
            }}
            placeholder={searchPlaceholder}
          />
        )}

        {options.length === 0 && !allOption && <Paragraph className='p-5'>{translate('select.noOptions')}</Paragraph>}
        {options.length > 0 && (
          <ul className='rounded-13.5 max-h-64 overflow-auto scrollbar scrollbar-thumb'>
            {filteredOptions.map(({ label, value, icon }) => {
              const isSelected = selectedValues.includes(value);
              return (
                <SelectDropdownItem
                  key={value}
                  onClick={() => {
                    onOptionClick(value);
                  }}
                  selected={isSelected}
                  label={label}
                  icon={icon}
                />
              );
            })}
          </ul>
        )}
      </div>
    );
  },
);
