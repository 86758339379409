import { FilterIcon, MinusIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { FilterOperators } from '@va/types/filters';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import './applied-filter-block.scss';
import { useFiltersContext } from './filters-context';
import { FilterSelectDropdown } from './FilterSelectDropdown';

type FilterBlockProps = {
  filterId: string;
  fieldName: string;
  operator?: FilterOperators;
  input: React.ReactNode;
  operatorOptions?: FilterOperators[];
  hideDeleteButton?: boolean;
  disabled?: boolean;
  isGrouped?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const FilterBlock = ({
  fieldName,
  operator,
  input,
  operatorOptions,
  filterId,
  hideDeleteButton,
  disabled,
  isGrouped,
  size = 'large',
}: FilterBlockProps) => {
  const { deleteAppliedFilter } = useFiltersContext();
  const translate = useTranslate();

  return (
    <div
      className={classNames('applied-filter-block rounded-12 p-3px inline-block overflow-hidden', {
        'bg-white-snow hover:bg-gray-concrete': !disabled,
        'bg-gray-concrete': disabled,
        '!bg-gray-mercury-darker hover:!bg-gray-mercury-darker': isGrouped,
        'h-12': size === 'large',
        'h-42px': size === 'medium',
      })}
    >
      <div className='flex items-center gap-[1.5px] h-full'>
        <div
          className={classNames('field-name-wrapper', {
            '!bg-gray-concrete': disabled,
          })}
        >
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {fieldName}
          </Paragraph>
        </div>
        {operator && (
          <OperatorBlock
            filterId={filterId}
            operatorOptions={operatorOptions ?? []}
            selectedOperator={operator}
            disabled={disabled}
          />
        )}
        {input}
        {!hideDeleteButton && (
          <TooltipWrapper placement='top' content={translate('filters.removeThisFilter')}>
            <button
              className='h-full flex justify-center items-center aspect-square bg-white-snow active:bg-gray-mercury rounded-12 pointer-events-auto relative'
              onClick={() => {
                deleteAppliedFilter(filterId);
              }}
            >
              <MinusIcon color='var(--color-negative)' />
            </button>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
};

const OperatorBlock = ({
  operatorOptions,
  selectedOperator,
  filterId,
  disabled,
}: {
  operatorOptions: FilterOperators[];
  selectedOperator: FilterOperators;
  filterId: string;
  disabled?: boolean;
}) => {
  const translate = useTranslate();
  const { updateAppliedFilter } = useFiltersContext();

  if (operatorOptions.length === 0) {
    return (
      <Paragraph
        className={classNames('operator-wrapper uppercase', {
          '!bg-gray-concrete': disabled,
        })}
        size={paragraphSizes.tiny}
        weight={fontWeights.medium}
      >
        {translate(`filters.operators.${selectedOperator}`)}
      </Paragraph>
    );
  }

  return (
    <FilterSelectDropdown
      triggerClassName={classNames('operator-wrapper', {
        '!bg-gray-concrete': disabled,
      })}
      options={operatorOptions.map((operator) => ({
        label: translate(`filters.operators.${operator}`),
        value: operator,
        icon: <FilterIcon className='w-4 h-4' color='#3C3C3C' />,
      }))}
      showSearchInput={false}
      showArrowIcon={false}
      selectedOptions={{ [selectedOperator]: true }}
      onChange={(option) => {
        updateAppliedFilter(filterId, { operator: option.value as FilterOperators });
      }}
      disabled={disabled}
    >
      <Paragraph className='uppercase' size={paragraphSizes.tiny} weight={fontWeights.medium}>
        {translate(`filters.operators.${selectedOperator}`)}
      </Paragraph>
    </FilterSelectDropdown>
  );
};
