import { defaultWebsiteId, moduleNames, platform, Platform, tabNames, websiteStatus } from '@va/constants';
import { notAllowedSubscriptionViewPlatforms } from '@va/standalone/shared/constants';
import { WithLink, WithSubMenu } from '@va/types/navigation';
import { UserRole } from '@va/types/website';
import { isEcomModuleEnabled } from '@va/util/helpers';
import { Link } from '@va/util/router';
import { isUserLoggedInWithCustomUrl } from './standalone.helpers';

export const getNavigationTabs = (
  activeWebsiteId: string,
  status: string,
  websitePlatform: Platform,
  userRole: UserRole,
  isModuleEnabled: (moduleName: string) => boolean,
) => {
  const dataViewerTabPrefix = `/website/${activeWebsiteId}`;

  const settingsSubTabs: WithLink[] = [
    {
      name: tabNames.generalSettings,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.generalSettings),
    },
    {
      name: tabNames.modulesSettings,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/modules`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.moduleSettings),
    },
    {
      name: tabNames.manageContributors,
      linkTo: ({ className }) => (
        <Link className={className} to={`${dataViewerTabPrefix}/settings/manage-contributors`} />
      ),
      displayInLeftNav: isModuleEnabled(moduleNames.manageContributors),
    },
    {
      name: tabNames.visitorSegments,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/visitor-segments`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.segments),
    },
    {
      name: tabNames.pageSettings,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/pages`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.specialPages),
    },
    {
      name: tabNames.export,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/export`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.export),
    },
  ];
  if (websitePlatform !== platform.WIX) {
    settingsSubTabs.splice(2, 0, {
      name: tabNames.trackingCode,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/tracking-code`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.trackingCode),
    });
  }

  if (!notAllowedSubscriptionViewPlatforms.includes(websitePlatform) && !isUserLoggedInWithCustomUrl()) {
    settingsSubTabs.splice(6, 0, {
      name: tabNames.import,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/import`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.import),
    });
  }
  if (!notAllowedSubscriptionViewPlatforms.includes(websitePlatform) && userRole === UserRole.OWNER) {
    settingsSubTabs.splice(1, 0, {
      name: tabNames.subscriptionSettings,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/subscription`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.subscription),
    });
  }
  const feedbackTab: WithSubMenu = {
    index: 4,
    name: tabNames.feedback,
    hasSubGroups: true,
    displayInLeftNav: isModuleEnabled(moduleNames.feedback),
    submenu: [
      {
        name: tabNames.polls,
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/feedback/polls`} />,
        displayInLeftNav: isModuleEnabled(moduleNames.polls),
      },
      {
        name: tabNames.surveys,
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/feedback/surveys`} />,
        displayInLeftNav: isModuleEnabled(moduleNames.surveys),
      },
    ],
  };
  const settingsTab: WithSubMenu = {
    index: 6,
    name: tabNames.websiteSettings,
    submenu: settingsSubTabs,
    displayInLeftNav: isModuleEnabled(moduleNames.websiteSettings),
  };
  const milestoneTab: WithLink = {
    index: 8,
    linkTo: ({ className, translate }) => (
      <a className={className} href={translate('externalLink.tab.milestone')} target='_blank' rel='noreferrer'>
        {''}
      </a>
    ),
    name: tabNames.milestone,
    displayInLeftNav: isModuleEnabled(moduleNames.roadmap),
    openInNewTab: true,
  };
  const trafficStructureTab: WithLink = {
    index: 2.2,
    name: tabNames.trafficStructure,
    linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/traffic/share`} />,
    displayInLeftNav: isModuleEnabled(moduleNames.trafficStructure),
    children: [
      {
        name: tabNames.trafficShare,
        linkTo: `${dataViewerTabPrefix}/traffic/share`,
        displayInLeftNav: isModuleEnabled(moduleNames.trafficChannels),
      },
      {
        name: tabNames.trafficSession,
        linkTo: `${dataViewerTabPrefix}/traffic/session`,
        displayInLeftNav: isModuleEnabled(moduleNames.trafficSessions),
      },
      {
        name: tabNames.trafficPagesPerVisit,
        linkTo: `${dataViewerTabPrefix}/traffic/visits`,
        displayInLeftNav: isModuleEnabled(moduleNames.trafficPages),
      },
      {
        name: tabNames.trafficBounceRate,
        linkTo: `${dataViewerTabPrefix}/traffic/bounceRate`,
        displayInLeftNav: isModuleEnabled(moduleNames.trafficBounceRate),
      },
    ],
  };

  const outgoingTrafficTab: WithLink = {
    index: 2.3,
    name: tabNames.outgoingTraffic,
    linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/outgoing-traffic`} />,
    displayInLeftNav: isModuleEnabled(moduleNames.outgoingTraffic),
  };

  const ecommerceTabs: WithSubMenu = {
    name: tabNames.ecommerce,
    displayInLeftNav: isEcomModuleEnabled(userRole),
    submenu: [
      {
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/ecommerce/overview`} />,
        name: tabNames.ecommerceOverview,
        displayInLeftNav: true,
        isBeta: true,
      },
      {
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/ecommerce/customers`} />,
        name: tabNames.ecommerceCustomers,
        displayInLeftNav: true,
        isBeta: true,
      },
      {
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/ecommerce/orders`} />,
        name: tabNames.ecommerceOrders,
        displayInLeftNav: false,
        isBeta: true,
      },
      {
        linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/ecommerce/sales-charts`} />,
        name: tabNames.ecommerceSalesCharts,
        displayInLeftNav: true,
        isBeta: true,
      },
    ],
  };

  const tabs: Array<WithLink | WithSubMenu> = [
    {
      index: 1,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/`} />,
      name: tabNames.overview,
      displayInLeftNav: true, //isModuleEnabled(moduleNames.mainDashboards),
      isNew: true,
    },
    ecommerceTabs,
    {
      index: 2,
      name: tabNames.webStatsOverview,
      hasSubGroups: true,
      displayInLeftNav: isModuleEnabled(moduleNames.webStatsOverview),
      submenu: [
        {
          index: 2.1,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/statistics/overview`} />,
          name: tabNames.latestVisitorsOverview,
          displayInLeftNav: isModuleEnabled(moduleNames.webStatsOverview),
        },
        trafficStructureTab,
        outgoingTrafficTab,
        {
          index: 2.4,
          name: tabNames.latestVisitorsList,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/visitors/latest`} />,
          displayInLeftNav: isModuleEnabled(moduleNames.visitors),
          isNew: true,
          children: [
            {
              name: tabNames.latestVisitorsList,
              linkTo: `${dataViewerTabPrefix}/visitors/latest`,
              displayInLeftNav: false,
              isNew: true,
            },
            {
              name: tabNames.latestVisitorsMap,
              linkTo: `${dataViewerTabPrefix}/visitors/map`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.trafficCharts,
              linkTo: `${dataViewerTabPrefix}/visitors/charts`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.latestVisitorsLocations,
              linkTo: `${dataViewerTabPrefix}/visitors/locations`,
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.5,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/pages/landing`} />,
          name: tabNames.pageVisits,
          displayInLeftNav: isModuleEnabled(moduleNames.pages),
          children: [
            {
              name: tabNames.landingPages,
              linkTo: `${dataViewerTabPrefix}/pages/landing`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.conversions,
              linkTo: `${dataViewerTabPrefix}/pages/conversions`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.referringSites,
              linkTo: `${dataViewerTabPrefix}/pages/referring`,
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.6,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/competition`} />,
          name: tabNames.competition,
          displayInLeftNav: isModuleEnabled(moduleNames.competition),
        },
        {
          index: 2.7,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/devices`} />,
          name: tabNames.devices,
          displayInLeftNav: isModuleEnabled(moduleNames.devices),
        },
        {
          index: 2.8,
          name: tabNames.campaignsOverview,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/campaigns/overview`} />,
          displayInLeftNav: isModuleEnabled(moduleNames.utmOverview),
          children: [
            {
              name: tabNames.adsOverview,
              linkTo: `${dataViewerTabPrefix}/campaigns/overview`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.adsLatestVisitors,
              linkTo: `${dataViewerTabPrefix}/campaigns/latest`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.myCampaigns,
              linkTo: `${dataViewerTabPrefix}/campaigns/my`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.adsCharts,
              linkTo: `${dataViewerTabPrefix}/campaigns/charts`,
              displayInLeftNav: false,
            },
            {
              name: tabNames.utmGenerator,
              linkTo: `${dataViewerTabPrefix}/campaigns/utm-generator`,
              displayInLeftNav: false,
            },
          ],
        },
        {
          index: 2.8,
          name: tabNames.websiteErrors,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/website-errors`} />,
          // TODO Fix properly using the getFeaturePermissions
          displayInLeftNav: false, //isModuleEnabled(moduleNames.websiteErrors),
        },
      ],
    },
    {
      index: 3,
      name: tabNames.behaviour,
      displayInLeftNav: isModuleEnabled(moduleNames.visitorBehavior),
      submenu: [
        {
          name: tabNames.eventTracking,
          linkTo: ({ className }) => (
            <Link className={className} to={`${dataViewerTabPrefix}/behaviour/event-tracking`} />
          ),
          displayInLeftNav: isModuleEnabled(moduleNames.eventTracking),
          isNew: true,
        },
        {
          name: tabNames.recordings,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/behaviour/recordings`} />,
          displayInLeftNav: isModuleEnabled(moduleNames.sessionRecordings),
        },
        {
          name: tabNames.heatmaps,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/behaviour/heatmaps`} />,
          displayInLeftNav: isModuleEnabled(moduleNames.heatmaps),
        },
        {
          name: tabNames.funnels,
          linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/behaviour/funnels`} />,
          displayInLeftNav: isModuleEnabled(moduleNames.funnels),
        },
      ],
    },
    feedbackTab,
    {
      index: 5,
      name: tabNames.privacyCenter,
      linkTo: ({ className }) => <Link className={className} to={`${dataViewerTabPrefix}/settings/privacy-center`} />,
      displayInLeftNav: isModuleEnabled(moduleNames.privacyCenter),
    },
  ];

  if (activeWebsiteId !== defaultWebsiteId && status !== websiteStatus.PENDING) {
    //insert settings tab as last but one element
    tabs.push(settingsTab);
  }
  if (!isUserLoggedInWithCustomUrl() || (isUserLoggedInWithCustomUrl() && userRole === UserRole.OWNER)) {
    tabs.push(milestoneTab);
  }

  // tabs.splice(3, 0, feedbackTab);

  return tabs;
};
