import { selectAllowUpgrade, selectIntpWebsite, selectStpRates } from '@va/aaas/shared/selectors';
import { StpCounterResponse } from '@va/aaas/shared/types';
import { tabNames } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { Counter } from '@va/dashboard/shared/feature-counter';
import { Checkmark, CoinsIcon, LightningIcon, NavAddCircle } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { getNavIconByName } from '@va/standalone/shared/helpers';
import { getUserRole } from '@va/standalone/shared/selectors';
import { StarV2 } from '@va/svg-visa-icons';
import {
  Button,
  ChoiceSeparator,
  CircularProgress,
  Paragraph,
  TabbedButton,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator, isEcomModuleEnabled } from '@va/util/helpers';
import classNames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

enum TabNames {
  'stpConsumed' = 0,
  'stpRate' = 1,
}

export const StpCounter = () => {
  const stpRates = useSelector(selectStpRates);

  const translate = useTranslate();
  return (
    <TooltipWrapper
      placement='bottom'
      useDefaultStyle={false}
      interactive
      disabled={!stpRates}
      content={<StpCounterContent counterData={stpRates} />}
      tooltipClassNames='sm:w-full w-560px max-w-560px sm:px-3'
      trigger='click'
    >
      <div>
        <Counter
          consumed={stpRates?.stpConsumed ?? 0}
          total={stpRates?.stpLimit ?? 0}
          label={translate('pageHeader.creditsCounter.title')}
        />
      </div>
    </TooltipWrapper>
  );
};

const StpCounterContent = ({ counterData }: { counterData: StpCounterResponse }) => {
  const { locale } = useLocale();
  const translate = useTranslate();
  const userRole = useSelector(getUserRole);
  const intpWebsite = useSelector(selectIntpWebsite);
  const planName = intpWebsite.packageName;
  const isFreePlan = intpWebsite.inTrial;
  const expiresAt = moment(intpWebsite.expiresAt).locale(locale).format('LL');

  const allowUpgrade = useSelector(selectAllowUpgrade);
  const dispatch = useDispatch();

  const {
    //tp consumed
    stpLimit,
    stpConsumed,
    stpConsumedVisit,
    stpConsumedVisitEvent,
    stpConsumedSessionRecording,
    stpConsumedHeatmapIncrement,
    stpConsumedPollAnswer,
    stpConsumedSurveyAnswer,
    stpConsumedFunnelMatch,
    stpConsumedEcomEvent,

    //tp rate
    stpRateHeatmapIncrement,
    stpRateSessionRecording,
    stpRateSurveyAnswer,
    stpRatePollAnswer,
    stpRateVisit,
    stpRateVisitEvent,
    stpRateFunnelMatch,
    stpRateEcomEvent,
  } = counterData;

  const [selectedTab, setSelectedTab] = useState(TabNames.stpConsumed);

  const tabButtons = useMemo(
    () => [
      { value: TabNames.stpConsumed, label: translate('label.credits') },
      { value: TabNames.stpRate, label: translate('label.howItWorks') },
    ],
    [translate],
  );

  const isStpRateTab = useMemo(() => selectedTab === TabNames.stpRate, [selectedTab]);
  const planExpiryKey = useMemo(() => {
    if (isFreePlan) {
      return translate('featureCounter.freeTrial.expiresAt', { date: expiresAt });
    }
    return translate(`featureCounter.plan.expiresAt`, { date: expiresAt });
  }, [expiresAt, isFreePlan, translate]);

  const rowIcon = useMemo(() => {
    if (isStpRateTab) return <CoinsIcon color='#fff' />;
    return undefined;
  }, [isStpRateTab]);

  const rowLabelBaseKey = useMemo(() => {
    const subKey = isStpRateTab ? '.rate' : '';
    return `pageHeader.stpCounter.tp${subKey}`;
  }, [isStpRateTab]);

  return (
    <div className='bg-black rounded-24 p-3 text-white'>
      <div className='flex flex-row gap-2 py-3 rounded-15 -mb-4'>
        <div className='flex items-center justify-center rounded-12 bg-black-dark/50 p-15px'>
          <LightningIcon className='shrink-0' color='#FFFF' />
        </div>
        <div className='flex flex-col'>
          <div className='flex flex-row xs:flex-col'>
            <Paragraph size={paragraphSizes.large} weight={fontWeights.semibold}>
              {translate('featureCounter.currentPlan')}
            </Paragraph>
            <Paragraph className='ml-2' size={paragraphSizes.large} weight={fontWeights.semibold}>
              <span>{planName}</span>
            </Paragraph>
          </div>
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {planExpiryKey}
          </Paragraph>
        </div>
      </div>
      {allowUpgrade && (
        <Button
          icon={(_, color) => <NavAddCircle color={color} />}
          text={translate('button.upgradePlan')}
          className='!bg-white !hover:bg-white-light justify-center font-medium w-full mt-3'
          color='tertiary'
          onClick={() => {
            dispatch(startUpgradeProcess());
          }}
        />
      )}
      <div>
        <TabbedButton
          buttonOptions={tabButtons}
          selectedValue={selectedTab}
          onChange={setSelectedTab}
          className='!bg-black-dark/50 my-3'
          textClassName='text-white underline underline-offset-4'
          activeButtonClassName='!bg-black'
          inactiveButtonClassName='hover:bg-transparent active:bg-transparent'
        />
      </div>
      <div className='flex flex-row items-center gap-[9px] px-2'>
        <CoinsIcon color='#fff' />
        <div className='flex flex-col'>
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate('pageHeader.creditsCounter.creditsLeft')}
          </Paragraph>
          <Paragraph>
            {translate('feature.counter.counting', {
              behaviourCount: addNumberSeparator(stpLimit - stpConsumed, locale),
              featureLimit: addNumberSeparator(stpLimit, locale),
            })}
          </Paragraph>
        </div>
        <div className='ml-auto'>
          <CircularProgress radius={15} stroke={4} progress={(stpConsumed / stpLimit) * 100} gradientColor='#F66F1E' />
        </div>
      </div>
      <ChoiceSeparator
        label={
          isStpRateTab
            ? translate('pageHeader.creditsCounter.howItWorks')
            : translate('pageHeader.creditsCounter.creditsConsumed')
        }
      />
      <div className='flex flex-col gap-3 px-2'>
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.visits`)}
          count={isStpRateTab ? stpRateVisit : stpConsumedVisit}
          icon={getNavIconByName(tabNames.pageVisits, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.recordings`)}
          count={isStpRateTab ? stpRateSessionRecording : stpConsumedSessionRecording}
          icon={getNavIconByName(tabNames.recordings, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.heatmaps`)}
          count={isStpRateTab ? stpRateHeatmapIncrement : stpConsumedHeatmapIncrement}
          icon={getNavIconByName(tabNames.heatmaps, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.funnels`)}
          count={isStpRateTab ? stpRateFunnelMatch : stpConsumedFunnelMatch}
          icon={getNavIconByName(tabNames.funnels, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.polls`)}
          count={isStpRateTab ? stpRatePollAnswer : stpConsumedPollAnswer}
          icon={getNavIconByName(tabNames.polls, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.surveys`)}
          count={isStpRateTab ? stpRateSurveyAnswer : stpConsumedSurveyAnswer}
          icon={getNavIconByName(tabNames.surveys, false, '#fff')}
          rightIcon={rowIcon}
        />
        <ConsumedTouchpointRow
          label={translate(`${rowLabelBaseKey}.events`)}
          count={isStpRateTab ? stpRateVisitEvent : stpConsumedVisitEvent}
          icon={getNavIconByName(tabNames.eventTracking, false, '#fff')}
          rightIcon={rowIcon}
        />
        {isEcomModuleEnabled(userRole) && (
          <ConsumedTouchpointRow
            label={translate(`${rowLabelBaseKey}.ecom`)}
            labelTooltip={translate(`${rowLabelBaseKey}.ecomTooltip`)}
            count={isStpRateTab ? stpRateEcomEvent : stpConsumedEcomEvent}
            icon={getNavIconByName(tabNames.ecommerce, false, '#fff')}
            rightIcon={rowIcon}
          />
        )}
      </div>
      <div className='mt-18px flex items-center justify-between min-h-[60px] bg-black-dark/50 rounded-[15px]'>
        <div className='flex items-center ml-[15px]'>
          <StarV2 color='#FFFFF' stroke='#FFFFFF' />
          <Paragraph className='ml-[9px] opacity-90' weight={fontWeights.medium}>
            {translate('featureCounter.premiumSupport')}
          </Paragraph>
        </div>
        <div className='ml-3 mr-[18px]'>
          <Checkmark color='#FFFFFF' />
        </div>
      </div>
    </div>
  );
};

type ConsumedTouchpointRowProps = {
  label: string;
  icon?: JSX.Element;
  count: number;
  rightIcon?: JSX.Element;
  labelTooltip?: string;
};

const ConsumedTouchpointRow = ({ label, icon, count = 0, rightIcon, labelTooltip }: ConsumedTouchpointRowProps) => {
  const { locale } = useLocale();
  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-row items-center gap-[9px]'>
        {icon && <div className='max-w-14'>{icon}</div>}
        <TooltipWrapper content={labelTooltip} disabled={!labelTooltip} arrowColor={'#000'}>
          <Paragraph
            className={classNames('opacity-90', { 'underline-dashed': !!labelTooltip })}
            weight={fontWeights.medium}
          >
            {label}
          </Paragraph>
        </TooltipWrapper>
      </div>
      <div className='flex items-center gap-3'>
        <Paragraph weight={fontWeights.medium}>{addNumberSeparator(count, locale)}</Paragraph>
        {rightIcon}
      </div>
    </div>
  );
};
