import { TEST_IDS } from '@va/constants';
import { VerticalThreeDotIcon } from '@va/icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { useState } from 'react';
import { ContextMenu, ContextMenuProps } from '../context-menu';

export function ContextMenuWrapper({ contextMenu }: { contextMenu: ContextMenuProps }) {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      placement='bottom-start'
      arrow={false}
      interactive
      open={isContextMenuVisible}
      onOpenChange={(open) => setIsContextMenuVisible(open)}
      trigger='click'
      zIndex={1100}
      tooltipClassNames='shadow-overlay rounded-24'
      content={
        <ContextMenu
          {...contextMenu}
          onClose={() => {
            contextMenu.onClose && contextMenu.onClose();
            setIsContextMenuVisible(false);
          }}
        />
      }
    >
      <div
        data-testid={TEST_IDS.generic.nestedNavContextMenu}
        className={classNames('flex items-center justify-center hover:bg-white-snow rounded-md h-6 w-6 cursor-pointer')}
      >
        <VerticalThreeDotIcon color='#3C3C3C' />
      </div>
    </TooltipWrapper>
  );
}
