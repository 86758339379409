import { EVENTS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { DropdownArrow } from '@va/util/components';
import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import './Sidebar.scss';
import SidebarPopup from './SidebarPopup';
import { getIconByName, getParent } from './helper';
import NewMarker from './new-marker/NewMarker';
import { SidebarItemProps } from './propDefinitions';

type SidebarListComponentProps = {
  item: SidebarItemProps;
  sidebarItems: SidebarItemProps[];
  isExpanded?: boolean;
  handleClick: (item: SidebarItemProps) => void;
  selectedItem?: SidebarItemProps;
  previewMode?: boolean;
};
const SidebarListComponent = (props: SidebarListComponentProps) => {
  const { item, sidebarItems, isExpanded, handleClick, selectedItem, previewMode } = props;
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const translate = useTranslate();

  const removeCollapsedItem = (arr: string[], id: string) => {
    const newArr = [...arr];
    const index = newArr.indexOf(id);
    if (index > -1) {
      newArr.splice(index, 1);
    }
    return newArr;
  };

  const handleSelect = (item: SidebarItemProps) => {
    if (item.submenu && item.submenu.length > 0) {
      setCollapsedItems(
        collapsedItems?.includes(item.name)
          ? removeCollapsedItem(collapsedItems, item.name)
          : [...collapsedItems, item.name],
      );
    } else {
      handleClick(item);
    }
  };

  const propertyExistInChild = (property: 'isNew' | 'isBeta', sidebarItems: SidebarItemProps[]) => {
    const items = sidebarItems.filter((child) => !!child && child[property]);
    return items.length > 0;
  };

  const defaultLinkToValue = ({ className }: { className: string }) => <div className={className}></div>;

  const renderItem = (item: SidebarItemProps) => {
    item = { ...item, linkTo: !item.linkTo ? defaultLinkToValue : item.linkTo };
    const { linkTo: LinkTo } = item;

    if (!item.displayInLeftNav) return <></>;

    const parentInfo = getParent(sidebarItems, item.name);

    return (
      <div data-tour={item.name} key={item.name}>
        <div
          key={item.name}
          onClick={handleSelect.bind(this, item)}
          className={classNames(
            'flex sidebar-list-wrapper items-center relative p-2 text-gray-500 cursor-pointer group hover:box-border',
            { 'pr-4': !parentInfo?.name && isExpanded },
            { 'justify-center	w-[60px]': !isExpanded && !parentInfo?.name },
            { 'box-border': selectedItem?.name === item.name },
            {
              'selected-nav-item':
                selectedItem?.name === item.name || getParent(sidebarItems, selectedItem?.name)?.name === item.name,
            },
            { 'sidebar-list-parent w-full': !parentInfo?.name },
          )}
        >
          {getIconByName(
            item.name,
            (!getParent(sidebarItems, selectedItem?.name)?.name && selectedItem?.name === item.name) ||
              getParent(sidebarItems, selectedItem?.name)?.name === item.name,
            previewMode,
          )}
          <div
            className={classNames('flex items-center min-w-0 grow', {
              hidden: !isExpanded && !parentInfo?.name,
            })}
          >
            <span
              className={classNames(
                'ml-2 mr-auto text-sm text-primary truncate w-full',
                { 'font-semibold': !parentInfo },
                {
                  '!text-gray-dusty': previewMode,
                },
              )}
            >
              {translate(`tab.${item.name}`)}
            </span>
            <div className={classNames('', { hidden: previewMode })}>
              <NewMarker
                isNew={propertyExistInChild('isNew', item.submenu || []) || item.isNew || false}
                isBeta={propertyExistInChild('isBeta', item.submenu || []) || item.isBeta || false}
                showLabel={parentInfo === null}
              />
            </div>
            {'submenu' in item && (
              <DropdownArrow open={collapsedItems.includes(item.name)} color={previewMode ? '#999' : undefined} />
            )}
          </div>
          <div
            className={classNames('h-full absolute w-full left-0  opacity-10 rounded-[inherit]', {
              link_to: !parentInfo && !isExpanded,
              'group-hover:bg-primary': parentInfo || isExpanded,
            })}
          />
          {LinkTo && <LinkTo className={classNames('h-full absolute w-full left-0 z-10')} translate={translate} />}
        </div>
      </div>
    );
  };
  const renderItemChildren = (item: SidebarItemProps) => {
    if (!item.submenu) {
      return false;
    }
    return (
      <>
        {item.submenu.map((child: SidebarItemProps) => {
          return <Fragment key={child.name}>{renderItem(child)}</Fragment>;
        })}
      </>
    );
  };

  useEffect(() => {
    const listener = EventBus.addListener(EVENTS.collapseSidebarItems, () => {
      setCollapsedItems([]);
    });
    return () => {
      listener.removeListener();
    };
  }, []);

  const betaLabel = useMemo(() => {
    const isBeta = propertyExistInChild('isBeta', item.submenu || []) || item.isBeta || false;
    if (isBeta) return ` (${translate('leftNav.beta')})`;
    return '';
  }, [item.isBeta, item.submenu, translate]);

  return (
    <>
      <SidebarPopup
        showPopup={isExpanded}
        title={`${translate(`tab.${item.name}`)}${betaLabel}`}
        content={renderItemChildren.bind(this, item)}
        onHeaderClick={() => handleSelect(item)}
        linkTo={item.linkTo ?? item.submenu?.[0]?.linkTo}
      >
        {renderItem(item)}
      </SidebarPopup>
      <div>
        {isExpanded &&
          collapsedItems?.includes(item.name) &&
          item.submenu &&
          item.submenu.map((child: SidebarItemProps) => {
            return (
              <div className={'pl-4'} key={child.name}>
                {renderItem(child)}
              </div>
            );
          })}
      </div>
    </>
  );
};
export default SidebarListComponent;
