import { TEST_IDS } from '@va/constants';
import { ListIcon, RadioButton } from '@va/icons';
import { useTranslate } from '@va/localization';
import { DropdownArrow } from '@va/util/components';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Button } from '../button';
import { ToggleSwitch } from '../toggle-switch';
import { Paragraph, fontWeights } from '../typography';

export type GroupingOption = {
  label: string;
  value: string;
};

type HeaderCellFiltersDropdownProps = {
  clearSortBy: () => void;
  toggleSortBy: (descending: boolean) => void;
  isSortedDesc: boolean | undefined;
  closeTooltip: () => void;
  groupingOptions?: GroupingOption[];
  groupBy: string[];
  setGroupBy: (groupBy: string[]) => void;
  isGroupedByColumn: boolean;
  toggleGroupByColumn: () => void;
  canGroupBy: boolean;
  canSort: boolean;
};

export const HeaderCellFiltersDropdown: React.FC<HeaderCellFiltersDropdownProps> = ({
  clearSortBy,
  toggleSortBy,
  isSortedDesc,
  closeTooltip,
  groupingOptions,
  groupBy,
  setGroupBy,
  isGroupedByColumn,
  toggleGroupByColumn,
  canGroupBy,
  canSort,
}) => {
  const getInitialSelectedGroupByOption = useCallback(() => {
    const groupingValues = groupingOptions?.map((item) => item.value);
    return groupBy.find((item) => groupingValues?.includes(item));
  }, [groupBy, groupingOptions]);

  const initialSelectedGroupByOption = useMemo(() => {
    return getInitialSelectedGroupByOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [localIsSortedDesc, setLocalIsSortedDesc] = useState<undefined | boolean>(isSortedDesc);
  const [selectedGroupByOption, setSelectedGroupByOption] = useState<string | undefined>(initialSelectedGroupByOption);
  const [localIsGroupedByColumn, setLocalIsGroupedByColumn] = useState(isGroupedByColumn);

  const translate = useTranslate();

  const sortingHasChanged = useMemo(() => !isEqual(localIsSortedDesc, isSortedDesc), [isSortedDesc, localIsSortedDesc]);
  const selectedGroupingOptionHasChanged = useMemo(
    () => !isEqual(initialSelectedGroupByOption, selectedGroupByOption),
    [initialSelectedGroupByOption, selectedGroupByOption],
  );
  const groupingByColumnHasChanged = useMemo(
    () => !isEqual(localIsGroupedByColumn, isGroupedByColumn),
    [isGroupedByColumn, localIsGroupedByColumn],
  );

  const hasMadeChanges = useMemo(
    () => sortingHasChanged || selectedGroupingOptionHasChanged || groupingByColumnHasChanged,
    [groupingByColumnHasChanged, selectedGroupingOptionHasChanged, sortingHasChanged],
  );

  const removeSorting = useCallback(() => {
    setLocalIsSortedDesc(undefined);
  }, []);

  const removeSelectedGroupByOption = useCallback(() => {
    setSelectedGroupByOption(undefined);
  }, []);

  // For default column grouping, no multiple grouping options
  const applyGroupByColumnFilter = useCallback(() => {
    if (!groupingByColumnHasChanged) return;
    toggleGroupByColumn();
  }, [groupingByColumnHasChanged, toggleGroupByColumn]);

  // Only used when multiple grouping options are provided
  const applySelectedGroupByOption = useCallback(() => {
    const updatedGroupBy = [...groupBy];

    if (initialSelectedGroupByOption) {
      const index = updatedGroupBy.indexOf(initialSelectedGroupByOption);

      if (selectedGroupByOption) {
        updatedGroupBy[index] = selectedGroupByOption;
      } else {
        updatedGroupBy.splice(index, 1);
      }
    }

    if (!initialSelectedGroupByOption && selectedGroupByOption) {
      updatedGroupBy.push(selectedGroupByOption);
    }

    setGroupBy(updatedGroupBy);
  }, [groupBy, initialSelectedGroupByOption, selectedGroupByOption, setGroupBy]);

  const applyGroupByFilter = useCallback(() => {
    if (!groupingOptions) {
      applyGroupByColumnFilter();
      return;
    }

    applySelectedGroupByOption();
  }, [applyGroupByColumnFilter, applySelectedGroupByOption, groupingOptions]);

  const applySortingFilter = useCallback(() => {
    if (localIsSortedDesc === undefined) {
      clearSortBy();
    } else {
      toggleSortBy(localIsSortedDesc);
    }
  }, [clearSortBy, localIsSortedDesc, toggleSortBy]);

  const applyFilters = useCallback(() => {
    if (sortingHasChanged) {
      applySortingFilter();
    }

    if (groupingByColumnHasChanged || selectedGroupingOptionHasChanged) {
      applyGroupByFilter();
    }

    closeTooltip();
  }, [
    applyGroupByFilter,
    applySortingFilter,
    closeTooltip,
    groupingByColumnHasChanged,
    selectedGroupingOptionHasChanged,
    sortingHasChanged,
  ]);

  return (
    <div
      className='p-3 rounded-24 bg-white min-w-300 text-gray-charcoal'
      data-testid={TEST_IDS.generic.table.headerOptionsTooltip}
    >
      <ul>
        {groupingOptions &&
          groupingOptions.map((option) => {
            const isSelected = selectedGroupByOption === option.value;

            return (
              <Option
                onClick={() => {
                  if (isSelected) {
                    removeSelectedGroupByOption();
                  } else {
                    setSelectedGroupByOption(option.value);
                  }
                }}
                isSelected={isSelected}
                label={option.label}
                icon={(color) => <ListIcon color={color} />}
                data-testid={TEST_IDS.generic.table.groupColumnOption}
              />
            );
          })}

        {canGroupBy && !groupingOptions && (
          <li className='flex items-center gap-9px p-3 rounded-12'>
            <ListIcon color='#3C3C3C' />
            <Paragraph weight={fontWeights.medium}>{translate('table.groupBy')}</Paragraph>
            <div className='ml-auto'>
              <ToggleSwitch
                checked={localIsGroupedByColumn}
                onChange={(value) => {
                  setLocalIsGroupedByColumn(value);
                }}
                data-testid={TEST_IDS.generic.table.groupColumnSwitch}
              />
            </div>
          </li>
        )}
        {canSort && (
          <>
            <Option
              isSelected={localIsSortedDesc === false}
              onClick={() => {
                if (localIsSortedDesc === false) {
                  removeSorting();
                  return;
                }

                setLocalIsSortedDesc(false);
              }}
              label={translate('table.sortAscending')}
              icon={(color) => <DropdownArrow color={color} open />}
              data-testid={TEST_IDS.generic.table.sortColumnAscBtn}
            />
            <Option
              isSelected={localIsSortedDesc === true}
              onClick={() => {
                if (localIsSortedDesc === true) {
                  removeSorting();
                  return;
                }

                setLocalIsSortedDesc(true);
              }}
              label={translate('table.sortDescending')}
              icon={(color) => <DropdownArrow color={color} />}
              data-testid={TEST_IDS.generic.table.sortColumnDescBtn}
            />
          </>
        )}
      </ul>
      <div className='flex gap-3 mt-3'>
        <Button
          onClick={closeTooltip}
          color='primary'
          text={translate('button.cancel')}
          className='flex-1 min-w-min'
          data-testid={TEST_IDS.generic.buttons.close}
        />
        <Button
          disabled={!hasMadeChanges}
          onClick={applyFilters}
          color='tertiary'
          text={translate('button.apply')}
          className='flex-2 min-w-min'
          data-testid={TEST_IDS.generic.buttons.apply}
        />
      </div>
    </div>
  );
};

const Option = ({
  onClick,
  isSelected,
  label,
  icon,
  'data-testid': dataTestId,
}: {
  onClick: () => void;
  isSelected: boolean;
  label: string;
  icon: (color: string) => React.ReactNode;
  'data-testid'?: string;
}) => {
  return (
    <li
      role='button'
      onClick={onClick}
      className='flex items-center gap-9px p-3 rounded-12 hover:bg-white-snow'
      data-testid={dataTestId}
    >
      {icon('#3C3C3C')}
      <Paragraph weight={fontWeights.medium}>{label}</Paragraph>
      <div className='ml-auto'>
        <RadioButton isSelected={isSelected} />
      </div>
    </li>
  );
};
