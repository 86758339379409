import { IconProps } from '.';

export const CircleLinkIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM6 6C4.34315 6 3 7.34315 3 9C3 10.6569 4.34315 12 6 12H8.25V10.5H6C5.17157 10.5 4.5 9.82843 4.5 9C4.5 8.17157 5.17157 7.5 6 7.5H8.25V6H6ZM15 9C15 7.34315 13.6569 6 12 6H9.75V7.5H12C12.8284 7.5 13.5 8.17157 13.5 9C13.5 9.82843 12.8284 10.5 12 10.5H9.75V12H12C13.6569 12 15 10.6569 15 9ZM11.75 8.25H5.75V9.75H11.75V8.25Z'
        fill={color}
      />
    </svg>
  );
};
