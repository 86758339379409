import { SelectorButton } from '@va/ui/components/buttons';
import { ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { useState } from 'react';
import { HowDidYouFindUsOption } from './HowDidYouFindUsOption';

export type DropdownOptionType = {
  label: string;
  value: string | number;
};

export const HowDidYouFindUsDropdown = ({
  selectedOption,
  options,
  label,
  placeholder,
  onChange,
}: {
  selectedOption: DropdownOptionType | undefined;
  options: DropdownOptionType[];
  placeholder: string;
  label: string;
  onChange: (option: DropdownOptionType) => void;
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => setIsDropdownVisible((prev) => !prev);

  const handleOptionSelect = (option: DropdownOptionType) => {
    onChange(option);
    setIsDropdownVisible(false);
  };

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      trigger='click'
      tooltipClassNames='w-350px md:w-420px'
      open={isDropdownVisible}
      onOpenChange={toggleDropdown}
      interactive
      arrow={false}
      placement='bottom'
      content={
        <div className={classNames('text-gray-charcoal p-2 bg-white border-2 border-gray-concrete')}>
          <ul className='flex flex-col gap-2 h-180px overflow-y-auto scrollbar scrollbar-thumb'>
            {options.map((option) => (
              <HowDidYouFindUsOption
                key={option.value}
                option={option}
                isSelected={option.value === selectedOption?.value}
                handleOptionSelect={handleOptionSelect}
              />
            ))}
          </ul>
        </div>
      }
    >
      <div className='w-full md:w-420px'>
        <SelectorButton
          topNode={
            <ParagraphWithTooltip
              weight={fontWeights.medium}
              colorClassName='text-gray-charcoal'
              className='truncate w-full'
            >
              {label}
            </ParagraphWithTooltip>
          }
          bottomNode={
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              colorClassName='text-gray-charcoal'
              className='truncate w-full'
            >
              {selectedOption ? selectedOption.label : placeholder}
            </ParagraphWithTooltip>
          }
          rightElement={<DropdownArrow open={isDropdownVisible} />}
          className='bg-white-snow'
        />
      </div>
    </TooltipWrapper>
  );
};
