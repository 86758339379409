import { TEST_IDS } from '@va/constants';
import { ClockIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getEventIcon } from '@va/shared/ssr-player';
import { MappedEventType } from '@va/types/recordings';
import {
  Button,
  fontWeights,
  IconWithBackground,
  InfoSectionItem,
  Paragraph,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { formatMomentDuration, formatTime } from '@va/util/helpers';
import classNames from 'classnames';
import moment from 'moment';
import React, { memo, useMemo } from 'react';

type RecordingEventListItemProps = {
  event: MappedEventType;
  onClick: (event: MappedEventType) => void;
  isPageHistoryTrackingEnabled: boolean;
  dataTestId: string;
  variant?: 'default' | 'wix-mini';
  selected: boolean;
};

type EventListItemStyles = {
  className?: string;
  iconClassName?: string;
  buttonClassName?: string;
  squareIcons?: boolean;
  size?: 'small' | 'large';
  iconType?: 'circle' | 'square';
};

const styles: Record<NonNullable<RecordingEventListItemProps['variant']>, EventListItemStyles> = {
  default: {
    size: 'large',
    iconClassName: 'bg-white',
  },
  'wix-mini': {
    size: 'small',
    className: '!bg-white !p-1.5',
    iconClassName: 'bg-white-catskill',
    buttonClassName: 'bg-white-catskill',
    iconType: 'square',
  },
};

export const RecordingEventListItem: React.FC<RecordingEventListItemProps> = memo(
  ({ event, onClick, isPageHistoryTrackingEnabled, dataTestId, variant = 'default', selected }) => {
    const translate = useTranslate();
    const fromTimestamp = formatTime(formatMomentDuration(moment.duration(event.delay)));
    const toTimestamp = event.to && formatTime(formatMomentDuration(moment.duration(event.to)));

    const { icon: eventIcon, backgroundColor: iconBgColor } = useMemo(
      () => getEventIcon({ eventDetails: event.name }),
      [event.name],
    );

    return (
      <InfoSectionItem
        dataTestId={dataTestId}
        size={styles[variant].size}
        textColReverse={true}
        className={classNames(styles[variant].className, {
          '!bg-gray-concrete': selected,
        })}
        rightNode={
          <div className='flex items-center gap-2'>
            <Paragraph className='whitespace-nowrap' size={paragraphSizes.normal} weight={fontWeights.medium}>
              {toTimestamp ? fromTimestamp + ' - ' + toTimestamp : fromTimestamp}
            </Paragraph>
            <Button
              data-testid={TEST_IDS.generic.buttons.jumpToEvent}
              className={styles[variant].buttonClassName}
              color='quaternary'
              icon={() => (
                <ClockIcon
                  className={classNames({ 'w-[15px] h-[15px]': styles[variant].size === 'small' })}
                  color='var(--color-text-primary)'
                />
              )}
              onClick={() => {
                onClick(event);
              }}
              tooltip={translate('recordings.jumpToAction')}
              size={styles[variant].size}
            />
          </div>
        }
        icon={
          <IconWithBackground
            className={styles[variant].iconClassName}
            style={{ backgroundColor: iconBgColor }}
            size={styles[variant].size}
            type={styles[variant].iconType}
            icon={({ className }) => eventIcon(className)}
          />
        }
        title={
          <TooltipWrapper placement='bottom' followCursorAxis='x' content={event.tooltipText}>
            <Paragraph colorClassName='text-text-primary' weight={fontWeights.medium} className='truncate'>
              {event.details}
            </Paragraph>
          </TooltipWrapper>
        }
        label={isPageHistoryTrackingEnabled ? event.page : undefined}
      />
    );
  },
);
