import { FILTERS_NAME } from '@va/constants';
import {
  FunnelFilter,
  useAlarmingEventFilter,
  useBooleanOptions,
  useBrowserOptions,
  useCountryOptions,
  useDeviceOptions,
  useNewReferrerFilter,
  useOssOptions,
  useTrafficChannelFilters,
  useVisitorTypeOptions,
} from '@va/dashboard/shared/filters';
import { useTranslate } from '@va/localization';
import { IntervalFilter, MultiSelectFilter, SingleSelectFilter, TextFilter } from '@va/shared/feature-filters';
import { Filter, FilterOperators } from '@va/types/filters';
import { isWixMiniApp } from '@va/util/helpers';
import { useMemo } from 'react';

const useFunnelFilter = () => {
  const translate = useTranslate();

  // Does not make sense in the context of the wix mini app
  if (isWixMiniApp()) return null;

  return new FunnelFilter({
    id: '14',
    name: FILTERS_NAME.FUNNEL_DROPOUTS,
    label: translate('filters.options.funnel'),
  });
};

const useRecordingsFilterOptions = () => {
  const translate = useTranslate();

  const countryOptions = useCountryOptions();
  const deviceOptions = useDeviceOptions();
  const visitorTypeOptions = useVisitorTypeOptions();
  const alarmingEventFilter = useAlarmingEventFilter({ name: FILTERS_NAME.ALARMING_EVENT_CONTAIN });
  const booleanOptions = useBooleanOptions({ falseValue: 'false', trueValue: 'true' });
  const referrer = useNewReferrerFilter({ name: FILTERS_NAME.REFERRER_URL });
  const funnelFilter = useFunnelFilter();
  const trafficChannelFilters = useTrafficChannelFilters();

  const browserNameFilter = useMemo(
    () =>
      new MultiSelectFilter({
        id: 'browserName',
        name: FILTERS_NAME.BROWSER,
        label: translate('filter.browserName'),
        inputProps: {
          showSearchInput: true,
          searchPlaceholder: translate('filters.placeholder.browserName'),
          useOptions: useBrowserOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),

    [translate],
  );
  const osFilter = useMemo(
    () =>
      new MultiSelectFilter({
        id: 'operating-system',
        name: FILTERS_NAME.OPERATING_SYSTEM,
        label: translate('filter.operatingSystem'),
        inputProps: {
          searchPlaceholder: translate('filters.placeholder.platform'),
          showSearchInput: true,
          useOptions: useOssOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),
    [translate],
  );

  const containsPageFilter = useMemo(
    () =>
      new TextFilter({
        id: '10',
        name: FILTERS_NAME.URL_CONTAIN,
        inputProps: { label: translate('filters.placeholder.urlAddress') },
        label: translate('filters.options.containsPage'),
        appliedLabel: translate('filters.options.page'),
        operator: FilterOperators.contains,
      }),
    [translate],
  );

  const filterOptionsWithoutAbes: Filter[] = useMemo(() => {
    const options = [
      referrer,
      trafficChannelFilters,
      new TextFilter({
        id: 'adCampaignLabels',
        name: FILTERS_NAME.AD_CAMPAIGN_LABEL,
        label: translate(`filters.utmParams.adCampaignLabel`),
        inputProps: { placeholder: translate(`filters.placeholder.adCampaignLabel`) },
        operator: FilterOperators.is,
      }),
      new MultiSelectFilter({
        id: 'countries',
        name: FILTERS_NAME.COUNTRY,
        label: translate('filter.countries'),
        inputProps: {
          useOptions: () => ({ data: countryOptions }),
          searchPlaceholder: translate('filters.placeholder.countries'),
          showSearchInput: true,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),
      browserNameFilter,
      osFilter,
      new MultiSelectFilter({
        id: '4',
        label: translate('filter.devices'),
        name: FILTERS_NAME.DEVICE,
        inputProps: { useOptions: () => ({ data: deviceOptions }) },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
        valueMapper: (value, operator) => {
          return { [operator]: value };
        },
      }),
      new MultiSelectFilter({
        id: '5',
        label: translate('filters.options.visitorStatus'),
        name: FILTERS_NAME.VISITOR_TYPE,
        inputProps: { useOptions: () => ({ data: visitorTypeOptions }) },
      }),
      new SingleSelectFilter({
        id: '6',
        label: translate('filters.options.watched'),
        name: FILTERS_NAME.WATCHED,
        inputProps: { useOptions: () => ({ data: booleanOptions }), showSearchInput: false },
      }),
      new SingleSelectFilter({
        id: '7',
        label: translate('filters.options.starred'),
        name: FILTERS_NAME.STARRED,
        inputProps: { useOptions: () => ({ data: booleanOptions }), showSearchInput: false },
      }),
      new TextFilter({
        id: '8',
        name: FILTERS_NAME.ENTRY_PAGE,
        label: translate('filters.options.entryPage'),
        inputProps: { label: translate('filters.placeholder.urlAddress') },
      }),
      new TextFilter({
        id: '9',
        name: FILTERS_NAME.EXIT_PAGE,
        inputProps: { label: translate('filters.placeholder.urlAddress') },
        label: translate('filters.options.exitPage'),
      }),
      containsPageFilter,
      new TextFilter({
        id: '11',
        name: FILTERS_NAME.URL_NOT_CONTAIN,
        inputProps: { label: translate('filters.placeholder.urlAddress') },
        label: translate('filters.options.notContainsPage'),
        appliedLabel: translate('filters.options.page'),
        operator: FilterOperators.doesNotContain,
      }),
      new IntervalFilter({
        id: '12',
        name: FILTERS_NAME.DURATION,
        label: translate('filters.options.recordingsDuration'),
        inputProps: { minLabel: translate('filters.minDuration'), maxLabel: translate('filters.maxDuration') }, // TODO Add validator for the values
      }),
      new IntervalFilter({
        id: '13',
        name: FILTERS_NAME.PAGE_COUNT,
        label: translate('filters.options.pageCount'),
        inputProps: {
          placeholder: translate('filters.options.pageCount'),
          minLabel: translate('filters.minPages'),
          maxLabel: translate('filters.maxPages'),
        }, // TODO Add validator for the values
      }),
      funnelFilter,
      new TextFilter({
        id: '15',
        name: FILTERS_NAME.RECORDING_ID,
        inputProps: { label: translate('filters.placeholder.recordingId') },
        label: translate('filters.options.recordingId'),
        appliedLabel: translate('filters.options.recordingId'),
        operator: FilterOperators.equal,
      }),
      // Commented tags filter based on VISA-9862
      // new KeyValueFilter({
      //   id: '17',
      //   name: FILTERS_NAME.RECORDING_TAGS,
      //   label: translate('filters.options.recordingsTags'),
      //   inputProps: {
      //     placeholder: translate('filters.options.recordingsTags'),
      //     keyLabel: translate('filters.enterKey'),
      //     valueLabel: translate('filters.enterValue'),
      //   },
      // }),
      new SingleSelectFilter({
        id: '16',
        label: translate('filters.options.hasNote'),
        name: FILTERS_NAME.HAS_NOTE,
        inputProps: { useOptions: () => ({ data: booleanOptions }), showSearchInput: false },
      }),
    ];

    return options.filter((o) => o !== null) as Filter[];
  }, [
    referrer,
    trafficChannelFilters,
    translate,
    browserNameFilter,
    osFilter,
    containsPageFilter,
    funnelFilter,
    countryOptions,
    deviceOptions,
    visitorTypeOptions,
    booleanOptions,
  ]);

  const filterOptions = useMemo(
    () => [...filterOptionsWithoutAbes, ...(alarmingEventFilter ? [alarmingEventFilter] : [])],
    [alarmingEventFilter, filterOptionsWithoutAbes],
  );

  return { filterOptions, filterOptionsWithoutAbes, alarmingEventFilter, containsPageFilter };
};

export default useRecordingsFilterOptions;
