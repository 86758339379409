import { FEATURE_TOUR_IDS, supportLinks } from '@va/constants';
import { DropdownIcon, GlobeIcon, HelpIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { HorizontalDivider } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import LanguageSelector from '../languageSelector/LanguageSelector';
import { UserInitials } from './UserInitials';
import { UserMenuButton } from './UserMenuButton';
import { useUserInformation } from './useUserInformation';
import { useUserMenu } from './useUserMenu';

type OnCloseCallback = () => void;
type UserMenuProps = {
  renderUserInfo?: (onClose: OnCloseCallback) => ReactNode;
  renderAccountSettingsButton?: (onClose: OnCloseCallback) => ReactNode;
  logoutButton?: ReactNode;
};

export function UserMenu({ renderUserInfo, renderAccountSettingsButton, logoutButton }: UserMenuProps) {
  const translate = useTranslate();
  const { userInitials } = useUserInformation();
  const { isExpanded, toggleExpanded, closeMenu } = useUserMenu();
  const [isLanguageSelectorVisible, setIsLanguageSelectorVisible] = useState(false);

  const handleLanguageSelectorToggle = () => setIsLanguageSelectorVisible((prev) => !prev);
  return (
    <TooltipWrapper
      open={isExpanded}
      onOpenChange={(open) => toggleExpanded(open)}
      trigger='click'
      zIndex={999}
      tooltipClassNames='shadow-md rounded-24'
      content={
        <div className='min-w-[400px] rounded-24 bg-white border-2 border-gray-mercury text-black px-3 py-3'>
          {!isLanguageSelectorVisible ? (
            <>
              {renderUserInfo && renderUserInfo(closeMenu)}

              <UserMenuButton
                leftIcon={<GlobeIcon color='var(--color-black)' />}
                text={translate('locale.language')}
                rightIcon={<DropdownIcon />}
                onClick={handleLanguageSelectorToggle}
              />
              <HorizontalDivider />

              {renderAccountSettingsButton && renderAccountSettingsButton(closeMenu)}

              <a href={supportLinks.home} target='_blank' rel='noreferrer' onClick={closeMenu}>
                <UserMenuButton leftIcon={<HelpIcon />} text={translate('button.helpCenter')} />
              </a>
              {logoutButton}
            </>
          ) : (
            <LanguageSelector
              onBackClick={handleLanguageSelectorToggle}
              onLanguageSelected={() => {
                handleLanguageSelectorToggle();
                closeMenu();
              }}
            />
          )}
        </div>
      }
      arrow={false}
      useDefaultStyle={false}
    >
      <div
        data-tour={FEATURE_TOUR_IDS.userMenu}
        className={classNames(
          'mr-2 sm-initial:mr-0 sm-initial:h-60px rounded-15 bg-primary-lighter-06 hover:bg-primary-lighter-13 sm-initial:p-3 flex justify-between items-center cursor-pointer',
          { isOpen: isExpanded },
        )}
      >
        <UserInitials initials={userInitials} />
        <DropdownArrow open={isExpanded} className='hidden sm-initial:block sm-initial:ml-2' />
      </div>
    </TooltipWrapper>
  );
}
