import { LIMIT } from '@va/dashboard/modules/feedback/shared';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import DebouncedInput from '@va/deprecated/form/DebouncedInput';
import { TripleDotIcon } from '@va/icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect } from 'react';

const ChoiceOption = ({ className, translate, optionsMenu, name, innerRef, isFormDisabled, handleChoiceOrder, id }) => {
  const wrapperId = `draggable-${id}`;

  const onDrop = useCallback(
    (event) => {
      const targetElementId = event.target.parentNode.id.split('-')[1];
      const sourceElementId = event.dataTransfer.getData('text/plain');

      handleChoiceOrder(sourceElementId, targetElementId);
    },
    [handleChoiceOrder],
  );

  const onDragStart = useCallback((event) => {
    event.dataTransfer.setData('text/plain', event.target.id.split('-')[1]);
  }, []);

  useEffect(() => {
    const choice = document.getElementById(wrapperId);
    choice.addEventListener('dragstart', onDragStart);
    choice.addEventListener('drop', onDrop);

    return () => {
      choice.removeEventListener('dragstart', onDragStart);
      choice.removeEventListener('drop', onDrop);
    };
  }, [handleChoiceOrder, onDragStart, onDrop, wrapperId]);

  return (
    <div
      className={classNames('flex border-b-2 border-gray-gallery relative', className)}
      draggable={!isFormDisabled && 'true'}
      id={wrapperId}
    >
      <div
        className={classNames('flex items-center justify-center border-r-2 border-gray-gallery w-14 h-12', {
          'cursor-grab': !isFormDisabled,
        })}
      >
        <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect y='8' width='12' height='2' rx='1' fill='#CCCCCC' />
          <rect y='4' width='12' height='2' rx='1' fill='#CCCCCC' />
          <rect width='12' height='2' rx='1' fill='#CCCCCC' />
        </svg>
      </div>

      <Field name={name}>
        {({ field, form }) => (
          <DebouncedInput
            value={field.value}
            disabled={isFormDisabled}
            name={name}
            maxLength={LIMIT.CHARS.CHOICE_OPTION}
            as='input'
            className='mx-2 pr-8 w-full bg-white'
            placeholder={translate('feedback.question.choicePlaceholder')}
            ref={innerRef}
            onChange={field.onChange}
          />
        )}
      </Field>

      {optionsMenu && !isFormDisabled && (
        <TooltipWrapper
          content={optionsMenu}
          trigger='click'
          tooltipClassNames='rounded-lg shadow-md'
          useDefaultStyle={false}
        >
          <button
            type='button'
            className='absolute right-0 h-full w-10 text-center focus:outline-none hover:bg-alabaster active:bg-gray-concrete'
          >
            <TripleDotIcon className='inline-block' />
          </button>
        </TooltipWrapper>
      )}
    </div>
  );
};

ChoiceOption.propTypes = {
  className: PropTypes.string,
  optionsMenu: PropTypes.node,
  name: PropTypes.string,
  innerRef: PropTypes.object,
  isFormDisabled: PropTypes.bool,

  // withTranslate
  translate: PropTypes.func.isRequired,
};

export default withTranslate(memo(ChoiceOption));
