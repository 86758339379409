const createGenericId = (id: string) => `generic-${id}`;
const createSpecificId = (id: string) => `specific-${id}`;
const createFormElementId = (formName: string, inputName: string) => `form-${formName}-${inputName}`;

export const TEST_IDS = {
  helpers: {
    createReportBlockId: (id: string) => createSpecificId(`report-block-${id}`),
    createTableId: (id: string) => createSpecificId(`table-${id}`),
    createTableCellId: (id: string) => createGenericId(`table-cell-${id}`),
    createListItemId: (id: string) => createGenericId(`list-item-${id}`),
    createFormElementId: (formName: string, inputName: string) => createFormElementId(formName, inputName),
    createDropdownOptionId: (id: string, index: number) => createGenericId(`dropdown-option-${id}-${index}`),
    createFeatureOnboardingElementId: (id: string) => createSpecificId(`feature-onboarding-${id}`),
  },
  // Can appear multiple times inside the DOM should only be selected inside a specific context
  generic: {
    filters: {
      dropdownContainer: createGenericId('filters-dropdown-container'),
      list: createGenericId('filters-list'),
      toggleBtn: createGenericId('toggle-filters-btn'),
      newFilterTabBtn: createGenericId('new-filter-tab-btn'),
      filterTemplatesTabBtn: createGenericId('filter-templates-tab-btn'),
      filterTemplatesList: createGenericId('filter-templates-list'),
    },
    bulkOperations: {
      selectBtn: createGenericId('bulk-operations-select-btn'),
      selectAll: createGenericId('bulk-operations-select-all'),
    },
    buttons: {
      close: createGenericId('close-btn'),
      open: createGenericId('open-btn'),
      submit: createGenericId('submit-btn'),
      apply: createGenericId('apply-btn'),
      continue: createGenericId('continue-btn'),
      cancel: createGenericId('cancel-btn'),
      reset: createGenericId('reset-btn'),
      erase: createGenericId('erase-btn'),
      refresh: createGenericId('refresh-btn'),
      back: createGenericId('back-btn'),
      upgrade: createGenericId('upgrade-btn'),
      closeModal: createGenericId('close-modal-btn'),
      seeMore: createGenericId('see-more-btn'),
      viewAll: createGenericId('view-all-btn'),
      pause: createGenericId('pause-btn'),
      create: createGenericId('create-btn'),
      select: createGenericId('select-btn'),
      copyToClipboard: createGenericId('copy-to-clipboard-btn'),
      goToWebsite: createGenericId('go-to-website-btn'),
      redirect: createGenericId('redirect-btn'),
      jumpToEvent: createGenericId('jump-to-event-btn'),
    },
    visualizationOptions: {
      dropdownContainer: createGenericId('visualization-options-dropdown-container'),
      toggleBtn: createGenericId('toggle-visualization-options-btn'),
    },
    dropdown: createGenericId('dropdown'),
    datePicker: {
      dropdownContainer: createGenericId('date-picker-dropdown-container'),
      optionsList: createGenericId('date-picker-options-list'),
    },
    inputs: {
      search: createGenericId('search-input'),
      email: createGenericId('email-input'),
      password: createGenericId('password-input'),
      text: createGenericId('text-input'),
      checkbox: createGenericId('checkbox-input'),
    },
    export: {
      toggleBtn: createGenericId('toggle-export-btn'),
      dropdownContainer: createGenericId('export-dropdown-container'),
      getExportOptionId: (option: string) => createGenericId(`export-option-${option}`),
    },
    previousPeriodComparison: {
      toggleOptionsDropdownBtn: createGenericId('toggle-previous-period-comparison-options-dropdown'),
      toggleComparisonBtn: createGenericId('toggle-previous-period-comparison-btn'),
      dropdownOptionsContainer: createGenericId('compare-to-previous-dropdown'),
    },
    chart: {
      getChartTileId: (id: string) => createGenericId(`chart-tile-${id}`),
      toggleTileContextMenuBtn: createGenericId('chart-toggle-tile-context-menu-btn'),
      tooltipContainer: createGenericId('chart-tooltip-container'),
      datapoint: createGenericId('chart-datapoint'),
    },
    table: {
      nextPageBtn: createGenericId('table-next-page-btn'),
      prevPageBtn: createGenericId('table-prev-page-btn'),
      pageSelectorDropdown: createGenericId('table-page-selector-dropdown'),
      pageSelectorTooltip: createGenericId('table-page-selector-tooltip'),
      pageSelectorInputField: createGenericId('table-page-selector-input-field'),
      rowsPerPageDropdown: createGenericId('table-rows-per-page-dropdown'),
      rowsPerPageTooltip: createGenericId('table-rows-per-page-tooltip'),
      rowsPerPageOption: createGenericId('table-rows-per-page-option'),
      goToFirstPageBtn: createGenericId('table-go-to-first-page-btn'),
      goToLastPageBtn: createGenericId('table-go-to-last-page-btn'),
      sortHeaderBtn: createGenericId('table-sorting-header-btn'),
      groupHeaderBtn: createGenericId('table-grouping-header-btn'),
      sortAndGroupHeaderBtn: createGenericId('table-sorting-and-grouping-header-btn'),
      groupColumnSwitch: createGenericId('table-group-column-switch'),
      groupColumnOption: createGenericId('table-group-column-option'),
      headerOptionsTooltip: createGenericId('table-header-options-tooltip'),
      sortColumnAscBtn: createGenericId('table-sort-column-asc-btn'),
      sortColumnDescBtn: createGenericId('table-sort-column-desc-btn'),
    },
    dashboardCard: {
      heading: createGenericId('dashboard-card-heading'),
      percentageChange: createGenericId('dashboard-card-percentage-change'),
    },
    links: {
      location: createGenericId('links-location'),
      visitedPagesHistory: createGenericId('link-visited-pages-history'),
      sessionRecordings: createGenericId('link-session-recordings'),
      userHistory: createGenericId('link-user-history'),
      action: createGenericId('link-user-action'),
    },
    companyReveal: {
      label: createGenericId('company-reveal-label'),
      tooltipContent: createGenericId('company-reveal-tooltip-content'),
    },
    visitInfo: {
      device: createGenericId('visit-info-device'),
      browser: createGenericId('visit-info-browser'),
      os: createGenericId('visit-info-os'),
    },
    featureCard: {
      statusSection: createGenericId('feature-card-status-section'),
      toggleStatusBtn: createGenericId('feature-card-toggle-status-btn'),
      viewUnseenRecordingsBtn: createGenericId('feature-card-view-unseen-recordings'),
      settingsBtn: createGenericId('feature-card-settings-btn'),
    },
    privacyLevelCard: {
      countriesDropdown: createGenericId('privacy-level-card-countries-dropdown'),
      featuresCard: createGenericId('privacy-level-card-features-card'),
    },
    eventTracking: {
      toggleSettingBtn: createGenericId('event-tracking-toggle-settings-btn'),
    },
    whiteLabel: {
      toggleWhiteLabelSwitch: createGenericId('white-label-toggle-white-label-switch'),
      pricingPlanBtn: createGenericId('white-label-pricing-plan-btn'),
      upgradePlanBtn: createGenericId('white-label-upgrade-plan-btn'),
      commissionPayoutsBtn: createGenericId('white-label-commission-payouts-btn'),
      payoutsByYearSelect: createGenericId('white-label-payouts-by-year-select'),
    },
    whiteLabelTheme: {
      confirmReplyToEmailBtn: createGenericId('custom-email-confirm-reply-to-email-btn'),
      sendTestEmailBtn: createGenericId('custom-email-send-test-email-btn'),
    },
    invoices: {
      downloadInvoiceBtn: createGenericId('download-invoice-btn'),
    },
    icon: {
      os: createGenericId('icon-os'),
      browser: createGenericId('icon-browser'),
      device: createGenericId('icon-device'),
    },
    visitorsMap: {
      togglePinsBtn: createGenericId('visitors-map-toggle-pins-btn'),
      liveMapContent: createGenericId('visitors-live-map-content'),
      liveMapTooltip: createGenericId('visitors-live-map-tooltip'),
      liveMapCounter: createGenericId('visitors-live-map-counter'),
    },

    timeUnitSelector: createGenericId('time-unit-selector'),
    funnels: {
      detailsBtn: createGenericId('funnels-details-btn'),
      activateBtn: createGenericId('funnels-activate-btn'),
      moreActionsBtn: createGenericId('funnels-more-actions-btn'),
      editBtn: createGenericId('funnels-edit-name-btn'),
      editNameBtn: createGenericId('funnels-edit-name-btn'),
      copyLinkBtn: createGenericId('funnels-copy-link-btn'),
      deleteBtn: createGenericId('funnels-delete-btn'),
    },
    heatmaps: {
      toggleBtn: createGenericId('toggle-heatmaps-btn'),
      createBtn: createGenericId('create-heatmap-btn'),
      heatmapCard: {
        selectHeatmapBtn: createGenericId('heatmap-card-select-btn'),
        settingsMenuBtn: createGenericId('heatmap-card-settings-menu-btn'),
        heatmapCreator: createGenericId('heatmap-card-creator'),
        heatmapStatus: createGenericId('heatmap-card-status'),
        heatmapLastEdited: createGenericId('heatmap-card-last-edited'),
        pageTargetType: createGenericId('heatmap-card-page-target-type'),
        pageUrl: createGenericId('heatmap-card-page-url'),
        pageViews: createGenericId('heatmap-card-page-views'),
        viewHeatmapBtn: createGenericId('heatmap-card-view-heatmap-btn'),
      },
      createHeatmap: {
        selectPage: createGenericId('create-heatmap-select-page'),
        pageSearchField: createGenericId('create-heatmap-page-search-field'),
      },
    },
    recordings: {
      starIcon: createGenericId('recordings-star-icon'),
      commentIcon: createGenericId('recordings-comment-icon'),
    },
    link: createGenericId('link'),
    title: createGenericId('title'),
    subtitle: createGenericId('subtitle'),
    tooltipContent: createGenericId('tooltip-content'),
    nestedNavContextMenu: createGenericId('nav-link-context-menu'),
    validationRepresentation: {
      error: createGenericId('validation-error'),
    },
    loader: createGenericId('loader'),
    count: createGenericId('count'),
    avatar: createGenericId('avatar'),
    noDataAvailable: createGenericId('no-data-available'),
    progress: createGenericId('progress'),
  },
  // Should only appear once in the DOM and can be selected directly
  specific: {
    appLogo: createSpecificId('app-logo'),
    communityBtn: createSpecificId('community-btn'),
    featureTourBtn: createSpecificId('feature-tour-btn'),
    toggleAccountSettingsBtn: createSpecificId('toggle-account-settings-btn'),
    toggleLeftSidebarBtn: createSpecificId('toggle-left-sidebar-btn'),
    pageTitle: createSpecificId('page-title'),
    pageHeaderContainer: createSpecificId('page-header-container'),
    autoRefreshDropdown: {
      toggleBtn: createSpecificId('toggle-auto-refresh-dropdown-btn'),
      dropdownContainer: createSpecificId('auto-refresh-dropdown-container'),
    },
    featureCounter: {
      btn: createSpecificId('feature-counter-btn'),
      dropdownContainer: createSpecificId('feature-counter-dropdown-container'),
      limit: createSpecificId('feature-counter-limit'),
    },
    customDashboards: {
      createDashboardFormContainer: createSpecificId('create-dashboard-form-container'),
      createDashboardBtn: createSpecificId('create-dashboard-btn'),
      toggleAllDashboardsDropdown: createSpecificId('toggle-all-dashboard-btn'),
      dashboardsListDropdownContainer: createSpecificId('custom-dashboards-list-dropdown-container'),
      getOpenDashboardLinkId: (id: string) => createSpecificId(`open-dashboard-link-${id}`),
    },
    datePicker: {
      toggleBtn: createSpecificId('date-picker-toggle-btn'),
      dropdownContainer: createSpecificId('date-picker-dropdown-container'),
    },
    nestedNavigationLinks: {
      masterDashboard: createSpecificId('master-dashboard-nav-link'),
      pagesDashboard: createSpecificId('pages-dashboard-nav-link'),
      eventTracking: {
        auto: createSpecificId('event-auto-tracking-nav-link'),
        manual: createSpecificId('event-manual-tracking-nav-link'),
        alarmingBehavior: createSpecificId('event-alarming-behavior-nav-link'),
        generator: createSpecificId('event-tag-generator-nav-link'),
      },
      trafficStructure: {
        trafficChannels: createSpecificId('traffic-channels-nav-link'),
        pagesPerSession: createSpecificId('pages-per-session-nav-link'),
        sessionDuration: createSpecificId('session-duration-nav-link'),
        bounceRate: createSpecificId('bounce-rate-nav-link'),
      },
      pages: {
        landingPages: createSpecificId('landing-pages-nav-link'),
        conversions: createSpecificId('conversion-pages-nav-link'),
        referring: createSpecificId('referring-pages-nav-link'),
      },
    },
    admin3as: {
      pricingPlansNavLink: createSpecificId('admin3as-pricing-plans-nav-link'),
      clientsNavLink: createSpecificId('admin3as-clients-nav-link'),
      maxStpLimitBtn: createSpecificId('admin3as-max-stp-limit-btn'),
      stpCostBtn: createSpecificId('admin3as-stp-cost-btn'),
    },
    register: {
      form: createSpecificId('register-form'),
    },
    recordingDetailsPage: {
      detailsTab: createSpecificId('recording-details-tab'),
      actionsTab: createSpecificId('recording-actions-tab'),
    },
    recordingsSettingsTab: {
      recordAllPagesBtn: createSpecificId('recordings-settings-record-all-pages-btn'),
      recordSpecificPages: {
        button: createSpecificId('recordings-settings-record-specific-pages-btn'),
        selectPageTab: createSpecificId('recordings-settings-record-specific-pages-select-page-tab'),
        selectPageDropdown: createSpecificId('recordings-settings-record-specific-pages-select-page-dropdown'),
        selectCustomUrlTab: createSpecificId('recordings-settings-record-specific-pages-select-custom-url-tab'),
        urlStructurePreviewBtn: createSpecificId('recordings-settings-record-specific-pages-url-structure-preview-btn'),
        selectUrlPatternDropdown: createSpecificId(
          'recordings-settings-record-specific-pages-select-url-pattern-dropdown',
        ),
      },
      minDurationSwitch: createSpecificId('recordings-settings-min-duration-switch'),
      minDurationInput: createSpecificId('recordings-settings-min-duration-input'),
      textObfuscationSwitch: createSpecificId('recordings-settings-text-obfuscation-switch'),
    },
    outgoingTrafficSettingsTab: {
      considerWhitelistedURLsAsExternalToggle: createSpecificId(
        'outgoingTraffic-settings-whitelistedURLs-external-toggle',
      ),
    },
    wixMini: {
      recordingsOverviewNavLink: createSpecificId('wix-mini-recordings-overview-nav-link'),
      generalSettingsNavLink: createSpecificId('wix-mini-general-settings-nav-link'),
      privacyCenterNavLink: createSpecificId('wix-mini-privacy-center-nav-link'),
    },
    activateSessionRecordings: createSpecificId('activate-session-recordings'),
  },
};
