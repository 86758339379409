import { NestedNavigationType, NestedRoute, NestedTab } from '@va/types/navigation';

export function buildNestedRoute(route: Omit<NestedRoute, 'type'>): NestedRoute {
  return { ...route, type: NestedNavigationType.Route };
}

export function buildNestedTab(tab: Omit<NestedTab, 'type'>): NestedTab {
  return { ...tab, type: NestedNavigationType.Tab };
}

export function buildNestedNavigation(routes: Array<NestedRoute>): Array<NestedRoute>;
export function buildNestedNavigation(tabs: Array<NestedTab>): Array<NestedTab>;
export function buildNestedNavigation(tabs: Array<NestedRoute | NestedTab>): Array<NestedRoute | NestedTab>;

export function buildNestedNavigation(routes: Array<NestedRoute | NestedTab>): Array<NestedRoute | NestedTab> {
  return routes;
}
