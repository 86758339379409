import { isNil, isPlainObject } from 'lodash';
// MOVE TO CONSTANTS
export const urlViews = {
  URL: 'url',
  TITLE: 'title',
};
// MOVE TO CONSTANTS
export const pageType = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
};

export function getParameterByName(name: string, url?: string) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&amp;]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const formatQueryParamsToRedirectURL = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const pushTo = searchParams.get('pushTo');

  if (pushTo) searchParams.delete('pushTo');

  const queryString = searchParams.toString();
  return `${pushTo ?? ''}${queryString ? `?${queryString}` : ''}`;
};

export const updateUrlParameter = (key: string, value: string | string[]) => {
  const url = new URL(window.location.href);
  const hash = window.location.hash;
  const searchParams = new URLSearchParams(url.search);

  if (Array.isArray(value)) {
    searchParams.delete(key);
    value.forEach((val) => searchParams.append(key, val));
  } else {
    searchParams.set(key, value);
  }
  const updatedUrl = `${url.pathname}?${searchParams.toString()}${hash}`;
  return updatedUrl;
};

export const removeUrlParameter = (key: string) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const hash = window.location.hash;
  searchParams.delete(key);

  const updatedUrl = `${url.pathname}?${searchParams.toString()}${hash}`;
  return updatedUrl;
};

export function getUrlWithTrailingSlash(url: string) {
  return url && url.replace(/\/?$/, '/');
}

export function getUrlWithoutTrailingSlash(url: string) {
  return url && url.replace(/\/?$/, '');
}

export function getUrlWithoutQueryString(url: string) {
  return url && url.split('?')[0];
}

export function getUrlWithoutProtocolAndTrailingSlash(url: string) {
  return getUrlWithoutTrailingSlash(removeWww(removeProtocol(url)));
}

export function removeWww(url: string) {
  if (getWellFormedUrl(url).includes('//www.')) return url.replace('www.', '');
  return url;
}

export function getUrlPrefix(url: string) {
  const urlWithoutDomain = url.split('.')[0];
  return url && removeProtocol(urlWithoutDomain);
}

export function removeProtocol(url: string) {
  if (url.includes('http://')) return url.replace('http://', '');
  if (url.includes('https://')) return url.replace('https://', '');

  return url;
}

export function getWellFormedUrl(url: string) {
  const protocolPattern = new RegExp(/^(?:(?:https|http):\/\/)/);
  const hasProtocol = url && protocolPattern.test(url.toString().toLowerCase());
  return hasProtocol ? url : 'https://' + url;
}

export const toQueryString = function (obj: Record<string, any> | undefined, prefix?: string): string {
  if (!obj) return '';
  // eslint-disable-next-line prefer-const
  let str = [],
    p;
  for (p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p) && !isNil(obj[p])) {
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && isPlainObject(v) ? toQueryString(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }

  return str.join('&');
};

export const addProtocol = (url: string, websiteBaseUrl: string | null, pageTypeData: string | null, ssl = false) => {
  if (!url) return '';
  if (pageType.DYNAMIC === pageTypeData && websiteBaseUrl) {
    url = url.includes(websiteBaseUrl) ? url : websiteBaseUrl + url;
  }

  if (!url.includes('://')) {
    return `http${ssl ? 's' : ''}://` + url;
  }
  return url;
};

export const getUrlView = (websiteUrlView: string, url: string, pageName: string) => {
  if (websiteUrlView === urlViews.TITLE || !url) return pageName;

  try {
    const urlInfo = new URL(addProtocol(url, null, null, true));
    return urlInfo.pathname === '/' ? urlInfo.host : urlInfo.pathname;
  } catch {
    return url;
  }
};

export function getUrlPathname(url: string) {
  try {
    const fullUrl = new URL(url);
    return fullUrl.pathname;
  } catch (e) {
    console.log(`Cannot construct URL from ${url}`);
    return '/';
  }
}
