import { upperFirst } from 'lodash';

export function capitalizeFirstLetter(str: string) {
  return upperFirst(str);
}

export function generateString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getFullName({ firstName, lastName }: { firstName: string; lastName: string }) {
  if (!firstName && !lastName) return '';
  if (firstName && !lastName) return capitalizeFirstLetter(firstName);
  if (lastName && !firstName) return capitalizeFirstLetter(lastName);
  return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
}

export function truncate(str: string, maxLength: number, postfix: string) {
  const substring = str && str.length > maxLength ? str.substring(0, maxLength) : str;
  if (substring !== str) {
    postfix = postfix === undefined ? '...' : postfix;
    return substring + postfix;
  }

  return substring;
}

export const getInitials = (str: string) => {
  if (str.includes('@')) return str.slice(0, 2).toUpperCase();
  const initials = (str && str.match(/\b\w/g)) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export function getFirstLetter(input: string | undefined) {
  if (!input) return '';
  return input.charAt(0);
}

export const getInitialsFromUserInfo = (userInformation: { firstName?: string; lastName?: string; email?: string }) => {
  const { firstName, lastName, email } = userInformation ?? {};
  if (!firstName && !lastName && email) {
    return email.slice(0, 2);
  }

  return `${getFirstLetter(userInformation?.firstName)}${getFirstLetter(userInformation?.lastName)}`;
};

export const getFullNameFromUserInfo = (userInformation: { firstName?: string; lastName?: string; email?: string }) => {
  const { firstName = '', lastName = '', email } = userInformation ?? {};
  if (!firstName && !lastName && email) {
    return email;
  }

  return getFullName({ firstName, lastName });
};

export function transformCommaSeparatedValuesToArray(str: string) {
  if (str === '') return [];
  return str.split(',').map((s) => s.trim());
}

export async function hashString(input: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
