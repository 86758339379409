import { BLACK_COLOR } from '@va/constants';
import { TestAttributes } from '@va/types/component';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import { ElementType } from 'react';
import { ContextMenuProps } from '../context-menu';
import { ContextMenuWrapper } from './ContextMenuWrapper';

type NestedNavigationLinkProps = TestAttributes & {
  url: string;
  onRouteChange?: () => void;
  isActive: boolean;
  className?: string;
  activeColor?: string;
  icon?: ElementType;
  label: string;
  hasTooltip?: boolean;
  contextMenu?: ContextMenuProps;
  contextMenuElement?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onHover?: () => void;
  onClick?: () => void;
};

export const NestedNavigationLink = ({
  url,
  onRouteChange,
  isActive,
  className,
  activeColor = '#3C3C3C',
  icon: Icon,
  label,
  hasTooltip,
  contextMenu,
  contextMenuElement,
  endAdornment,
  onHover,
  onClick,
  ...rest
}: NestedNavigationLinkProps) => {
  return (
    <div
      data-testid={rest['data-testid']}
      id='nested-nav-link-wrapper'
      className='flex items-center relative h-full'
      onMouseEnter={onHover}
    >
      <Link
        id={'nested-nav-link'}
        to={url}
        key={url}
        onClick={() => {
          onClick?.();
          onRouteChange?.();
        }}
        style={{ color: isActive ? activeColor : undefined }}
        className={classNames(
          `whitespace-nowrap active:bg-gray-concrete py-3 px-4 text-15 transition font-medium text-center flex items-center h-full`,
          {
            'text-gray-charcoal hover:text-mine-shaft hover:bg-alabaster rounded-lg sm:rounded-none': !isActive,
          },
          className,
        )}
      >
        {Icon && (
          <Icon
            isActive={isActive}
            color={isActive ? activeColor : BLACK_COLOR}
            className='hidden sm-initial:block mr-2'
          />
        )}
        <span
          className={classNames({
            'underline-dashed': hasTooltip && isActive,
          })}
        >
          {label}
        </span>
        {endAdornment}
      </Link>
      <div
        className={'absolute bottom-0 left-0 right-0 h-[1px]'}
        style={{
          backgroundColor: isActive ? activeColor : undefined,
        }}
      />
      {isActive && contextMenuElement && contextMenuElement}
      {isActive && contextMenu && <ContextMenuWrapper contextMenu={contextMenu} />}
    </div>
  );
};
