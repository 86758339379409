import classNames from 'classnames';

export function Close({ className, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      className={classNames('icon close-icon', className)}
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <polygon
        fill='#13232A'
        points='18.8,2.9 17.7,1.7 9.9,8.9 2.1,1.7 1,2.9 8.7,10 1,17 2.1,18.2 9.9,11.1 17.7,18.2 18.8,17 11.1,10 '
      />
    </svg>
  );
}
