import { EVENTS, TEST_IDS } from '@va/constants';
import {
  DataViewButton,
  Paragraph,
  ParagraphWithTooltip,
  TitleInput,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCustomizationContext, useEventsContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { memo } from 'react';
import { useReportBlockCtx } from './report-block-ctx';

export const ReportBlockTitle = memo(() => {
  const { title, titleTooltip, titleTooltipOptions, visualization, size = 'medium', nodes } = useReportBlockCtx();
  const { canEditTitle } = usePermissionsProvider();
  const { localEventBus } = useEventsContext();
  const { getCustomValue } = useCustomizationContext();

  const customRBTitle = getCustomValue('initialTitle', typeof title === 'string' ? title : String(title));
  return (
    <div
      className={classNames('flex items-center', {
        'gap-12px': size === 'medium',
        'gap-9px': size === 'small',
        'overflow-hidden': !canEditTitle,
      })}
    >
      {visualization && <DataViewButton {...visualization} size={size} />}
      {canEditTitle ? (
        <TitleInput
          name={'title'}
          initialValue={customRBTitle}
          onSave={(title: string) => {
            localEventBus?.dispatch(EVENTS.editRBName, title);
          }}
        />
      ) : (
        <div className='overflow-hidden'>
          <TooltipWrapper
            trigger='click'
            interactive
            disabled={!titleTooltip}
            content={titleTooltip}
            {...titleTooltipOptions}
          >
            <div className='truncate max-w-min flex gap-2 items-center'>
              <ParagraphWithTooltip
                data-testid={TEST_IDS.generic.title}
                className={classNames('inline', { 'underline-dashed cursor-default': titleTooltip })}
                weight={fontWeights.medium}
                size={size === 'medium' ? paragraphSizes.large : paragraphSizes.medium}
              >
                {customRBTitle}
              </ParagraphWithTooltip>
              <span>{nodes?.titleTag}</span>
            </div>
          </TooltipWrapper>
          {typeof nodes?.subtitle === 'string' ? (
            <Paragraph className='mt-1'>{nodes.subtitle}</Paragraph>
          ) : (
            nodes?.subtitle && <div className='mt-1'>{nodes.subtitle}</div>
          )}
        </div>
      )}
    </div>
  );
});
