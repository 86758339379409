import { FILTERS_NAME } from '@va/constants';
import { useTranslate } from '@va/localization';
import { MultiSelectFilter } from '@va/shared/feature-filters';
import { FilterOperators } from '@va/types/filters';
import { isWixMiniApp } from '@va/util/helpers';
import { useMemo } from 'react';
import { useAlarmingEventOptions } from './filter-options';

export const useAlarmingEventFilter = ({
  name,
}: {
  name: (typeof FILTERS_NAME)['TRIGGER' | 'ALARMING_EVENT_CONTAIN'];
}) => {
  const translate = useTranslate();
  const alarmingEventOptions = useAlarmingEventOptions();

  const filter = useMemo(
    () =>
      new MultiSelectFilter({
        id: 'trigger',
        label: translate('filters.options.alarmingEvent'),
        name: name,
        inputProps: { useOptions: () => ({ data: alarmingEventOptions }) },
        operator: FilterOperators.equal,
      }),
    [alarmingEventOptions, name, translate],
  );

  if (isWixMiniApp()) return null;

  return filter;
};
