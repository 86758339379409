import { createColumnHelper } from '@tanstack/react-table';
import { getUrlViewFlag } from '@va/dashboard/selectors/core';
import { useLocalizationContext } from '@va/localization';
import { AlarmingBehavior } from '@va/types/events';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { PageListCardItem, Paragraph } from '@va/ui/design-system';
import { addNumberSeparator, getLocalizedDate } from '@va/util/helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAlarmingBehaviorEventsContext } from '../../context/ABEContext';
import { ReferrersCell } from '../cells/ReferrersCell';

const columnHelper = createColumnHelper<AlarmingBehavior>();
export const useAbesByPageColumns = () => {
  const { translate, locale } = useLocalizationContext();
  const { data } = useAlarmingBehaviorEventsContext();
  const urlView = useSelector(getUrlViewFlag);
  return useMemo(
    () => [
      columnHelper.accessor('page', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('all.abeDetailsTable.headers.page.title', {
              count: addNumberSeparator(data?.meta.total, locale) ?? 0,
            })}
            tooltip={translate('all.abeDetailsTable.headers.page.titleTooltip')}
          />
        ),
        cell: ({
          row: {
            original: { page, pageTitle, color },
          },
        }) => {
          return <PageListCardItem url={page} title={pageTitle} urlView={urlView} color={color} />;
        },
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-left',
        },
        minSize: 200,
      }),
      columnHelper.accessor('count', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.eventTracking.alarmingBehaviorEvents.table.numberOfDetections.title')}
            tooltip={translate('all.eventTracking.alarmingBehaviorEvents.table.numberOfDetections.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => <Paragraph>{addNumberSeparator(getValue(), locale)}</Paragraph>,
        minSize: 100,
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'referrers',
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.referrers.title')}
            tooltip={translate('all.abeDetailsTable.headers.referrers.tooltip')}
            context={context}
          />
        ),
        cell: ({
          row: {
            original: { referrers },
          },
        }) => {
          return <ReferrersCell referrers={referrers} />;
        },
        minSize: 100,
      }),
      columnHelper.accessor('firstAppearance', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.firstAppearance.title')}
            tooltip={translate('all.abeDetailsTable.headers.firstAppearance.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => (
          <Paragraph>
            {getLocalizedDate(getValue(), locale, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })}
          </Paragraph>
        ),
        minSize: 120,
        enableSorting: true,
      }),
      columnHelper.accessor('lastAppearance', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.lastAppearance.title')}
            tooltip={translate('all.abeDetailsTable.headers.lastAppearance.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => (
          <Paragraph>
            {getLocalizedDate(getValue(), locale, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })}
          </Paragraph>
        ),
        minSize: 120,
        enableSorting: true,
      }),
    ],
    [translate, data?.meta.total, locale, urlView],
  );
};
