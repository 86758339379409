import { TEST_IDS } from '@va/constants';
import { CommonProps } from '@va/types/component';
import { ButtonColors, fontWeights, Paragraph, paragraphSizes, SelectableButton } from '@va/ui/design-system';
import classNames from 'classnames';
import { ReactNode } from 'react';

export type SelectableButtonsGroupItemProps = CommonProps & {
  label: string;
  icon?: (iconClasses: string, color?: string) => React.ReactNode;
  value: ValueType;
  disabled?: boolean;
  tooltip?: ReactNode;
  onHover?: () => void;
};

type ValueType = string | number;

type SelectableButtonsGroupProps = CommonProps & {
  buttons: SelectableButtonsGroupItemProps[];
  title?: string;
  disabledTooltip?: string;
  onChange: (changedValue: ValueType) => void;
  selectedValue: ValueType;
  small?: boolean;
  flexDirectionClass?: string;
  activeButtonColor?: ButtonColors;
  inactiveButtonColor?: ButtonColors;
};

export const buildSelectableButtons = (buttons: SelectableButtonsGroupItemProps[]) => buttons;
export const buildSelectableButton = (button: SelectableButtonsGroupItemProps) => button;

export function SelectableButtonsGroup({
  buttons,
  title,
  disabledTooltip,
  onChange,
  selectedValue,
  className,
  small,
  flexDirectionClass = 'flex-row',
  activeButtonColor,
  inactiveButtonColor,
  ...rest
}: SelectableButtonsGroupProps) {
  return (
    <div
      data-testid={rest['data-testid']}
      className={classNames(
        'selectable-buttons-container flex flex-row items-center bg-white-snow rounded-xl p-[3px] justify-center md:justify-between',
        className,
      )}
    >
      {title && (
        <Paragraph
          colorClassName='text-gray-charcoal'
          size={paragraphSizes.tiny}
          weight={fontWeights.medium}
          className='px-3 sm:hidden whitespace-nowrap'
        >
          {title}
        </Paragraph>
      )}
      <div className={classNames('flex items-center gap-2 grow flex-nowrap', flexDirectionClass)}>
        {buttons.map((button: SelectableButtonsGroupItemProps, index) => {
          const selected = button.value === selectedValue;
          return (
            <SelectableButton
              {...button}
              data-testid={button['data-testid'] ?? TEST_IDS.helpers.createListItemId(String(button.value))}
              className={classNames(
                button.className,
                'leading-18 text-xs h-9',
                { 'hover:!bg-white !bg-transparent': !selected },
                { '!text-gray-silver': button.disabled },
              )}
              key={index}
              icon={button.icon}
              text={button.label}
              selected={selected}
              disabled={button.disabled}
              onClick={() => onChange(button.value)}
              tooltip={button.disabled ? disabledTooltip : button.tooltip}
              small={small}
              activeButtonColor={activeButtonColor}
              inactiveButtonColor={inactiveButtonColor}
              onHover={button.onHover}
            />
          );
        })}
      </div>
    </div>
  );
}
