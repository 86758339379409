import { NavAddCircle } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { isWixMiniApp } from '@va/util/helpers';
import { DashboardAppWorker, useCustomizationContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { AddFilterTooltip } from './add-filter-tooltip/AddFilterTooltip';
import './applied-filters.scss';
import { useFiltersContext } from './filters-context';

type AppliedFiltersProps = {
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

export const AppliedAddFilterButton = ({ size = 'large', className }: AppliedFiltersProps) => {
  const ctx = useFiltersContext();
  const { isDropdownOpen, setIsDropdownOpen, preFilterChange } = ctx;
  const translate = useTranslate();
  const { canEditFilters } = usePermissionsProvider();
  const { getCustomValue } = useCustomizationContext();

  return (
    <AddFilterTooltip isOpen={isDropdownOpen} openChange={setIsDropdownOpen}>
      <div className='grow'>
        <Button
          tooltip={
            !canEditFilters &&
            getCustomValue('disabledFiltersMessage', translate('all.defaultWarnings.cantEditFilters'))
          }
          disabled={!canEditFilters}
          color='quinary'
          icon={(_, color) => <NavAddCircle className='shrink-0' color={color} />}
          text={translate('button.addNewFilter')}
          className={classNames(
            'w-full rounded-r-none add-filter-btn',
            {
              'h-42px': size === 'large',
              'h-36px': size === 'medium',
            },
            className,
          )}
          textClasses='!text-12'
          onClick={() => {
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Main View',
                action: 'onclick',
                label: 'Add/Edit Filter',
                value: 'Button',
              });
            }

            const openDropdown = preFilterChange?.(ctx);
            if (preFilterChange && !openDropdown) return;
            setIsDropdownOpen((prev) => !prev);
          }}
          onHover={() => {
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Main View',
                action: 'hover',
                label: 'Add/Edit Filter',
                value: 'Button',
              });
            }
          }}
        />
      </div>
    </AddFilterTooltip>
  );
};
