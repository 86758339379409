import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { ModalWrapper } from '@va/dashboard/shared/modals/ModalWrapperV2/ModalWrapper';
import { CogIcon, FullscreenIcon, PauseIcon, PlayIcon, ReplayIcon, SkipIcon, TimerIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Paragraph, ToggleActionCard, fontWeights } from '@va/ui/design-system';
import { isWixMiniApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export type PlayerControlsProps = {
  currentSpeed: number;
  currentTime: string;
  isFullscreen: boolean | undefined;
  isPlaying: boolean;
  isFinished: boolean;
  isSkipping: boolean;
  setSpeed: (speen: number) => void;
  speedValues: number[];
  toggleFullScreen: () => void;
  togglePlay: () => void;
  toggleSkipInactive: () => void;
  totalTime: string;
  size?: 'medium' | 'large';
};

const CustomPlayerControlsV2: React.FC<PlayerControlsProps> = (props) => {
  const {
    togglePlay,
    isPlaying,
    isFinished,
    currentTime,
    totalTime,
    isSkipping,
    toggleSkipInactive,
    currentSpeed,
    speedValues,
    setSpeed,
    toggleFullScreen,
    size = 'large',
  } = props;
  const translate = useTranslate();
  const isMobile = useSelector(isMobileDevice);

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);

  const changeSpeed = useCallback(() => {
    const maxSpeed = speedValues[speedValues.length - 1];
    const currentSpeedIndex = speedValues.findIndex((value) => value === currentSpeed);
    if (currentSpeed === maxSpeed) {
      setSpeed(speedValues[0]);
      return;
    }
    setSpeed(speedValues[currentSpeedIndex + 1]);
  }, [currentSpeed, setSpeed, speedValues]);

  const controlStyle = useMemo(
    () =>
      classNames('flex items-center justify-center bg-white-snow p-3 hover:bg-gray-concrete active:bg-gray-mercury', {
        'w-12 h-12 rounded-12': size === 'large',
        'w-42px h-42px rounded-10.5': size === 'medium',
      }),
    [size],
  );

  return (
    <>
      <div className='flex overflow-auto scrollbar scrollbar-thumb items-center gap-3 w-full'>
        <button
          type='button'
          className={controlStyle}
          onMouseEnter={() => {
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onhover',
                label: `${isPlaying ? 'Pause' : 'Play'} Recording`,
                value: 'Button',
              });
            }
          }}
          onClick={() => {
            togglePlay();
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onclick',
                label: `${isPlaying ? 'Pause' : 'Play'} Recording`,
                value: 'Button',
              });
            }
          }}
        >
          {isPlaying ? (
            <PauseIcon color='#3C3C3C' />
          ) : isFinished ? (
            <ReplayIcon color='#3C3C3C' />
          ) : (
            <PlayIcon color='#3C3C3C' />
          )}
        </button>
        <Paragraph colorClassName='text-white'>{currentTime + '/' + totalTime}</Paragraph>
        <div className='ml-auto flex items-center gap-3'>
          {isMobile ? (
            <button
              type='button'
              onClick={() => {
                setIsActionsModalOpen(true);
              }}
              className={controlStyle}
            >
              <CogIcon color='#3C3C3C' />
            </button>
          ) : (
            <>
              <button
                type='button'
                className={classNames(controlStyle, 'text-15 text-gray-charcoal')}
                onMouseEnter={() => {
                  if (isWixMiniApp()) {
                    DashboardAppWorker.trackEvent({
                      category: 'Recording Detail View',
                      action: 'onhover',
                      label: 'Increase Play',
                      value: 'Button',
                    });
                  }
                }}
                onClick={() => {
                  changeSpeed();
                  if (isWixMiniApp()) {
                    DashboardAppWorker.trackEvent({
                      category: 'Recording Detail View',
                      action: 'onclick',
                      label: 'Increase Play',
                      value: 'Button',
                    });
                  }
                }}
              >
                {currentSpeed + 'x'}
              </button>
              <ToggleActionCard
                heightClass={classNames({ '!h-12': size === 'large' })}
                wrapperClass='w-full'
                name='skipInactivity'
                title={translate('ssr.player.skipInactivity')}
                value={isSkipping}
                icon={() => <SkipIcon color='#696969' />}
                handleChange={() => {
                  toggleSkipInactive();
                  if (isWixMiniApp()) {
                    DashboardAppWorker.trackEvent({
                      category: 'Recording Detail View',
                      action: 'onclick',
                      label: `Skip Inactivity ${isSkipping ? 'Off' : 'On'}`,
                      value: 'Button',
                    });
                  }
                }}
                onToggleHover={() => {
                  if (isWixMiniApp()) {
                    DashboardAppWorker.trackEvent({
                      category: 'Recording Detail View',
                      action: 'onhover',
                      label: 'Hover Button Skip Inactivity',
                      value: 'Button',
                    });
                  }
                }}
                size={size}
              />
            </>
          )}
          <button
            type='button'
            onClick={toggleFullScreen}
            onMouseEnter={() => {
              if (isWixMiniApp()) {
                DashboardAppWorker.trackEvent({
                  category: 'Recording Detail View',
                  action: 'onhover',
                  label: `Fullscreen`,
                  value: 'Button',
                });
              }
            }}
            className={controlStyle}
          >
            <FullscreenIcon color='#3C3C3C' />
          </button>
        </div>
      </div>
      {isActionsModalOpen && isMobile && (
        <ControlsActionsModal
          closeControlsActionsModal={() => {
            setIsActionsModalOpen(false);
          }}
          isOpen={isActionsModalOpen}
          {...props}
        />
      )}
    </>
  );
};

export default CustomPlayerControlsV2;

const ControlsActionsModal = ({
  isSkipping,
  toggleSkipInactive,
  currentSpeed,
  setSpeed,
  speedValues,
  closeControlsActionsModal,
  isOpen,
}: PlayerControlsProps & {
  closeControlsActionsModal: () => void;
  isOpen: boolean;
}) => {
  const translate = useTranslate();

  const modalContentRef = useRef<HTMLDivElement>(null);
  const [localSpeed, setLocalSpeed] = useState<number>(currentSpeed);
  const [localIsSkipping, setLocalIsSkipping] = useState<boolean>(isSkipping);
  const speedText = localSpeed === 1 ? translate('ssr.player.normalSpeed') : `${localSpeed}.0`;

  const isSubmitDisabled = currentSpeed === localSpeed && localIsSkipping === isSkipping;

  const changeLocalSpeed = useCallback(() => {
    const maxSpeed = speedValues[speedValues.length - 1];
    const currentSpeedIndex = speedValues.findIndex((value: number) => value === localSpeed);
    if (localSpeed === maxSpeed) {
      setLocalSpeed(speedValues[0]);
      return;
    }
    setLocalSpeed(speedValues[currentSpeedIndex + 1]);
  }, [localSpeed, speedValues]);

  const toggleLocalIsSkipping = () => {
    setLocalIsSkipping((prev) => !prev);
  };

  const onSubmit = useCallback(() => {
    closeControlsActionsModal();
    if (isSkipping !== localIsSkipping) {
      toggleSkipInactive();
    }
    if (currentSpeed !== localSpeed) {
      setSpeed(localSpeed);
    }
  }, [closeControlsActionsModal, currentSpeed, isSkipping, localIsSkipping, localSpeed, setSpeed, toggleSkipInactive]);

  return (
    <ModalWrapper
      isModalOpen={isOpen}
      modalContentRef={modalContentRef}
      closeModal={closeControlsActionsModal}
      modalBackdropSelector='#ssr-player'
    >
      <div className='modal-content p-3' ref={modalContentRef}>
        <div
          className='cursor-default mb-6px flex rounded-18 bg-white-snow justify-between items-center pr-18px pl-3'
          onClick={() => {
            changeLocalSpeed();
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onclick',
                label: 'Increase Play',
                value: 'Button',
              });
            }
          }}
        >
          <div className='flex h-12 items-center gap-2'>
            <TimerIcon color='#3C3C3C' className='shrink-0' />
            <Paragraph weight={fontWeights.medium}>{translate('ssr.player.speed')}</Paragraph>
          </div>
          <Paragraph weight={fontWeights.medium}>{speedText}</Paragraph>
        </div>
        <ToggleActionCard
          heightClass='!h-12'
          titleClass='!ml-0'
          name='skipInactivity'
          title={translate('ssr.player.skipInactivity')}
          value={localIsSkipping}
          icon={() => <SkipIcon color='#696969' />}
          handleChange={() => {
            toggleLocalIsSkipping();
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onclick',
                label: `Skip Inactivity ${localIsSkipping ? 'Off' : 'On'}`,
                value: 'Button',
              });
            }
          }}
          onToggleHover={() => {
            if (isWixMiniApp()) {
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onhover',
                label: 'Hover Button Skip Inactivity',
                value: 'Button',
              });
            }
          }}
        />
        <div className='flex items-center gap-3 mt-6px'>
          <Button
            color='tertiary'
            text={translate('button.cancel')}
            className='w-1/2'
            onClick={() => {
              closeControlsActionsModal();
              DashboardAppWorker.trackEvent({
                category: 'Recording Detail View',
                action: 'onclick',
                label: 'Recording Player Settings Submit',
                value: 'Button',
              });
            }}
          />
          <Button text={translate('button.submit')} className='w-1/2' onClick={onSubmit} disabled={isSubmitDisabled} />
        </div>
      </div>
    </ModalWrapper>
  );
};
