import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export const ConfirmationCard = (props) => {
  const { cardTitle, cardDescription, confirmBtnText, cancelBtnText, onConfirmClick, onCancelClick } = props;
  const cssClasses = useMemo(() => {
    const border = 'border-2 border-white rounded-24';
    const background = 'transition duration-100';
    return classNames(
      border,
      background,
      'confirmation min-w-240 max-w-[350px] bg-alabaster h-full flex flex-column focus:outline-none',
    );
  }, []);
  return (
    <div className={cssClasses}>
      {cardTitle && (
        <div className='p-4 w-full font-semibold text-center border-b-2 border-solid border-gray-concrete text-lg text-mine-shaft leading-normal'>
          {cardTitle}
        </div>
      )}
      <div className='p-4 border-b-2 border-solid border-gray-concrete text-15 text-mine-shaft leading-normal'>
        {cardDescription}
      </div>
      <div className='flex justify-around'>
        <div className='cursor-pointer p-1 w-2/4 bg-clip-padding text-center text-15 leading-normal font-medium text-mine-shaft border-r-2 border-solid border-gray-concrete'>
          <div className='bg-gray-concrete p-3 rounded-bl-3xl' onClick={onCancelClick}>
            {cancelBtnText}
          </div>
        </div>
        <div className='p-1 w-2/4 text-center text-15 leading-normal font-medium text-pomegranate cursor-pointer'>
          <div className='bg-gray-concrete p-3 rounded-br-3xl' onClick={onConfirmClick}>
            {confirmBtnText}
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationCard.propTypes = {
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};
