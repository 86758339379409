import { ColorPickerIcon } from '@va/ui/components/white-label-customisation';
import { Button } from '@va/ui/design-system';
import classNames from 'classnames';
import { HexColorPicker } from 'react-colorful';

type ColorSelectorButtonTypes = { color: string; onChange: (color: string) => void; disabled?: boolean };

export const ColorSelectorButton = ({ color, onChange, disabled = false }: ColorSelectorButtonTypes) => {
  return (
    <Button
      tooltip={!disabled && <ColorSelector color={color} onChange={onChange} />}
      tooltipProps={{ trigger: 'click', useDefaultStyle: false, interactive: true, arrow: false, placement: 'bottom' }}
      icon={() => <ColorPickerIcon className='shrink-0' fillColor={color} />}
      className={classNames('h-60px w-60px !bg-white-snow', { '!cursor-default': disabled })}
      disabled={disabled}
    />
  );
};

export const ColorSelector = ({ color, onChange }: ColorSelectorButtonTypes) => {
  return (
    <div
      className='flex flex-col gap-3 bg-white p-3 rounded-24 w-full items-center'
      onClick={(e) => e.stopPropagation()}
    >
      <HexColorPicker color={color} onChange={onChange} />
      <input
        type='text'
        value={color}
        onChange={(e) => onChange(e.target.value)}
        className='text-black h-6 rounded-12 text-center border border-solid w-full'
      />
    </div>
  );
};
