import {
  apiStatus,
  defaultWebsiteId,
  platform,
  subFeaturesGeneralSettings,
  subFeaturesModuleSettings,
  tabNames,
  userActions,
} from '@va/constants';
import { getAccountInformation, getApiRequest as getCoreApiRequest } from '@va/dashboard/selectors/api';
import { getWebsite, isWixWebsite } from '@va/dashboard/selectors/core';
import { getActiveTab } from '@va/dashboard/selectors/ui';
import { accountType, FREE_PLAN_DETAILS, subscriptionStatus, subscriptionType } from '@va/standalone/shared/constants';
import { UserRole } from '@va/types/website';
import { getPlatformById, is3asApp } from '@va/util/helpers';
import { permissionPack } from '@va/wix/shared/constants';
import Config from 'Config';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { getApiRequest, getApiRequestStatus, getWebsites, getWebsitesMerged } from './api';

export const initializing = (state) => state.getIn(['app', 'initializing'], undefined);
export const getActiveWebsiteId = (state) => state.getIn(['app', 'activeWebsiteId'], defaultWebsiteId);
export const getWebsiteIdToBeRemoved = (state) => state.getIn(['app', 'websiteIdToBeRemoved'], null);
export const getWebsiteById = (state, id) => {
  const websites = getWebsitesMerged(state);

  return websites.find((website) => website.get('id') === id);
};

/** @deprecated Use getActiveWebsiteV2*/
export const getActiveWebsite = (state) => {
  return Immutable.fromJS(state.getIn(['app', 'setActiveWebsite', 'data'])) || Immutable.Map();
};

export const getActiveWebsiteV2 = (state) => {
  return Immutable.fromJS(state.getIn(['app', 'setActiveWebsite', 'data']))?.toJS();
};

export const contributorRole = (state) => {
  const activeWebsite = getActiveWebsite(state);
  return activeWebsite.get('isWebsiteOwner') === false;
};

export const hasUserRole = (state) => {
  const website = getActiveWebsiteV2(state);
  if (!website?.userRole) return () => false;
  return (userRole) => userRole === website.userRole;
};

export const isWebsiteOwner = createSelector([getActiveWebsiteV2], (website) => {
  return !!website?.isWebsiteOwner;
});

export const getUserRole = createSelector([getActiveWebsite], (activeWebsite) => activeWebsite.get('userRole'));

export const isActiveWebsiteWhiteLabeled = createSelector([getActiveWebsite], (activeWebsite) =>
  activeWebsite.get('whiteLabel'),
);

export const hasPermission = (state, action, subFeature, tabName) => {
  const activeWebsite = getActiveWebsiteV2(state);
  const isWix = isWixWebsite(state);
  const currentUserRole = activeWebsite?.userRole;
  const activeTab = getActiveTab(state);
  const tab = tabName ?? activeTab;

  if (isWix || is3asApp()) return true;

  if (currentUserRole === UserRole.EDITOR) {
    switch (tab) {
      case tabNames.funnels:
        return [userActions.Create, userActions.Edit, userActions.Delete].includes(action);
      case tabNames.pageSettings:
      case tabNames.conversions:
        return [userActions.Create, userActions.Edit, userActions.Delete, userActions.Settings].includes(action);
      case tabNames.recordingsDetailsPage:
      case tabNames.recordings:
        return [
          userActions.Create,
          userActions.Edit,
          userActions.Delete,
          userActions.Settings,
          userActions.ChangeStatus,
        ].includes(action);
      case tabNames.generalSettings:
        return getGeneralSettingsPermission(subFeature, action);
      case tabNames.heatmaps:
        return [
          userActions.Create,
          userActions.Edit,
          userActions.Delete,
          userActions.ChangeStatus,
          userActions.Reset,
          userActions.Settings,
        ].includes(action);
      case tabNames.polls:
      case tabNames.surveys:
        return [userActions.Create, userActions.Edit, userActions.Delete, userActions.ChangeStatus].includes(action);
      case tabNames.eventTracking:
        return [userActions.Enable, userActions.Create, userActions.ChangeStatus, userActions.Edit].includes(action);
      case tabNames.overview:
        return [userActions.ChangeStatus, userActions.Create].includes(action);
      case tabNames.modulesSettings:
        return getModuleSettingsPermission(subFeature, action);
      default:
        return false;
    }
  } else if (currentUserRole === UserRole.DASHBOARD) {
    //TODO - update the permissions
    return false;
  } else if (currentUserRole === UserRole.WATCHER) {
    return false;
  } else {
    return true;
  }
};

const getGeneralSettingsPermission = (subFeature, action) => {
  switch (subFeature) {
    case subFeaturesGeneralSettings.ignoreSelfVisits:
    case subFeaturesGeneralSettings.whiteListDomain:
      return [userActions.Create, userActions.Delete].includes(action);
    default:
      return false;
  }
};

const getModuleSettingsPermission = (subFeature, action) => {
  switch (subFeature) {
    case subFeaturesModuleSettings.eventTracking:
      return [userActions.Settings].includes(action);
    case subFeaturesModuleSettings.sessionRecordings:
      return [userActions.Settings].includes(action);
    case subFeaturesModuleSettings.ecommerce:
    case subFeaturesModuleSettings.outgoingTrafic:
      return false;
    default:
      return false;
  }
};

export const isSettingActiveWebsiteInProgress = (state) =>
  state.getIn(['app', 'setActiveWebsite', 'inProgress'], false);

export const hasWixWebsite = (state) => {
  const websites = getWebsitesMerged(state);

  return websites && websites.some((website) => getPlatformById(website.get('platform')) === platform.WIX);
};

export const noOfOwnWixClaimedWebsites = (state) => {
  const websites = getWebsites(state);
  if (websites) {
    return websites
      .get('websites', Immutable.List())
      .filter((website) => getPlatformById(website.get('platform')) === platform.WIX).size;
  }

  return 0;
};

/**
 * @param {Immutable.Map} state
 * @param {string} type - one of ['ownedWebsites', 'sharedWebsites']
 */
export const getNrOfWebsites = (state, type) => {
  const websites = getWebsites(state);

  if (websites && websites.get(type)) {
    return websites.get(type, Immutable.List()).size;
  }

  return 0;
};

export const getNrOfSubscriptions = (state) => {
  const websites = getWebsites(state);
  if (websites) {
    return websites
      .get('ownedWebsites', Immutable.List())
      .filter((website) => website.get('subscription'))
      .reduce((acc) => acc + 1, 0);
  }
  return 0;
};

export const getNrOfActiveSubscriptions = (state) => {
  const websites = getWebsites(state);
  if (websites) {
    return websites
      .get('ownedWebsites', Immutable.List())
      .filter(
        (website) =>
          website.get('subscription') && website.get('subscription').get('status') === subscriptionStatus.ACTIVE,
      ).size;
  }
  return 0;
};

export const isSumoUser = (state) => {
  const accountInformation = getAccountInformation(state);

  return accountInformation.type === accountType.SUMO;
};

export const hasAppliedLifeTimeDeal = (state) => {
  const website = getWebsite(state);
  return website.sumoActive;
};

export const getAppsumoStep = (state) => state.getIn(['app', 'appsumoStep'], null);
export const getAppsumoUserId = (state) => state.getIn(['app', 'appsumoUserId'], null);
export const getVouchersList = (state) => state.getIn(['app', 'listOfVouchers'], Immutable.Map());

export const isBrainTreeInstanceLoaded = (state) => state.getIn(['app', 'brainTreeInstanceLoaded'], false);

/** @deprecated */
export const hasAllRequiredCustomerDetails = (state) => {
  const customer = getApiRequest(state, 'getCustomer');
  return (
    customer.get('id', null) !== null &&
    customer.get('companyName', null) !== null &&
    customer.get('country', null) !== null &&
    customer.get('firstName', null) !== null &&
    customer.get('lastName', null) !== null &&
    customer.get('address', null) !== null
  );
};

export const hasFreePlan = (state) => {
  const featurePermissions = getCoreApiRequest(state, 'getFeaturePermissions');
  return featurePermissions && featurePermissions.visits && featurePermissions.visits.limit <= FREE_PLAN_DETAILS.visits;
};

export const getPaymentPlans = (state) => {
  const paymentPlansStatus = getApiRequestStatus(state, 'getPaymentPlans');
  const paymentPlans = getApiRequest(state, 'getPaymentPlans');
  if (paymentPlansStatus !== apiStatus.SUCCEEDED) {
    return null;
  }

  return {
    monthly: getProcessPaymentPlansByType(paymentPlans, subscriptionType.MONTHLY),
    yearly: getProcessPaymentPlansByType(paymentPlans, subscriptionType.YEARLY),
  };
};

export const getPlanDetailsById = (state, planId) => {
  const paymentPlans = getPaymentPlans(state);
  if (!paymentPlans) return;
  const allPlans = paymentPlans.monthly.concat(paymentPlans.yearly);
  return allPlans.find((plan) => {
    return plan.allPlanIds.includes(String(planId));
  });
};

export const getPlanNameById = (state, planId) => {
  return getPlanDetailsById(state, planId)?.name;
};

export const getPlanFrequencyById = (state, planId) => {
  const paymentPlans = getPaymentPlans(state);
  if (!paymentPlans) return;
  for (const [frequency, plans] of Object.entries(paymentPlans)) {
    for (const plan of plans) {
      if (plan.allPlanIds?.includes(planId)) return frequency;
    }
  }
};

const getProcessPaymentPlansByType = (paymentPlans, subscriptionType) => {
  let paymentPlansArrSorted = [
    {
      planId: 'FREE',
      index: -1,
      name: FREE_PLAN_DETAILS.planName,
      visits: FREE_PLAN_DETAILS.visits,
      recordings: FREE_PLAN_DETAILS.recordings,
      heatmaps: FREE_PLAN_DETAILS.heatmaps,
      polls: FREE_PLAN_DETAILS.polls,
      surveys: FREE_PLAN_DETAILS.surveys,
      oldPlanIds: [],
      allPlanIds: ['FREE'],
      price: 0,
    },
  ];
  const badgeColors = ['#EAA03D', '#C8D000', '#8F7CED', '#54A4DA'];
  paymentPlans
    .get(subscriptionType, Immutable.Map())
    .filter((item) => item.get('active') === true)
    .sortBy((item) => item.get('weight'))
    .forEach((plan, index) => {
      const badgeColorIndex = Math.floor(plan.get('weight') / 100);
      const planId = plan.get('id');
      const oldPlanIds =
        plan
          .get('oldPlanIds')
          ?.toJS()
          ?.map((planId) => String(planId)) ?? [];

      paymentPlansArrSorted.push({
        planId: planId,
        index: Number(index),
        name: plan.get('name'),
        oldPlanIds: oldPlanIds,
        allPlanIds: [...oldPlanIds, planId],
        visits: plan
          .get('permissions')
          .find((item) => item.get('name') === 'VISITS_LIMIT')
          ?.get('value'),
        recordings: plan
          .get('permissions')
          .find((item) => item.get('name') === 'SSR_LIMIT')
          ?.get('value'),
        heatmaps: plan
          .get('permissions')
          .find((item) => item.get('name') === 'HEATMAPS_LIMIT')
          ?.get('value'),
        funnels: plan
          .get('permissions')
          .find((item) => item.get('name') === 'FUNNELS_LIMIT')
          ?.get('value'),
        polls: plan
          .get('permissions')
          .find((item) => item.get('name') === 'POLLS_LIMIT')
          ?.get('value'),
        surveys: plan
          .get('permissions')
          .find((item) => item.get('name') === 'SURVEYS_LIMIT')
          ?.get('value'),
        eventTracking: plan
          .get('permissions')
          .find((item) => item.get('name') === 'EVENT_TRACKING_LIMIT')
          ?.get('value'),
        price: plan.get('price'),
        badge: {
          color: badgeColors[badgeColorIndex - 1],
        },
      });
    });

  return paymentPlansArrSorted;
};

export const getPlanIndex = (state) => {
  const paymentPlans = getPaymentPlans(state);

  const lastCreatedSubscription = getLastCreatedSubscription(state);

  if (!lastCreatedSubscription || !paymentPlans) return -1;

  let monthlyPlanFoundIndex = paymentPlans.monthly.findIndex((plan) => {
    if (
      String(plan.planId) === String(lastCreatedSubscription.planId) ||
      plan.oldPlanIds?.includes(String(lastCreatedSubscription.planId))
    )
      return true;
    return false;
  });

  let yearlyPlanFoundIndex = paymentPlans.yearly.findIndex((plan) => {
    if (
      String(plan.planId) === String(lastCreatedSubscription.planId) ||
      plan.oldPlanIds?.includes(String(lastCreatedSubscription.planId))
    )
      return true;
    return false;
  });

  return Math.max(monthlyPlanFoundIndex, yearlyPlanFoundIndex, 1);
};

/**
 *
 * @deprecated outdated logic
 * TODO Delete
 */
export const getNextBillingPlan = (state) => {
  const subscriptionsResponse = getApiRequest(state, 'getAllSubscriptions');
  const allSubscriptions = subscriptionsResponse.get('data', Immutable.List());
  return (
    allSubscriptions.size > 0 && allSubscriptions.toJS().filter((item) => item.status === subscriptionStatus.PENDING)[0]
  );
};

/**
 *
 * @deprecated outdated logic
 * TODO Delete
 */
export const hasNextBillingPlan = (state) =>
  getNextBillingPlan(state) && getNextBillingPlan(state).id !== null && getNextBillingPlan(state).id !== undefined;

export const getActiveSubscription = (state) => {
  const subscriptionsResponse = getApiRequest(state, 'getAllSubscriptions');
  const allSubscriptions = subscriptionsResponse.get('data', Immutable.List());
  const activeSubscription =
    allSubscriptions.size > 0 && allSubscriptions.toJS().filter((item) => item.status === subscriptionStatus.ACTIVE);
  return activeSubscription && activeSubscription[0];
};

export const getLastCreatedSubscription = (state) => {
  const subscriptionsResponse = getApiRequest(state, 'getAllSubscriptions');
  const allSubscriptions = subscriptionsResponse.get('data', Immutable.List());
  const filteredSubscriptions =
    allSubscriptions.size > 0 && allSubscriptions.toJS().filter((item) => item.status !== subscriptionStatus.PENDING);

  let lastSubscription = filteredSubscriptions && filteredSubscriptions[0];
  if (lastSubscription && filteredSubscriptions.length > 1) {
    for (let i = 1; i < filteredSubscriptions.length; i++) {
      if (filteredSubscriptions[i].createdAtTs > lastSubscription.createdAtTs) {
        lastSubscription = filteredSubscriptions[i];
      }
    }
  }

  return lastSubscription;
};

export const getLastSubscriptionExpirationDate = (state) => {
  const lastSubscription = getLastCreatedSubscription(state);
  return lastSubscription?.isActiveUntil;
};

export const getAllSubscriptionsApiStatus = (state) => getApiRequestStatus(state, 'getAllSubscriptions');

export const getSubscriptionId = createSelector(getLastCreatedSubscription, (subscription) => subscription?.id);
export const getSubscriptionType = createSelector(getLastCreatedSubscription, (subscription) => subscription?.type);

export const getVoucherFromActiveSubscription = (state) => {
  const activeSubscription = getActiveSubscription(state);
  return activeSubscription.voucherCode || null;
};

/**
 *
 * @deprecated outdated logic
 * TODO Recheck
 */
export const hasCanceledSubscription = (state) => {
  const activeSubscription = getLastCreatedSubscription(state);
  //User canceled the current subscription if does not have nextBillingPlan and active subscription has isActiveUntil field set or current subscription is in "Paused" status.

  return (
    !hasNextBillingPlan(state) &&
    activeSubscription &&
    (activeSubscription.isActiveUntil !== null || activeSubscription.status === subscriptionStatus.PAUSED)
  );
};

export const hasUpgradedBefore = (state) => {
  const subscriptionsResponse = getApiRequest(state, 'getAllSubscriptions');
  const allSubscriptions = subscriptionsResponse.get('data', Immutable.List());
  return allSubscriptions.size > 0;
};

export const hasSubscriptionEnded = (state) => {
  const activeWebsite = getActiveWebsite(state);
  const subscription = activeWebsite.get('subscription');
  return (
    getPlatformById(activeWebsite.get('platform')) !== platform.WIX &&
    subscription &&
    subscription.get('status') !== subscriptionStatus.ACTIVE
  );
};

export const hasHigherPremiumPackageToUpgrade = (state) => {
  const platformName = getWebsite(state)['platform'];

  if (platformName === platform.WIX) {
    const featurePermissions = getCoreApiRequest(state, 'getFeaturePermissions');

    return featurePermissions && featurePermissions.wixPermissionPack < permissionPack.PRO;
  } else {
    const lastCreatedSubscription = getLastCreatedSubscription(state);
    const paymentPlans = getPaymentPlans(state);
    if (lastCreatedSubscription && paymentPlans) {
      const activePlanIndex = getPlanIndex(state);

      return activePlanIndex < paymentPlans[lastCreatedSubscription.planType].length - 2;
    }

    return true;
  }
};

export const hasTrackingCodeError = (state) => {
  const website = getWebsite(state);
  const hasTrackingCodeInstalled = website && website.frontendFlags && website.frontendFlags.trackingCodeInstalled;
  const hadFirstVisit = website.hadFirstVisit;

  if (typeof hasTrackingCodeInstalled === 'boolean') {
    return !hasTrackingCodeInstalled;
  } else {
    return !hadFirstVisit;
  }
};

export const isWixClaimed = (state) => {
  const isWixWebsite = getWebsite(state).platform === platform.WIX;
  const isStandaloneApp = Config.appType === 'standalone';
  return isWixWebsite && isStandaloneApp;
};

export const getPaddleCheckoutEvents = (state) => {
  return state.getIn(['app', 'paddleCheckoutEvents']);
};

export const getAgencyCustomTheme = (state) => state.getIn(['app', 'agencyCustomTheme'], Immutable.Map());

export const getAllAgencyThemes = (state) => {
  const agencyThemes = getApiRequest(state, 'getAgencyThemes').get('data');
  const customTheme = getAgencyCustomTheme(state);
  let allThemes = agencyThemes;
  if (customTheme && Immutable.fromJS(customTheme).size > 0) {
    allThemes = agencyThemes && agencyThemes.set(3, Immutable.fromJS(customTheme));
  }
  return allThemes;
};

export const getOnboardingStep = (state) => state.getIn(['app', 'onboardingStep']);
export const getWebsiteCreationStep = (state) => state.getIn(['app', 'websiteCreationStep']);
export const getUpgradePlanStep = (state) => state.getIn(['app', 'upgradePlanStep']);
export const getUpgradePlanIndex = (state) => state.getIn(['app', 'upgradePlanIndex']);
